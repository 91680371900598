import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from '../../../api/apiUtil.jsx';
import Api from "../../../api/api.jsx";
import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../../components/CustomFields/CustomCheckbox.jsx';
import CustomGoogleMapSearch from '../../../components/GoogleMap/CustomGoogleMapSearch.jsx';


export class CustomerAdminAccountForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let allItems = (props.allItems && props.allItems.length > 0) ? props.allItems : [];
    let item = (props.item) ? props.item : null;
    let first_name = (item && item.first_name) ? item.first_name : '';
    let last_name = (item && item.first_name) ? item.first_name : '';
    let address = (item && item.address) ? item.address : '';
    let contact_no = (item && item.contact_no) ? item.contact_no : '';
    let email = (item && item.email) ? item.email : '';
    // let is_main = (item && (item.is_main === true || item.is_main === false) ? item.is_main : false);

    this.state = {
        isLoading: isLoading,
        allItems: allItems,
        item: item,

        first_name: first_name,
        first_name_Validated: false,
        last_name: last_name,
        last_name_Validated: false,
        address: address,
        address_Validated: false,
        contact_no: contact_no,
        contact_no_Validated: false,
        email: email,
        isLoading: false,
        email_Validated: false,
        // is_main: (item) ? is_main : !this.checkIsMainExists(allItems),
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.item !== this.state.item) {
        let item = (nextProps.item) ? nextProps.item : null;
        this.setState({
            item: item
        });
    }
  }

  isEditRow = (allItems = [], item = null) => {
    if(allItems && allItems.length > 0 && item){
        let row = allItems.filter(x => x.uuid === item.uuid);

        return (row && row.length > 0) ? true : false;
    }

    return false;
  }
  /* END FUNCTIONS */


  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'First Name'}
                        placeholder={'john'}
                        value={this.state.first_name}
                        error={this.state.first_name_Validated}
                        required={true}
                        autoFocus={true}
                        onChange={(e) => {
                            this.setState({
                                first_name: e.target.value,
                                first_name_Validated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Last Name'}
                        placeholder={'doe'}
                        value={this.state.last_name}
                        error={this.state.last_name_Validated}
                        required={true}
                        autoFocus={true}
                        onChange={(e) => {
                            this.setState({
                                last_name: e.target.value,
                                last_name_Validated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'email'}
                        placeholder={'john@gmail.com'}
                        value={this.state.email}
                        error={this.state.email_Validated}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                email: e.target.value,
                                email_Validated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
     
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        {(this.state.item ? 'Edit Account' : 'Add Account')}
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;


    this.setState({
      first_name_Validated: false,
      last_name_Validated: false,
      email_Validated: false,
      address_Validated: false,
      contact_no_Validated: false,
    });
    
    if(this.state.first_name === ''){
      this.setState({
        first_name_Validated: true
      });
    } 
    if(this.state.last_name === ''){
      this.setState({
        last_name_Validated: true
      });
    }
    if(this.state.address === ''){
      this.setState({
        address_Validated: true
      });
    }
    if(this.state.contact_no === ''){
      this.setState({
        contact_no_Validated: true
      });
    } 
    if(this.state.email === ''){
      this.setState({
        email_Validated: true
      });
    }

    if (form.checkValidity() === true) {

        if(this.props.onSave){
            this.props.onSave((this.state.item ? true : false), this.getForm());
        }
    } else {
        this.setState({
            isLoading: false
        });
    }
  }

  getForm = () => {
    let data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      contact_no: this.state.contact_no,
      address: this.state.address,
    }

    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default CustomerAdminAccountForm;