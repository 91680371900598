import React, { Component } from 'react';
import uuid from 'react-uuid'

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";

import { 
  Box,
  Grid,
  Divider,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';

import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';

import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CustomFormDialog from '../../../components/Dialog/CustomFormDialog.jsx';

import CompanyDepartmentForm from './CompanyDepartmentForm.jsx';


export class CompanyDepartmentList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let preview = ((props.preview === true) || (props.preview === false)) ? props.preview : false;
    let items = (props.items && props.items.length > 0) ? props.items : [];

    this.state = {
      showAddNew: false,
      newDepartment: '',

      openDialogDeleteItem: false,
      itemDialogDeleteItem: null,

      openDialogDeleteSubItem: false,
      itemDialogDeleteSubItem: null,

      openDialogEditSubItem: false,
      itemDialogEditSubItem: null,

      departments: items,
      preview: preview,
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.state.items) {
        let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
        this.setState({
            departments: items
        });
    }
  }


  /* ITEM FUNCTIONS */
  addNew = (text = '', callback = null) => {
    let departments = this.state.departments;

    departments.push({
        uuid: uuid(),
        department_name: text,
        editName: text,
        showEdit: false,
        contact_people: [],
        delete: false,
    });
    
    this.setState({
        departments: departments
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onAddNew){
            this.props.onAddNew(this.state.departments);
        }
    });
  }

  edit = (item = null, form = null, callback = null) => {
    let departments = this.state.departments;

    let editIndex = departments.findIndex(e => e.uuid === item.uuid);
    if(editIndex !== -1){
        let oldForm = departments[editIndex];

        form['uuid'] = item.uuid;
        departments[editIndex] = form;

        oldForm['delete'] = true;
        departments.push(oldForm);
    }
    
    this.setState({
        departments: departments
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onEdit){
            this.props.onEdit(this.state.departments);
        }
    });
  }

  remove = (uuid = '', callback = null) => {
    let departments = this.state.departments;

    let index = departments.findIndex(e => e.uuid === uuid);
    if(index !== -1){
        departments[index]['delete'] = true;
    }

    this.setState({
        departments: departments
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onRemove){
            this.props.onRemove(this.state.departments);
        }
    });
  }
  /* END ITEM FUNCTIONS */


  /* SUB ITEM FUNCTIONS */
  addNewSubItem = (item = null, form = null, callback = null) => {
    let departments = this.state.departments;

    let index = departments.findIndex(x => x.uuid === item.uuid);
    if(index !== -1){
        if(form) {
            form['uuid'] = uuid();
            form['delete'] = false;
            departments[index].contact_people.push(form);
        } else {
            departments[index].contact_people.push({
                uuid: uuid(),
                name: '',
                address_line1: '',
                contact_no: '',
                delete: false,
            });
        }
    }
    
    this.setState({
        departments: departments
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onAddNew){
            this.props.onAddNew(this.state.departments);
        }
    });
  }

  editSubItem = (item = null, subItem = null, form = null, callback = null) => {
    let departments = this.state.departments;

    let index = departments.findIndex(x => x.uuid === item.uuid);
    if(index !== -1){
        let editIndex = departments[index].contact_people.findIndex(e => e.uuid === subItem.uuid);
        if(editIndex !== -1){
            let oldForm = departments[index].contact_people[editIndex];

            form['uuid'] = subItem.uuid;
            form['delete'] = false;
            departments[index].contact_people[editIndex] = form;
            
            oldForm['delete'] = true;
            departments[index].contact_people.push(oldForm);
        }
    }

    this.setState({
        departments: departments
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onEdit){
            this.props.onEdit(this.state.departments);
        }
    });
  }

  removeSubItem = (uuid = '', subUuid = '', callback = null) => {
    let departments = this.state.departments;

    let index = departments.findIndex(x => x.uuid === uuid);
    if(index !== -1){
        let subIndex = departments[index].contact_people.findIndex(e => e.uuid === subUuid);
        if(subIndex !== -1){
            departments[index].contact_people[subIndex]['delete'] = true;
        }
    }


    this.setState({
        departments: departments
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onRemove){
            this.props.onRemove(this.state.departments);
        }
    });
  }
  /* END SUB ITEM FUNCTIONS */


  /* DIALOG */
  deleteItemDialog = () => {
      return <CustomDialog 
          open={this.state.openDialogDeleteItem}
          title={'Delete'}
          onClose={() => {
            this.setState({
                openDialogDeleteItem: false,
                itemDialogDeleteItem: null,
            });
          }}
          onOk={() => {
              let item = this.state.itemDialogDeleteItem;
              this.remove(item.uuid, () => {
                this.setState({
                    openDialogDeleteItem: false,
                    itemDialogDeleteItem: null,
                });
              });
          }}
      >
          <Box>Are you sure you want to delete?</Box>
      </CustomDialog>
  }

  deleteSubItemDialog = () => {
    return <CustomDialog 
        open={this.state.openDialogDeleteSubItem}
        title={'Delete'}
        onClose={() => {
          this.setState({
              openDialogDeleteSubItem: false,
              itemDialogDeleteItem: null,
              itemDialogDeleteSubItem: null,
          });
        }}
        onOk={() => {
            let item = this.state.itemDialogDeleteItem;
            let subItem = this.state.itemDialogDeleteSubItem;
            this.removeSubItem(item.uuid, subItem.uuid, () => {
              this.setState({
                  openDialogDeleteSubItem: false,
                  itemDialogDeleteItem: null,
                  itemDialogDeleteSubItem: null,
              });
            });
        }}
    >
        <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }

  editSubItemDialog = () => {
    return <CustomFormDialog 
        open={this.state.openDialogEditSubItem}
        title={'Person In Charge'}
        onClose={() => {
          this.setState({
              openDialogEditSubItem: false,
              itemDialogEditItem: null,
              itemDialogEditSubItem: null,
          });
        }}
    >
        <CompanyDepartmentForm
          item={this.state.itemDialogEditSubItem}
          onSave={(isEdit, form) => {
              let item = this.state.itemDialogEditItem;
              let subItem = this.state.itemDialogEditSubItem;

              if(isEdit) {
                  this.editSubItem(item, subItem, form, () => {
                      this.setState({
                          openDialogEditSubItem: false,
                          itemDialogEditItem: null,
                          itemDialogEditSubItem: null,
                      });
                  });
              } else {
                  this.addNewSubItem(item, form, () => {
                      this.setState({
                          openDialogEditSubItem: false,
                          itemDialogEditItem: null,
                          itemDialogEditSubItem: null,
                      });
                  });
              }
          }}
          onClose={() => {
              this.setState({
                  openDialogEditSubItem: false,
                  itemDialogEditItem: null,
                  itemDialogEditSubItem: null,
              });
          }}
        />
    </CustomFormDialog>
  }
  /* END DIALOG */


  addNewDepartment = () => {
    if(!this.state.showAddNew) {
        return <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    <CustomButton 
                        color={'primary'}
                        href={'/'}
                        onClick={(e) => {
                            e.preventDefault();

                            let newDepartments = this.state.departments;

                            if(newDepartments && newDepartments.length > 0){
                                for(let d = 0; d < newDepartments.length; d++){
                                    newDepartments[d].showEdit = false;
                                }
                            }

                            this.setState({
                                showAddNew: true,
                                departments: newDepartments,
                                newDepartment: '',
                            });
                        }}
                    >
                        <Icon component={'i'}>add</Icon>
                        <Box display={'inline-block'} pl={1}>Add New Department</Box>
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    } else {
        return <Grid container>
            <Box clone pt={2}>
                <Grid item xs={12}>
                    <CustomInput 
                        placeholder={'Eg. Finiance'}
                        value={this.state.newDepartment}
                        autoFocus={true}
                        onChange={(e) => {
                            this.setState({
                                newDepartment: e.target.value,
                            });
                        }}
                        onPressEnter={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            this.setState({
                                newDepartment: e.target.value,
                            }, () => {
                                this.addNew(this.state.newDepartment, () => {
                                    this.setState({
                                        showAddNew: false,
                                        newDepartment: '',
                                    });
                                });
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <Grid container>
                        <Box clone pr={2}>
                            <Grid item xs={'auto'}>
                                <CustomButton 
                                    color={'primary'}
                                    size={'small'}
                                    onClick={(e) => {
                                        this.addNew(this.state.newDepartment, () => {
                                            this.setState({
                                                showAddNew: false,
                                                newDepartment: '',
                                            });
                                        });
                                    }}
                                >
                                    Add department
                                </CustomButton>
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={true}>
                                <CustomButton 
                                    size={'small'}
                                    onClick={(e) => {
                                        this.setState({
                                            showAddNew: false,
                                            newDepartment: '',
                                        });
                                    }}
                                >
                                    Cancel
                                </CustomButton>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    }
  }

  editDepartment = (item, i) => {
    if(!item.showEdit) {
        return <Grid container alignItems={'center'}>
            {!this.state.preview && <Box clone pl={{ xs: 0, md: 2}}>
                <Grid item xs={'auto'}>
                    <IconButton
                        className={'company-department-remove-btn'}
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                            this.setState({
                                openDialogDeleteItem: true,
                                itemDialogDeleteItem: item,
                            });
                        }}
                    >
                        <Icon component={'i'}>close</Icon>
                    </IconButton>
                    <IconButton
                        className={'company-department-edit-btn'}
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                            let newDepartments = this.state.departments;

                            if(newDepartments && newDepartments.length > 0){
                                for(let d = 0; d < newDepartments.length; d++){
                                    newDepartments[d].showEdit = false;
                                }
                            }

                            newDepartments[i].showEdit = true;

                            this.setState({
                                departments: newDepartments,
                                showAddNew: false,
                            });
                        }}
                    >
                        <Icon component={'i'}>edit</Icon>
                    </IconButton>
                </Grid>
            </Box>}
            <Box clone>
                <Grid item xs={true}>
                    <CustomLabel
                        className={'no-margin'}
                        label={item.department_name}
                        size={'lg'}
                    />
                </Grid>
            </Box>
            {!this.state.preview && <Box clone>
                <Grid item xs={12} md={'auto'}>
                    <CustomButton 
                        color={'primary'}
                        href={'/'}
                        onClick={(e) => {
                            e.preventDefault();

                            this.setState({
                                openDialogEditSubItem: true,
                                itemDialogEditItem: item,
                                itemDialogEditSubItem: null,
                            });
                        }}
                    >
                        <Icon component={'i'}>add</Icon>
                        <Box display={'inline-block'} pl={1}>Add Person in Charge</Box>
                    </CustomButton>
                </Grid>
            </Box>}
            <Box clone pl={3} pr={3}>
                <Grid item xs={12}>
                    {this.contactPeopleList(item, i)}
                </Grid>
            </Box>
        </Grid>
    } else {
        return <Grid container>
            <Box clone pt={2}>
                <Grid item xs={12}>
                    <CustomInput 
                        placeholder={'Eg. Finiance'}
                        value={item.department_name}
                        autoFocus={true}
                        onChange={(e) => {
                            let newDepartments = this.state.departments;

                            newDepartments[i].department_name = e.target.value;

                            this.setState({
                                departments: newDepartments
                            });
                        }}
                        onPressEnter={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            let newDepartments = this.state.departments;

                            newDepartments[i].department_name = e.target.value;

                            this.setState({
                                departments: newDepartments
                            }, () => {
                                let newDepartments = this.state.departments;

                                newDepartments[i].editName = newDepartments[i].department_name;
                                newDepartments[i].showEdit = false;

                                this.setState({
                                    departments: newDepartments
                                });
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone pl={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Box clone pr={2}>
                            <Grid item xs={'auto'}>
                                <CustomButton 
                                    color={'primary'}
                                    size={'small'}
                                    onClick={(e) => {
                                        let newDepartments = this.state.departments;

                                        newDepartments[i].editName = newDepartments[i].department_name;
                                        newDepartments[i].showEdit = false;

                                        this.setState({
                                            departments: newDepartments
                                        });
                                    }}
                                >
                                    Edit department
                                </CustomButton>
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={true}>
                                <CustomButton 
                                    size={'small'}
                                    onClick={(e) => {
                                        let newDepartments = this.state.departments;

                                        newDepartments[i].department_name = newDepartments[i].editName;
                                        newDepartments[i].showEdit = false;

                                        this.setState({
                                            departments: newDepartments
                                        });
                                    }}
                                >
                                    Cancel
                                </CustomButton>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    }
  }

  contactPeopleList = (item, i) => {
      if(item && item.contact_people && item.contact_people.length > 0 && item.contact_people.filter(x => !x.delete).length > 0){
        return item.contact_people.filter(x => !x.delete).map((subItem, j) => {
            return <Box key={j} pt={1} pb={1}>
                <Grid container alignItems={'center'}>
                    <Box clone pl={{ xs: 0, md: 2}}>
                        <Grid item xs={12} md={4}>
                            <CustomLabel
                                label={<Box>
                                    <Tooltip title={'Full Name'} placement={'top'}>
                                        <Box>{subItem.first_name}</Box>
                                    </Tooltip>
                                </Box>}
                            />
                        </Grid>
                    </Box>
                    <Box clone>
                        <Grid item xs={12} md={4}>
                            <CustomLabel
                                label={<Box>
                                    <Tooltip title={'Email Address'} placement={'top'}>
                                        <Box>{subItem.email}</Box>
                                    </Tooltip>
                                </Box>}
                            />
                        </Grid>
                    </Box>
                    <Box clone>
                        <Grid item xs={12} md={(!this.state.preview) ? 2 : 4}>
                            <CustomLabel
                                label={<Box>
                                    <Tooltip title={'Phone Number'} placement={'top'}>
                                        <Box>{subItem.contact_no}</Box>
                                    </Tooltip>
                                </Box>}
                            />
                        </Grid>
                    </Box>
                    {!this.state.preview && <Box clone pr={{ xs: 0, md: 2}}>
                        <Grid item xs={12} md={2}>
                            <Grid container justify={'flex-end'}>
                                <Box clone>
                                    <Grid item xs={'auto'}>
                                        <IconButton
                                            onClick={() => {
                                                this.setState({
                                                    openDialogEditSubItem: true,
                                                    itemDialogEditItem: item,
                                                    itemDialogEditSubItem: subItem,
                                                });
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Grid>
                                </Box>
                                <Box clone>
                                    <Grid item xs={'auto'}>
                                        <IconButton
                                            onClick={() => {
                                                this.setState({
                                                    openDialogDeleteSubItem: true,
                                                    itemDialogDeleteItem: item,
                                                    itemDialogDeleteSubItem: subItem,
                                                });
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>}
                </Grid>
                <Divider light />
            </Box>
        });
      } else {
          return this.noResult();
      }
  }

  noResult = () => {
      return <Grid container>
        <Box clone pt={3} textAlign={'center'}>
            <Grid item xs={12}>
                <CustomLabel
                    label={'No Results!'}
                    weight={'bold'}
                />
            </Grid>
        </Box>
    </Grid>
  }


  render() {
    return <Box pt={5} pb={5}>
        <Grid container>
            {!this.state.preview && <Grid item xs={12}>
                <CustomLabel
                    label={'Company Department'}
                    weight={'bold'}
                    size={'md'}
                />
                <Divider light />
            </Grid>}
            <Grid item xs={12}>
                <Box>
                    {
                        (this.state.departments && this.state.departments.length > 0 && this.state.departments.filter(x => !x.delete).length > 0)
                        ?
                        this.state.departments.filter(x => !x.delete).map((item, i) => {
                            return <Box key={i} pt={1} pb={1}>
                                {this.editDepartment(item, i)}
                            </Box>
                        })
                        :
                        this.noResult()
                    }

                    {!this.state.preview && this.addNewDepartment()}
                </Box>
            </Grid>
        </Grid>
        {this.deleteItemDialog()}
        {this.deleteSubItemDialog()}
        {this.editSubItemDialog()}
    </Box>
  }
}

export default CompanyDepartmentList;