import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';

import { 
  Box,
  Grid,
  CircularProgress,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';

import { ReactComponent as TimeIcon } from '../../assets/img/icons/time.svg';
import { ReactComponent as ImportIcon } from '../../assets/img/icons/import.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CircleIcon from '../../components/CircleIcon/CircleIcon.jsx';
import StatusList from '../../components/Status/StatusList.jsx';
import StatusItem from '../../components/Status/StatusItem.jsx';
import CustomCalendar from '../../components/Calendar/CustomCalendar.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';

import JobImportDialog from './Partial/JobImportDialog.jsx';


export class JobSchedule extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    this.state = {
      jobTab: 1,

      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      },
      view: 'month',

      isLoadingStatus: false,
      status: [],
      statusSelected: null,
      order_status: null,

      isLoading: false,
      data: [],
      
      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,
    }
  }
  

  componentDidMount() {
    this.callInitApis();
  }

  callInitApis = () => {
    this.loadStatusApi();
    this.callReadApi();
  }
  loadStatusApi = () => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = data.map((item, i) => {
          return {
            id: item.status_details.id,
            status: item.status_details.status,
            total: item.total_job,
          };
        });
  
        status.unshift({
          id: 0,
          status: 'Total Jobs',
          total: total,
        });
  
        this.setState({
          status: status,
        });
      }
    });
  }


  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }
  
  callReadApi = (isLoading = true, search = '') => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: isLoading
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        page: this.state.page,
        take: this.state.limit,
        search: search,
        start_date: startDate,
        end_date: endDate,
        order_status: this.state.order_status,
        light_data: true,
      };

      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if (data.result && data.result.length > 0) {
              let groupedResults = _.groupBy(data.result, (r) => moment(r.drop_off_time_planned).format(apiUtil.getDefaultDateFormat()));

              if(groupedResults){
                let groupedData = Object.keys(groupedResults).map(key => groupedResults[key]);
                
                let result = [];
                if(groupedData && groupedData.length > 0){
                  for(let i = 0; i < groupedData.length; i++){
                    let array = groupedData[i];

                    let dateTime = (groupedData[i][0].drop_off_time_planned && groupedData[i][0].drop_off_time_planned !== '') ? groupedData[i][0].drop_off_time_planned : groupedData[i][0].drop_off_date;

                    result.push({
                      id: i,
                      start: moment(dateTime).toDate(),
                      end: moment(dateTime).toDate(),
                      title: <div className={'second'}>{(array && array.length > 0) ? array.length : 0} Jobs</div>,
                    });
                  }
                  
                  this.setState({
                    data: (result && result.length > 0) ? result : [],
                    isLoading: false,
                  });
                } else {
                  this.setState({
                    data: [],
                    isLoading: false,
                  });
                }
              } else {
                this.setState({
                  data: [],
                  isLoading: false,
                });
              }
            } else {
              this.setState({
                data: [],
                isLoading: false,
              });
            }

            this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              isLoading: false,
            });
          });
      });
    });
  }
  /* END API */


  /* IMPORT API */
  callImportApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('create/orders/multiple', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END IMPORT API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              range={this.state.rangeDate}
              onChange={(range) => {
                this.setState({
                  view: 'month',
                  rangeDate: range,
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Grid>
        </Box>
        <Box clone pr={1}>
          <Grid item xs={'auto'}>
            <CustomUploadButton 
              color={'primary'}
              accept={'.xls, .xlsx'}
              onChange={(files) => {
                if(files && files.length > 0){
                  this.setState({
                    openImportDialog: true,
                    openImportDialogItem: files[0],
                  });
                }
              }}
            >
              <ImportIcon />
            </CustomUploadButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              color={'secondary'}
              onClick={() => {
                this.props.history.push('/jobs-form');
              }}
            >
              + New Jobs
            </CustomButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={12}>
            <GroupButton
              className={'head-tabs'}
              color={'secondary'}
              selected={this.state.jobTab}
              buttons={[ "Job List", "Job Schedule" ]}
              onClick={(selected, btn) => {
                if(selected === 0){
                  this.props.history.push('/job-list');
                }
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* STATUS */
  customStatus = () => {
    return <Grid container alignItems={'center'}>
      <Box clone mb={1}>
        <Grid item xs={'auto'}>
          <CircleIcon>
            <TimeIcon />
          </CircleIcon>
        </Grid>
      </Box>
      <Box clone pl={3} pr={3}>
        <Grid item xs={'auto'}>
          <CustomLabel
            className={'no-margin'}
            label={'Jobs List'}
            weight={'bold'}
            size={'md'}
          />
          <CustomLabel
            label={'Based on recent activities'}
            size={'xs'}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} lg={true}>
          <Box className={'custom-status-list-wrapper'}>
            <StatusList
              selected={(this.state.statusSelected > 0) ? this.state.statusSelected : 0}
              items={this.state.status.map((item, i) => {
                return <StatusItem
                  text={<Box>
                    <Icon component={'i'} className={'custom-dot status ' + (item.status.replace(' ', '-').trim().toLowerCase())}>lens</Icon>
                    <span>{item.status}</span>
                  </Box>}
                  count={item.total}
                />
              })}
              onClick={(index) => {
                this.setState({
                  statusSelected: index,
                  order_status: (index && index > 0) ? this.state.status[index].id : null,
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Box>
        </Grid>
      </Box>
    </Grid>
  }
  /* END STATUS */


  /* CALENDAR */
  customCalendar = () => {
    if(!this.state.isLoading){
      return <CustomCalendar
        events={this.state.data}
        view={this.state.view}
        date={(this.state.rangeDate && this.state.rangeDate.startDate) ? new Date(this.state.rangeDate.startDate) : null}
        onClick={(item) => {
          if(item && item.start){
            let date = moment(item.start).format(apiUtil.getDefaultDateFormat());
            
            this.props.history.push({
              pathname: '/job-schedule-list',
              state: {
                date: date
              }
            });
          }
        }}
        onNavigate={(date, type) => {
          let range = {
            startDate: moment(date).startOf(type),
            endDate: moment(date).endOf(type),
          };

          this.setState({
            view: type,
            rangeDate: range,
          }, () => {
            this.loadStatusApi();
            this.callReadApi(false);
          });
        }}
        onView={(date, type) => {
          console.log(date, type);
          let range = {
            startDate: moment(date).startOf(type),
            endDate: moment(date).endOf(type),
          };
          
          this.setState({
            view: type,
            rangeDate: range,
          }, () => {
            this.loadStatusApi();
            this.callReadApi(false);
          });
        }}
      />
    } else {
      return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
        <CircularProgress size={35} />
      </Box>
    }
  }
  /* END CALENDAR */


  /* DIALOG */
  importDialog = () => {
    return <CustomDialog 
      open={this.state.openImportDialog}
      title={'Import Jobs'}
      maxWidth={'xl'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openImportDialog: false,
          openImportDialogItem: null,
        });
      }}
    >
      <JobImportDialog
        file={this.state.openImportDialogItem}
        isLoading={this.state.openImportDialogIsLoading}
        onClose={() => {
          this.setState({
            openImportDialog: false,
            openImportDialogItem: null,
          });
        }}
        onImport={(form) => {
          this.setState({
            openImportDialogIsLoading: true
          }, () => {
            this.callImportApi(form, (data) => {
              let all_jobs = (form && form.length > 0) ? form.length : 0;
              let failed_orders = (data && data.failed_orders && data.failed_orders.length > 0) ? data.failed_orders.length : 0;
              let saved_orders = (data && data.saved_orders && data.saved_orders.length > 0) ? data.saved_orders.length : 0;
              
              if(failed_orders > 0){
                apiUtil.toast(failed_orders + ' of ' + all_jobs + ' jobs have not successfully imported', 'check_circle', 'error');
              }
  
              if(saved_orders > 0){
                apiUtil.toast(saved_orders + ' of ' + all_jobs + ' jobs successfully imported', 'check_circle', 'success');
              }
  
              this.callReadApi(false);
  
              this.setState({
                openImportDialog: false,
                openImportDialogItem: null,
                openImportDialogIsLoading: false,
              });
            });
          });
        }}
      />
    </CustomDialog>
  }
  /* END DIALOG */


  render() {
    return <Box className="job-schedule-page" >
      <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
      
      <Card className={'pb-0'}>
        {this.customStatus()}
      </Card>

      <Card>
        {this.customCalendar()}
      </Card>

      {this.importDialog()}
    </Box>;
  }
}