import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import api from "../../../api/api.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomAutosuggest from '../../../components/CustomFields/CustomAutosuggest.jsx';

export class BatchUpdateForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);

    this.state = {
        isLoading: isLoading,
        order_status:null,
        order_status_id:null,
        drop_off_worker:null,
        drop_off_worker_id:null,
        extra_worker:null,
        extra_worker_ids:null
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
  }

  /* WORKER API */

  callWorkerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      disabled: false,
      // take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search,
      is_truck_driver: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
    
            if(callback){
              callback(arr);
            }
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }

  callManpowerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      // take: apiUtil.getDefaultPageSize(),
      disabled: false,
      is_active: true,
      search: search,
      is_man_power: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        let result = (data.result && data.result.length > 0) ? data.result : [];

        let arr = [];
        if(result && result.length > 0){
          arr = result.map((item, i) => {
            let value = item.id;
            let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

            if(arr.findIndex(x => x.value === value) === -1){
              return {
                value: value,
                label: label,
                item: item
              }
            } else {
              return null;
            }
          });
  
          if(callback){
            callback(arr);
          }
        } else {
          if(callback){
            callback([]);
          }
        }
      }, (error, type) => {
        if(callback){
          callback([]);
        }
      });
    });
  }

  callOrderStatusesApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    ldsApi.read('order_statuses', null, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data && data.length > 0) ? data : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = item.status;

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
    
            if(callback){
              callback(arr);
            }
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }
  /* END WORKER API */

  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    <CustomAutosuggest 
                      className={'no-margin'}
                      placeholder={'Job Status'}
                      label={'Job Status'}
                      value={this.state.order_status}
                      onChange={(value) => {
                        this.setState({
                            order_status_id:value.value,
                            order_status:value.label
                        });
                        
                      }}
                      onSearch={(value, e) => {
                        this.callOrderStatusesApi(value, (arr) => {
                          e.showNewSuggestions(arr);
                        });
                      }}
                      onClick={(value, e) => {
                        this.callOrderStatusesApi(value, (arr) => {
                          e.showNewSuggestions(arr);
                        });
                      }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    
                    <CustomAutosuggest 
                      className={'no-margin'}
                      placeholder={'Assign Driver'}
                      label={'Assign Driver'}
                      value={this.state.drop_off_worker}
                      onChange={(value) => {
                        this.setState({
                            drop_off_worker_id:value.value,
                            drop_off_worker:value.label
                        });
                      }}
                      onSearch={(value, e) => {      
                        this.callWorkerDriverReadApi(value, (arr) => {
                          e.showNewSuggestions(arr);
                        });
                      }}
                      onClick={(value, e) => {
                        this.callWorkerDriverReadApi(value, (arr) => {
                          e.showNewSuggestions(arr);
                        });
                      }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomAutosuggest 
                      className={'no-margin'}
                      placeholder={'Assign Manpower'}
                      label={'Assign Manpower'}
                      value={this.state.extra_worker}
                      onChange={(value) => {
                        this.setState({
                            extra_worker_ids:value.value,
                            extra_worker:value.label
                        });
                        
                      }}
                      onSearch={(value, e) => {
                        this.callManpowerDriverReadApi(value, (arr) => {
                          e.showNewSuggestions(arr);
                        });
                      }}
                      onClick={(value, e) => {
                        this.callManpowerDriverReadApi(value, (arr) => {
                          e.showNewSuggestions(arr);
                        });
                      }}
                    />
                </Grid>
            </Box>
            
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'right'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        Submit
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;
    
    if(this.props.onSave){
        this.props.onSave(this.getForm());
    }
    
  }

  
  getForm = () => {
    let data = {
        order_status_id: this.state.order_status_id,
        drop_off_worker_id: this.state.drop_off_worker_id,
        extra_worker_ids: this.state.extra_worker_ids,
    }

    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default BatchUpdateForm;