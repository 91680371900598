import React, { Component } from 'react';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { 
  Box,
  Grid,
  CircularProgress
} from '@material-ui/core';
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import Table from '../../components/Table/Table.jsx';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import PieChart from './PieChart';
import BarChart from './BarChart';
import moment from 'moment';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
/**
 * http://lds-api.taskk.me/admin/api/orders/job/stats?start_date=2019-05-01&end_date=2020-11-23
 * http://lds-api.taskk.me/admin/api/dashboard/recent_activity/?start_date=2019-05-01&end_date=2020-11-23
 * http://lds-api.taskk.me/admin/api/dashboard/delivery_perday/?start_date=2019-05-01&end_date=2020-11-23
 */
class Dashboard extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.state = {
      jobTab: 0,
      jobTabTable: 0,

      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      },

      isLoadingStatus: false,
      status: [],
      tableStatus: [],
      statusSelected: null,
      order_status: null,
      isLoading: false,
      isStatLoading: false,
      grouping: true,
      limit: apiUtil.getDefaultPageSize(),
      data: [],
      total: 0,
      page: 1,
      total_delivery: 0,
      openDialog: false,
      openDialogItem: null,

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      stats : [],
      recent_activity : [],
      delivery_perday : []
    }
  }
  componentDidMount() {
    const { setIsOnBoarding } = this.context;
    
    this.callAdminProfileApi((isOnBoarding, data) => {
      setIsOnBoarding(isOnBoarding);

      if(isOnBoarding){
        this.props.history.push('/settings');
      }

      this.callInitApis()
    });
  }
  callInitApis = () => {
    this.callStatsApi()
    this.callRecentActivityApi()
    this.callDeliveryPerDayApi()
  }

  callAdminProfileApi = (callback = null) => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
        api.read('profile/admins', null, accessToken).then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(data.result.is_onboarding, data.result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(false, null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(false, null);
              }
            });
          });
      });
    });
  }

  callDeliveryPerDayApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        page: this.state.page,
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('dashboard/delivery_perday/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            const dataArr = (data.result && data.result.length > 0) ? data.result : []
            this.setState({
              delivery_perday: dataArr,
              isLoading: false,
            });
          }, (error, type) => {
            this.setState({
              delivery_perday: [],
              isLoading: false,
            });
          });

      });
    });
  }
  callStatsApi = () => {
    const { accessToken } = this.context;

    this.setState({
      isStatLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        page: this.state.page,
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            let dataArr = []
            for(const [key, val] of Object.entries(data.result)) {
              dataArr.push({
                id: val.status_details.id,
                total: val.total_job,
                status: val.status_details.status,
                stage: val.status_details.stage
              })
            }
            this.setState({
              stats: dataArr,
              total_delivery: data.total_delivery,
              isStatLoading: false,
            });
          }, (error, type) => {
            this.setState({
              recent_activity: [],
              isStatLoading: false,
            });
          });

      });
    });
  }
  callRecentActivityApi = () => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        page: this.state.page,
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('dashboard/recent_activity/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            const dataArr = (data.result && data.result.length > 0) ? data.result : []
            
            this.setState({
              recent_activity: dataArr,
              isLoading: false,
            });
          }, (error, type) => {
            this.setState({
              recent_activity: [],
              isLoading: false,
            });
          });

      });
    });
  }
  customToolbar = (e) => {
    return <Box>
      <Grid container alignItems={'center'}>
        <Box clone>
          <Grid item xs={12} md={true}>
            <CustomLabel
              label={'Recent Activities'}
              size={'lg'}
            />
          </Grid>
        </Box>
        <Box clone textAlign={'right'} pb={2} pr={2}>
          <Grid item xs={12} md={2}>

          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* TABLE */
  customTable = () => {
    return <Table 
      ref={this.refTable}
      className={'job-table'}
      isLoading={this.state.isLoading}
      paging={false}
      data={this.state.recent_activity.slice(0,20)}
      Toolbar={this.customToolbar}
      maxBodyHeight={550}
      Row={this.customRow}
      columns={[
        { 
          title: "#ID", 
          field: "id",
          width: "10%",
        },
        { 
          title: "Activity", 
          field: "description",
          width: "65%"
        },
        { 
          title: "Time", 
          field: "submitted_time",
          render: (row) => {
            let time = moment.utc(row.submitted_time);
            return moment(time).local().fromNow();
          }
        },
      ]}
    />
  }
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }} pt={{xs: 2, md: 0}}>
            <Grid item xs={12} md={3} xl={2}>
              <CustomDateRangePicker
                range={this.state.rangeDate}
                onChange={(range) => {
                  this.setState({
                    rangeDate: range,
                  }, () => {
                    this.callInitApis();
                  });
                }}
              />
            </Grid>
          </Box>
      </Grid>
    </Box>
  }
  jobStats = () => {
    const {stats, isStatLoading} = this.state
    return !isStatLoading ? stats.map( (val, index) => <div 
      key={index} 
      className={`info-card info-card--${val.status.replace(" ","").toLowerCase()}`} 
      onClick={() => {
        this.props.history.push({
          pathname: '/job-list',
          state: {
            index: index + 1,
            order_status: val.id
          }
        });
      }}
    >
    <div className="info-card__inner-left">
      <div className="info-card__total">{val.total}</div>
      <div className="info-card__label">{val.status}</div>
    </div>
    <div className="info-card__inner-btn">
    
    </div>
  </div>) 
  : 
  <div className="info-card info-card--isloading">
      <CircularProgress size={35} />
  </div>
  }
  render() {
    const {stats, delivery_perday, total_delivery} = this.state
    return <Box className="dashboard-page">
      <StickyPageHeader>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
      <Grid container>
        <Grid item xs={12} md={12} sm={12} lg={4} xl={3}>
          <div className="info-cards">
          
            <div className="info-card info-card--piechart">
              
              <div className="info-card--total-jobs">
                <span>Total Jobs</span>
                <span>{total_delivery}</span>
              </div>
              <PieChart total={total_delivery} completed={stats[4] && stats[4].total}/>
              
            </div>
          
          </div>
        </Grid>
        <Grid item xs={12} md={12} sm={12} lg={8} xl={9}>
          <div className="info-cards">
            
            {this.jobStats()}
          
          </div>
        </Grid>
      </Grid>
      
      <Grid container>
        <Grid item xs={12} md={6}>
          <Card className={'recent-activities'}>
          {this.customTable()}
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={'delivery-chart'}>
            <Box>
            <Grid container alignItems={'center'}>
                <Box clone>
                  <Grid item xs={12} md={true}>
                    <CustomLabel
                      label={'Deliveries Per Day'}
                      size={'lg'}
                    />
                  </Grid>
                </Box>
                <Box clone textAlign={'right'} pb={2} pr={2}>
                  <Grid item xs={12} md={2}>

                  </Grid>
                </Box>
              </Grid>
            </Box>
            <BarChart data={delivery_perday} />
          </Card>
        </Grid>
      </Grid>
    </Box>;
  }
}

export default Dashboard;
