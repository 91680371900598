import React, { Component } from 'react';

import moment from 'moment';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";

import { 
  Box,
  Chip,
} from '@material-ui/core';

import Table from '../../../components/Table/Table.jsx';


export class HistoryList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();

    let preview = ((props.preview === true) || (props.preview === false)) ? props.preview : false;
    let data = (props.items && props.items.length > 0) ? props.items : [];

    this.state = {
        preview: preview,

        isLoading: false,
        grouping: false,
        paging: false,
        limit: apiUtil.getDefaultPageSize(),
        data: data,
        total: 0,
        page: 1,
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.state.items) {
        let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
        this.setState({
            data: items
        });
    }
  }


  /* TABLE */
  customTable = () => {
    return <Table 
      ref={this.refTable}
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      paging={this.state.paging}
      limit={this.state.limit}
      page={this.state.page}
      total={this.state.total}
      data={this.state.data}
      onPageChange={(page) => {
        this.setState({
          page: page,
        }, () => {
          this.callReadApi();
        });
      }}
      Toolbar={this.customToolbar}
      columns={[
        { 
          title: "Details", 
          field: "details",
        },
        { 
            title: "Updated By", 
            field: "admin.first_name",
            render: (row) => {
              if (row.worker_id !== null) {
                return row.worker.first_name + ' ' + row.worker.last_name;
              } else {
                return row.admin? row.admin.first_name: "";
              }
            }
        },
        { 
            title: "Updated At", 
            field: "updated_at",
            render: (row) => {
                let date = (row && row.updated_at && row.updated_at !== '') ? moment(row.updated_at).format('MMMM D, YYYY HH:mm A') : '';
                return date;
            }
        },
        { 
            title: "Last Status", 
            field: "order_status.status",
            render: (row) => {
                let status = (row && row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '';
                return <Box textAlign={'center'}>
                    {(status !== '') && <Chip className={'status-label ' + apiUtil.statusToClassName(status)} label={status} />}
                </Box>;
            }
        },
        { 
            title: "Current Status", 
            field: "current_status.status",
            render: (row) => {
                let status = (row && row.current_status && row.current_status.status && row.current_status.status !== '') ? row.current_status.status : '';
                return <Box textAlign={'center'}>
                    {(status !== '') && <Chip className={'status-label ' + apiUtil.statusToClassName(status)} label={status} />}
                </Box>;
            }
        },
      ]}
    />
  }
  /* END TABLE */


  render() {
    return <Box pt={3} pb={5}>
        {this.customTable()}
    </Box>
  }
}

export default HistoryList;