import React, { useRef, useState, useContext, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';


import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import authApi from '../../api/Authentication.jsx';
import apiUtil from '../../api/apiUtil.jsx';

import logo from '../../assets/img/wn_logo.svg';


export default function SignIn() {
  let history = useHistory();

  const { clearStorage, token, refreshToken, authenticated, setAuthenticated, setToken, setCurrentRole, setAccessToken, setRefreshToken, setTokenType, setUser, setAppPermission } = useContext(AuthContext);
  
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);
  const [validatedPass, setValidatedPass] = useState(false);
  const [errorPass, setErrorPass] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const isMounted = useRef(null);
  const emailInput = useRef();
  const pswInput = useRef();
  const axiosRef = useRef();

  const handleClose = () => {
    setIsOpen(false);
  };

  const goFmsPage = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    //check if authenticated
    // checkToken();
    if(authenticated){
      let role = localStorage.getItem('current_role');
      if (role === 'Customer Template Role') {
        history.push("/job-list");
      } else {
        history.push("/");
      }
    }

    // executed when component mounted
    axiosRef.current = apiUtil.getCancelToken();
    isMounted.current = true;

    return () => {
      // executed when unmount
      isMounted.current = false;
      axiosRef.current.cancel();
    }
  }, []);

  const checkToken = () => {
    let tokenObj = apiUtil.getObject(token);
    if(tokenObj && refreshToken){
      var created = new Date(tokenObj.created_at);
      var expired = new Date(created);
      expired.setSeconds(expired.getSeconds() + tokenObj.expires_in);

      if (expired < new Date()) {
        setAuthenticated(false);
        callRefreshTokenApi(refreshToken);
      }
    } else {
      clearStorage();
      return;
    }
  }

  const callRefreshTokenApi = (refreshToken) => {
    authApi.refreshToken(refreshToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          data.created_at = data.created_at * 1000;
          
          setToken(JSON.stringify(data));
          setAccessToken(data.access_token);
          setRefreshToken(data.refresh_token);
          setTokenType(data.token_type);
          setAuthenticated(true);
        }, (error, type) => {
          clearStorage();
          return;
        });
    });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
    const form = e.currentTarget;

    setError(null);

    setValidatedEmail(false);
    setErrorEmail(null);
    setValidatedPass(false);
    setErrorPass(null);
    
    if(emailInput.current.lastChild.firstChild.value === ''){
      setValidatedEmail(true);
      setErrorEmail('Please enter your email address.');
    }

    if(pswInput.current.lastChild.firstChild.value === ''){
      setValidatedPass(true);
      setErrorPass('Please enter your password.');
    }

    if (form.checkValidity() === true) {
      setIsLoading(true);

      authApi.login(emailInput.current.lastChild.firstChild.value, pswInput.current.lastChild.firstChild.value, axiosRef.current.token)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let appAccessPermissions = [];

            if (data.owner_info.application_registrations.length > 0) {
              data.owner_info.application_registrations.forEach((el) => {
                if (el.name === "AUTH") {
                  appAccessPermissions.push("auth");
                } else if (el.name === "WLS") {
                  appAccessPermissions.push("wls");
                } else if (el.name === "WMS") {
                  appAccessPermissions.push("wms");
                }
              });

              window.localStorage.setItem("appPermission", appAccessPermissions);
            }

            setAuthenticated(true);
            setCurrentRole(data.owner_info.roles[0] !== undefined ? data.owner_info.roles[0].role_name : null);
            setAccessToken(data.token.access_token);
            setRefreshToken(data.token.refresh_token);
            setTokenType(data.token.token_type);
            setToken(JSON.stringify(data.token));
            setUser(JSON.stringify(data));
            setAppPermission(appAccessPermissions);

            let role = localStorage.getItem('current_role');
            if (role === 'Customer Template Role') {
              history.push("/job-list");
            } else {
              history.push("/");
            }
          }, (error, type) => {
            setError({ title: error });
            setIsLoading(false);
          });
      });
    }
  };



  return (
    <div className="signin container">
        <Dialog
        open={isOpen}
        onClick={handleClose}
        >
          <DialogContent style={{padding:"50px", lineHeight:"30px"}} >
          We will cease support for <em style={{color:"#64CCC9", fontWeight:800}}>Worknode.AI</em> from Dec 2021 onwards. To all new and existing users, please visit <a href="https://fms.evfy.sg">https://fms.evfy.sg</a> instead. 
            </DialogContent >

            <div style={{textAlign:'center', paddingBottom:"50px"}}>
            <button style={{backgroundColor:"#64CCC9", border:"none"}}>
            <a href="https://fms.evfy.sg" style={{textDecoration:"none"}}><Button onClick={handleClose}>Check Here!!</Button></a>
            </button>
            </div>
            {/* <Button onClick={handleClose} > Close</Button> */}
           
        </Dialog>  
        
    <div className="cover">
      <div className="cover__text">Equipment you can count on. People you can trust</div>
    </div>
    <div className="login" component="main">
      <div className="top-menu">
        <div className="logo"><img src={logo} alt=""/></div>
        <div className="signup-acc"> 
        <div>Don't have an account?</div>
        <Button variant="contained" color="primary" href="/Sign-up">
          Sign Up
        </Button>
        </div>
      </div>
      {
        error && !isLoading
        &&
        <Box textAlign="left" mt={3} mb={3}>
          <Alert severity="error">
            "Incorrect username or password!"
            {/* {error.title && <b>{error.title}</b>}
            {(error.errors && error.errors.length > 0) && error.errors.map((e, i) => {
              return <div key={i}>{e}</div>
            })} */}
          </Alert>
        </Box>

        
      }
      <div className="form-wrapper">
      <form noValidate onSubmit={handleSubmit}>

        <Box textAlign="center" mb={3}>
          <div className="form-title">
          Welcome back!
          </div>
          <div className="form-description">
          Login into your dash express dashboard with your credentials
          </div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={validatedEmail} 
            helperText={errorEmail}
            ref={emailInput}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={validatedPass} 
            helperText={errorPass}
            ref={pswInput}
          />
        </Box>
        <Box mb={1}>
          <Grid container>
            <Grid item xs>
              {/*<Link href="#" variant="body2">
                Forgot password?
              </Link>*/}
            </Grid>
            <Grid item>
            </Grid>
          </Grid>
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="contained" 
          color="primary"
          disabled={isLoading}
        >
          <Box pl={2} pr={2} style={{ color: 'white' }}>SIGN IN</Box>
          {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
        </Button>
      </form>
      {/* <Box mt={6}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="/">TaskkMe</Link>
          {' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box> */}
      </div>
    </div>
    </div>
  );
}