import React, { useEffect, useState, useCallback } from 'react';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Signin from '../../views/Account/Signin.jsx';
import SignUp from "../../views/Account/SignUp.jsx";
import SignUpSuccess from "../../views/Account/SignUpSuccess.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import Api from "../../api/api.jsx";
import { CountryDataService } from '../../data/country-data';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import countryList from '../../assets/countryList.json';

const theme = createMuiTheme({
  /* RESPONSIVE -> It is also necessary to change at _mixins.scss*/
  breakpoints: {
    values: {
      xs: 0, 
      sm: 768, 
      md: 992, 
      lg: 1200, 
      xl: 1366
    }
  },
  palette: {
    primary: {
      light: '#83d6d3',
      main: '#64CCC9',
      dark: '#468e8c',
      contrastText: '#fff',
    },
    secondary: {
      light: '#3f464c',
      main: '#101820',
      dark: '#0b1016',
      contrastText: '#fff',
    }
  }
});


export const AuthContext = React.createContext();


export default ({ children }) => {
  const prevAuth = window.localStorage.getItem('authenticated') || false;
  const prevAccessToken = window.localStorage.getItem('access_token') || null;
  const prevRefreshToken = window.localStorage.getItem('refresh_token') || null;
  const prevTokenType = window.localStorage.getItem('token_type') || null;
  const prevCurrentRole = window.localStorage.getItem('current_role') || null;
  const prevToken = window.localStorage.getItem('token') || null;
  const prevUser = window.localStorage.getItem('user') || null;
  const prevIsCustomerRequired = window.localStorage.getItem('isCustomerRequired') || true;
  let prevIsOnBoarding = window.localStorage.getItem('isOnBoarding') || false;
  if (prevIsOnBoarding === "false") {
    prevIsOnBoarding = false;
  }
  const prevOnBoardingCustomer = window.localStorage.getItem('onBoardingCustomer') || null;
  const prevOnBoardingVehicle = window.localStorage.getItem('onBoardingVehicle') || null;
  const prevOnBoardingDriver = window.localStorage.getItem('onBoardingDriver') || null;
  const prevOnBoardingJob = window.localStorage.getItem('onBoardingJob') || null;

  const prevAppPermission = window.localStorage.getItem('appPermission') || [];

  const [authenticated, setAuthenticated] = useState(prevAuth);
  const [accessToken, setAccessToken] = useState(prevAccessToken);
  const [currentRole, setCurrentRole] = useState(prevCurrentRole);
  const [refreshToken, setRefreshToken] = useState(prevRefreshToken);
  const [tokenType, setTokenType] = useState(prevTokenType);
  const [token, setToken] = useState(prevToken);
  const [user, setUser] = useState(prevUser);
  const [tokenCookie, setTokenCookie] = useState(false);
  const [isCustomerRequired, setIsCustomerRequired] = useState(prevIsCustomerRequired);
  const [isOnBoarding, setIsOnBoarding] = useState(prevIsOnBoarding);
  const [onBoardingCustomer, setOnBoardingCustomer] = useState(prevOnBoardingCustomer);
  const [onBoardingVehicle, setOnBoardingVehicle] = useState(prevOnBoardingVehicle);
  const [onBoardingDriver, setOnBoardingDriver] = useState(prevOnBoardingDriver);
  const [onBoardingJob, setOnBoardingJob] = useState(prevOnBoardingJob);

  const [appPermission, setAppPermission] = useState(prevAppPermission);


  const clearStorage = () => {
    setAuthenticated(false);
    setAccessToken('');
    setCurrentRole(null);
    setRefreshToken('');
    setTokenType('');
    setToken(null);
    setUser(null);
    setIsCustomerRequired(true);
    setIsOnBoarding(true);
    setOnBoardingCustomer(null);
    setOnBoardingVehicle(null);
    setOnBoardingDriver(null);
    setOnBoardingJob(null);
    setAppPermission([]);
    window.localStorage.clear();

    apiUtil.deleteCookie("worknode_access_token");
    apiUtil.deleteCookie("worknode_refresh_token");
    apiUtil.deleteCookie("worknode_token_type");
    apiUtil.deleteCookie("worknode_token");
    apiUtil.deleteCookie("worknode_user");
  };

  const callReadSettingsApi = useCallback(() => {

    let driver_label = localStorage.getItem('global_driver_label');
    let manpower_label = localStorage.getItem('global_manpower_label');
    let country_phone = localStorage.getItem('country_phone');
    let access_token = localStorage.getItem('access_token');

    Api.read('application_company_settings', {}, access_token)
      .then(result => {
          apiUtil.parseResult(result, data => {
              
              let prevFilterDataId = 0;

              data.result.filter((filterData) => {
           
                if(filterData.group_name === "Country") {
                  let jsonParse = JSON.parse(filterData.json_data);
                  localStorage.setItem('Country',  jsonParse.name);
                  let cloneCountryList =   countryList.map(object => ({ ...object }));
                  let timeZoneList = cloneCountryList.filter((oldCountry) => {
                    if(jsonParse.name === oldCountry.value) {
                        oldCountry.value = oldCountry.offset;
                        oldCountry.label = "GMT "+oldCountry.offset + "("+ oldCountry.identifier+")";;
                        return oldCountry;
                    }
                  });
                  localStorage.setItem('TimeZoneList', JSON.stringify(timeZoneList));
                  localStorage.setItem('TimeZone', jsonParse.offset);

                }

                if (filterData.rule === "job_column_settings") {
                  if (filterData.id > prevFilterDataId) {
                    localStorage.setItem("job_column_settings", JSON.stringify(filterData));
                    prevFilterDataId = filterData.id;
                  }
                }
              })
              driver_label = data.result.filter(
                (setting) => {
                  localStorage.setItem("channel", setting.application_company_id.toString())
                  return setting.rule === "driver_label";
                }
              )[0].view_name
              driver_label = driver_label? driver_label : "Driver";
              localStorage.setItem('global_driver_label', driver_label);
              
              let is_customer_required = data.result.filter(x => x.rule === "is_customer_required")
              if(is_customer_required && is_customer_required.length > 0){
                setIsCustomerRequired(is_customer_required[0].enable);
              } else {
                setIsCustomerRequired(false);
              }
              
              manpower_label = data.result.filter(
                (setting) => {
                  return setting.rule === "manpower_label";
                }
              )[0].view_name
              manpower_label = manpower_label? manpower_label : "Manpower";
              localStorage.setItem('global_manpower_label', manpower_label);
    
              country_phone = data.result.filter(
                (setting) => {
                  return setting.rule === "country_code";
                }
              )[0].json_data
              let found = false;
              if(country_phone){
                const countries = new CountryDataService().getPhoneCode(); 
                let phone = JSON.parse(country_phone);
                countries.forEach(objCountry=>{
                  if(objCountry.countryCode.toUpperCase() === phone.code){
                    country_phone = objCountry.dialCode;
                    found = true
                  }
                });
              }
    
              localStorage.setItem('country_phone', found? country_phone:"");
          })
      })

    Api.read('application_unit_measurements', {}, accessToken)
    .then(result => {
        apiUtil.parseResult(result, data => {
            const res = (data.result && data.result.length > 0) ? data.result : []
            res.forEach( item => {
              if(item.module && item.module != "" && item.is_active){
                localStorage.setItem(item.measurement_type, item.measurement);
              }
            })
        })
    })
  },[accessToken])

  useEffect(
    () => {
      // set company settings and unit measurements 
      const initAppSettings = () => {
        callReadSettingsApi()
      }

      const loginFromCookie = (accessToken) => {
        Api.read('profile/admins', {}, accessToken).then(result => {
          apiUtil.parseResult(result, data => {
            window.localStorage.setItem('authenticated', true);
            window.localStorage.setItem('access_token', accessToken);
            window.localStorage.setItem('current_role', currentRole);

            let refreshToken = apiUtil.getCookie('worknode_refresh_token');
            if (refreshToken !== null) {
              window.localStorage.setItem('refresh_token', refreshToken);
            }

            let tokenType = apiUtil.getCookie('worknode_token_type');
            if (tokenType !== null) {
              window.localStorage.setItem('token_type', tokenType);
            }

            let token = apiUtil.getCookie('worknode_token');
            if (token !== null) {
              window.localStorage.setItem('token', token);
            }

            let user = {
              status: true,
              token: token,
              owner_info: data.result,
              result: data.result
            };

            window.localStorage.setItem('user', JSON.stringify(user));
            window.location.replace("/");
          });

        }, (error, type) => {
        });
      }

      if(authenticated){
        window.localStorage.setItem('authenticated', authenticated);
        window.localStorage.setItem('access_token', accessToken);
        window.localStorage.setItem('current_role', currentRole);
        window.localStorage.setItem('refresh_token', refreshToken);
        window.localStorage.setItem('token_type', tokenType);
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('user', user);
        window.localStorage.setItem('isCustomerRequired', isCustomerRequired);
        window.localStorage.setItem('isOnBoarding', isOnBoarding);
        window.localStorage.setItem('onBoardingCustomer', onBoardingCustomer);
        window.localStorage.setItem('onBoardingVehicle', onBoardingVehicle);
        window.localStorage.setItem('onBoardingDriver', onBoardingDriver);
        window.localStorage.setItem('onBoardingJob', onBoardingJob);
        initAppSettings();

        if (!tokenCookie && (accessToken !== null && refreshToken !== null && tokenType !== null && token !== null && user !== null)) {
          apiUtil.setCookie("worknode_access_token", accessToken);
          apiUtil.setCookie("worknode_refresh_token", refreshToken);
          apiUtil.setCookie("worknode_token_type", tokenType);
          apiUtil.setCookie("worknode_token", token);
          apiUtil.setCookie("worknode_user", user);
          window.localStorage.setItem('has_logged_in_cookie', true);
          setTokenCookie(true);
        }
      } else {
        let accessTokenCookie = apiUtil.getCookie("worknode_access_token");
        let checkLoginCookie = window.localStorage.getItem('has_logged_in_cookie') || null;

        if (accessTokenCookie !== null && checkLoginCookie === null) {
          loginFromCookie(accessTokenCookie);
        }

        window.localStorage.removeItem('authenticated');
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('current_role');
        window.localStorage.removeItem('refresh_token');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('isCustomerRequired');
        window.localStorage.removeItem('isOnBoarding');
        window.localStorage.removeItem('onBoardingCustomer');
        window.localStorage.removeItem('onBoardingVehicle');
        window.localStorage.removeItem('onBoardingDriver');
        window.localStorage.removeItem('onBoardingJob');
      }
      
    },
    [authenticated, currentRole, token, accessToken, refreshToken, tokenType, user, tokenCookie, isCustomerRequired, isOnBoarding, onBoardingCustomer, onBoardingVehicle, onBoardingDriver, onBoardingJob, appPermission, callReadSettingsApi]
  );


  const defaultContext = {
    clearStorage,
    authenticated,
    setAuthenticated,
    token,
    currentRole,
    setCurrentRole,
    setToken,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    tokenType,
    setTokenType,
    user,
    setUser,
    isCustomerRequired,
    setIsCustomerRequired,
    isOnBoarding,
    setIsOnBoarding,
    onBoardingCustomer,
    setOnBoardingCustomer,
    onBoardingVehicle,
    setOnBoardingVehicle,
    onBoardingDriver,
    setOnBoardingDriver,
    onBoardingJob,
    setOnBoardingJob,
    appPermission,
    setAppPermission
  };

  
  return (
    <MuiThemeProvider theme={theme}>
      <AuthContext.Provider value={defaultContext}>
        {
          authenticated
          ?
          children
          :
          <div className="App">
            <BrowserRouter>
              <Switch>
                <Route exact path="/">
                  <Redirect to={"/Sign-in"} />
                </Route>
                
                <Route path="/Sign-in" component={Signin} />
                <Route path="/Sign-up"  component={SignUp} />
                <Route path="/sign-up-success"  component={SignUpSuccess} />

                <Route component={Signin} />
              </Switch>
            </BrowserRouter>
          </div>
        }
      </AuthContext.Provider>
    </MuiThemeProvider>
  );
};