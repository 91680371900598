import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import {
    Tooltip
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';


const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
}))(Tooltip);


const CustomTooltip = props => {
    let [icon] = React.useState(props.icon ? props.icon : <Icon component={'i'}>help</Icon>);
    let [placement] = React.useState(props.placement ? props.placement : 'top');

    if(props.light) {
        return <LightTooltip className={"custom-tooltip-component " + props.className} title={props.title} placement={placement}>{icon}</LightTooltip>
    } else {
        return <Tooltip className={"custom-tooltip-component " + props.className} title={props.title} placement={placement}>{icon}</Tooltip>
    }
  }

  export default CustomTooltip;