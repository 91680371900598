import React, { Component } from 'react';
import uuid from 'react-uuid'

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";

import { 
  Box,
  Grid,
  Divider,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';

import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';

import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CustomFormDialog from '../../../components/Dialog/CustomFormDialog.jsx';

import CompanyAddressForm from './CompanyAddressForm.jsx';


export class CompanyAddressList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let preview = ((props.preview === true) || (props.preview === false)) ? props.preview : false;
    let items = (props.items && props.items.length > 0) ? props.items : [];

    this.state = {
      openDialogEdit: false,
      itemDialogEdit: null,

      openDialogDelete: false,
      itemDialogDelete: null,

      customer_addresses: items,
      preview: preview,
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.state.items) {
        let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
        this.setState({
            customer_addresses: items
        });
    }
  }


  /* FUNCTIONS */
  addNew = (form = null, callback = null) => {
    let customer_addresses = this.state.customer_addresses;

    if(form) {
        if(form.is_main){
            this.clearIsMain();
        }
        
        form['uuid'] = uuid();
        form['remove'] = false;
        customer_addresses.push(form);
    } else {
        let checkIsMain = this.checkIsMainExists();

        customer_addresses.push({
            uuid: uuid(),
            name: '',
            address_line1: '',
            contact_no: '',
            is_main: !checkIsMain,
            remove: false,
        });
    }
    
    this.setState({
        customer_addresses: customer_addresses
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onAddNew){
            this.props.onAddNew(this.state.customer_addresses);
        }
    });
  }

  edit = (item = null, form = null, callback = null) => {
    let customer_addresses = this.state.customer_addresses;

    let editIndex = customer_addresses.findIndex(e => e.uuid === item.uuid);
    if(editIndex !== -1){
        let oldForm = customer_addresses[editIndex];

        if(form.is_main){
            this.clearIsMain();
        }

        form['uuid'] = item.uuid;
        form['delete'] = false;
        customer_addresses[editIndex] = form;

        oldForm['remove'] = true;
        oldForm['is_main'] = false;
        customer_addresses.push(oldForm);
    }
    
    this.setState({
        customer_addresses: customer_addresses
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onEdit){
            this.props.onEdit(this.state.customer_addresses);
        }
    });
  }

  remove = (uuid = '', callback = null) => {
    let customer_addresses = this.state.customer_addresses;

    let index = customer_addresses.findIndex(e => e.uuid === uuid);
    if(index !== -1){
        customer_addresses[index]['remove'] = true;
    }
    
    this.setState({
        customer_addresses: customer_addresses
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onRemove){
            this.props.onRemove(this.state.customer_addresses);
        }
    });
  }

  checkIsMainExists = () => {
    let customer_addresses = this.state.customer_addresses;

    if(customer_addresses && customer_addresses.length > 0){
        let isMain = customer_addresses.filter(x => !x.remove).filter(x => x.is_main === true);

        return (isMain && isMain.length > 0) ? true : false;
    }

    return false;
  }
  clearIsMain = () => {
    let customer_addresses = this.state.customer_addresses;

    if(customer_addresses && customer_addresses.length > 0){
        let isMain = customer_addresses.filter(x => x.is_main === true);

        if(isMain && isMain.length > 0){
            customer_addresses = customer_addresses.map((item, i) => {
                item.is_main = false;
                return item;
            });
        }
    }
  }
  /* END FUNCTIONS */


  /* DIALOG */
  editDialog = () => {
      return <CustomFormDialog 
          open={this.state.openDialogEdit}
          title={'Company Address'}
          onClose={() => {
            this.setState({
                openDialogEdit: false,
                itemDialogEdit: null,
            });
          }}
      >
          <CompanyAddressForm
            item={this.state.itemDialogEdit}
            allItems={this.state.customer_addresses}
            onSave={(isEdit, form) => {
                let item = this.state.itemDialogEdit;

                if(isEdit) {
                    this.edit(item, form, () => {
                        this.setState({
                            openDialogEdit: false,
                            itemDialogEdit: null,
                        });
                    });
                } else {
                    this.addNew(form, () => {
                        this.setState({
                            openDialogEdit: false,
                            itemDialogEdit: null,
                        });
                    });
                }
                
            }}
            onClose={() => {
                this.setState({
                    openDialogEdit: false,
                    itemDialogEdit: null,
                });
            }}
          />
      </CustomFormDialog>
  }

  deleteDialog = () => {
      return <CustomDialog 
          open={this.state.openDialogDelete}
          title={'Delete'}
          onClose={() => {
            this.setState({
                openDialogDelete: false,
                itemDialogDelete: null,
            });
          }}
          onOk={() => {
              let item = this.state.itemDialogDelete;
              this.remove(item.uuid, () => {
                this.setState({
                    openDialogDelete: false,
                    itemDialogDelete: null,
                });
              });
          }}
      >
          <Box>Are you sure you want to delete?</Box>
      </CustomDialog>
  }
  /* END DIALOG */


  render() {
    return <Box pt={5} pb={5}>
        <Grid container>
            {!this.state.preview && <Grid item xs={12}>
                <CustomLabel
                    label={'Company Address'}
                    weight={'bold'}
                    size={'md'}
                    labelComponent={<CustomTooltip title={'You can have many addresses for a customer, only one will be selected as main address. Other addresses can still be selected later on.'} />}
                />
                <Divider light />
            </Grid>}
            <Grid item xs={12}>
                <Box>

                    {
                        (this.state.customer_addresses && this.state.customer_addresses.length > 0 && this.state.customer_addresses.filter(x => !x.remove).length > 0)
                        ?
                        this.state.customer_addresses.filter(x => !x.remove).map((item, i) => {
                            return <Box key={i} pt={1} pb={1}>
                                <Grid container alignItems={'center'}>
                                    <Box clone pl={{ xs: 0, md: 2}}>
                                        <Grid item xs={12} md={2}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Label'} placement={'top'}>
                                                        <Box>{item.name}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={(!this.state.preview) ? 4 : 6}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Full Address'} placement={'top'}>
                                                        <Box>{item.address_line1}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={2}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Phone Number'} placement={'top'}>
                                                        <Box>{item.contact_no}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={2}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Primary Address'} placement={'top'}>
                                                        <Box>{(item.is_main) ? 'Yes' : 'No'}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    {!this.state.preview && <Box clone pr={{ xs: 0, md: 2}}>
                                        <Grid item xs={12} md={2}>
                                            <Grid container justify={'flex-end'}>
                                                <Box clone>
                                                    <Grid item xs={'auto'}>
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    openDialogEdit: true,
                                                                    itemDialogEdit: item,
                                                                });
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Box>
                                                <Box clone>
                                                    <Grid item xs={'auto'}>
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    openDialogDelete: true,
                                                                    itemDialogDelete: item,
                                                                });
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>}
                                </Grid>
                                <Divider light />
                            </Box>
                        })
                        :
                        <Grid container>
                            <Box clone pt={3} textAlign={'center'}>
                                <Grid item xs={12}>
                                    <CustomLabel
                                        label={'No Results!'}
                                        weight={'bold'}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    }

                    {!this.state.preview && <Grid container>
                        <Box clone>
                            <Grid item xs={12}>
                                <CustomButton 
                                    color={'primary'}
                                    href={'/'}
                                    onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                            openDialogEdit: true,
                                            itemDialogEdit: null,
                                        });
                                    }}
                                >
                                    <Icon component={'i'}>add</Icon>
                                    <Box display={'inline-block'} pl={1}>Add New Company Address</Box>
                                </CustomButton>
                            </Grid>
                        </Box>
                    </Grid>}

                </Box>
            </Grid>
        </Grid>
        {this.editDialog()}
        {this.deleteDialog()}
    </Box>
  }
}

export default CompanyAddressList;