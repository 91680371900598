import React, { Component } from 'react';
import { read as readXsl, utils as xslUtils } from 'xlsx';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";

import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomSelectOption from '../../../components/CustomFields/CustomSelectOption.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import Table from '../../../components/Table/Table.jsx';


export class CustomerImportDialog extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let file = (props.file) ? props.file : null;
    
    this.state = {
        isLoading: isLoading,
        file: file,
        importedSheetData: [],
        headers: [],
        excelRows: [],

        dataFields: [
            { label: 'Company Name', value: 'company_name', required: true, disabled: false },
            { label: 'Company Code', value: 'company_code', required: true, disabled: false },
            { label: 'Company Address', value: 'company_address', required: true, disabled: false },
            { label: 'Company Contact', value: 'contact_no', required: true, disabled: false },
            { label: 'Department', value: 'department', required: false, disabled: false },
            { label: 'PIC', value: 'pic', required: false, disabled: false },
            { label: 'PIC Email', value: 'pic_email', required: false, disabled: false },
            { label: 'PIC Number', value: 'pic_contact', required: false, disabled: false }
        ],

        columns: [],
        rows: [],
    }
  }
  

  componentDidMount() {
    if(this.state.file){
        this.loadWorkSheet();
    } else {
        if(this.props.onClose){
            this.props.onClose();
        }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.file !== this.state.file) {
        let file = (nextProps.file) ? nextProps.file : null;
        this.setState({
            file: file
        });
    }
  }


  /* FUNCTIONS */
  loadWorkSheet = () => {
    if (typeof (FileReader) !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
            reader.onload = (e) => {
                let wb = readXsl(reader.result, {type: 'binary'});
                
                let wsname = wb.SheetNames[0];
                let ws = wb.Sheets[wsname];
               
                const data = xslUtils.sheet_to_json(ws, { defval: null, header: 1, dateNF: 'dd/mm/yyyy;@' });
                
                this.setState({
                    importedSheetData: data,
                }, () => {
                    this.initColumns();
                });
            };
            reader.readAsBinaryString(this.state.file);
        }
    } else {
        console.log("This browser does not support HTML5.");
    }
  }

  initColumns = () => {
    let columns = [];
    let headers = [];

    if(this.state.importedSheetData && this.state.importedSheetData.length > 0){
        let heads = this.state.importedSheetData[0];
        headers = heads.map((head, i) => {
            let labelItem = this.state.dataFields.filter(x => x.value === head);

            return {
                value: head,
                label: (labelItem && labelItem.length > 0) ? labelItem[0].label : head,
                disabled: false,
            }
        });
    }

    this.state.dataFields.map((feild, i) => {
        let headerIndex = headers.findIndex(x => x.value === feild.value);
        if(headerIndex !== -1){
            headers[headerIndex].disabled = true;
            feild.selected = (feild.value && feild.value !== '') ? feild.value : '';
            feild.disabled = true;
        }

        columns.push({ 
            field: (feild.selected && feild.selected !== '') ? feild.selected : '',
            realField: feild.value,
            title: feild.label,
            required: feild.required,
            width: 200,
            sorting: false,
            searchable: false,
            grouping: false,
            filtering: false,
            render: (row, type) => {
                return this.customCell(row, feild);
            }
        });
    });

    this.setState({
        columns: columns,
        headers: headers,
    }, () => {
        this.initRows();
    });
  }

  initRows = () => {
    let excelRows = [];
    
    if (this.state.importedSheetData && this.state.importedSheetData.length > 1) {
        excelRows = this.state.importedSheetData.map((item, i) => {
            if(i > 0){
                let row = this.state.importedSheetData[0].reduce((sum, key, index) => Object.assign(sum, { [key]: item[index] }), {});
                return row;
            } else {
                return null;
            }
        });
    }

    if(excelRows && excelRows.length > 0){
        excelRows.splice(0, 1);
    }

    let rows = this.initChangeColumns(excelRows);
  
    this.setState({
        excelRows: excelRows,
        rows: rows,
    });
  }

  initChangeColumns = (excelRows = []) => {
    let rows = [];

    if(excelRows && excelRows.length > 0){
        excelRows.map((row, i) => {
            let rowArray = [];

            let counter = 0;
            Object.entries(row).map(([ key, value ]) => {
                let realKey = key;
                if(this.state.dataFields && this.state.dataFields[counter] && this.state.dataFields[counter].value){
                    realKey = this.state.dataFields[counter].value;
                };

                let columnsIndex = this.state.columns.findIndex(x => x.realField === key);
                if(columnsIndex !== -1){
                    if(this.state.columns[columnsIndex].realField && this.state.columns[columnsIndex].realField !== ''){
                        let realField = this.state.columns[columnsIndex].realField;
                        rowArray[realField] = (row[realField] && row[realField] !== '') ? row[realField] : '';
                        rowArray[realField + '_validated'] = false;
                    } else {
                        rowArray[realKey] = '';
                        rowArray[realKey + '_validated'] = false;
                    }
                } else {
                    rowArray[realKey] = '';
                    rowArray[realKey + '_validated'] = false;
                }

                counter++;
            });

            rows.push(Object.assign({}, rowArray));
        });
    }

    return rows;
  }

  changeColumns = (realField, selectedField) => {
    let excelRows = this.state.excelRows
    let rows = this.state.rows
    let newRows = [];

    if((rows && rows.length > 0) && (excelRows && excelRows.length > 0)){
        newRows = rows.map((row, i) => {
            row[realField] = (excelRows[i][selectedField] && excelRows[i][selectedField] !== '') ? excelRows[i][selectedField] : '';
            return row;
        });
    }

    return newRows;
  }

  getForm(){
    let data = [];

    let rows = this.state.rows;
    if(rows && rows.length > 0){
        rows.forEach(objData => {
            let contact_persons = [];
      
            if(objData.pic){
              contact_persons = [{
                first_name : objData.pic,
                email : objData.pic_email?objData.pic_email:'',
                contact_no : objData.pic_contact?objData.pic_contact:''
              }]
            }
      
      
            let itemData = {
              company_code : objData.company_code,
              company_name : objData.company_name,
              is_active : true,
              addresses:[{
                name : 'Main Office',
                address_line1 : objData.company_address,
                contact_no : objData.contact_no,
                is_main:true
              }],
              departments:[{
                department_name : objData.department?objData.department:'Main Department',
                contact_persons : contact_persons
              }]
      
            }
      
            data.push(itemData);
          });
    }
    
    return data;
  }
  /* END FUNCTIONS */


  /* TABLE */
  customTable = () => {
    return <Table 
        className={'import-table'}
        Header={this.customHeader}
        paging={false}
        tableLayout={'fixed'}
        data={this.state.rows}
        columns={this.state.columns}
    />
  }

  customHeader = (props) => {
    return <thead className={'custom-head-thead'}>
        <tr className={'custom-head-tr'}>
            {props.columns.map((col, i) => {
                return <td key={i} className={'custom-head-th'} width={col.width}>
                    <Box>
                        <CustomSelectOption
                            className={'no-margin white'}
                            label={<Box fontWeight={'bold'}>{col.title}{((col.required) ? <span className={'text-danger font-size-18'}>*</span> : '')}</Box>}
                            placeholder={'Choose one'}
                            initFirstItem={true}
                            value={col.field}
                            items={this.state.headers}
                            onChange={(value, item) => {
                                let columns = this.state.columns;
                                let headers = this.state.headers;

                                if(value !== 0){
                                    let oldValue = col.field;
                                    let headerIndexOld = headers.findIndex(x => x.value === oldValue);
                                    if(headerIndexOld !== -1){
                                        headers[headerIndexOld].disabled = false;
                                    }

                                    col.field = item.value;
                                    
                                    let headerIndex = headers.findIndex(x => x.value === item.value);
                                    if(headerIndex !== -1){
                                        headers[headerIndex].disabled = true;
                                    }
                                } else {
                                    let oldValue = col.field;
                                    col.field = '';
                                    
                                    let headerIndex = headers.findIndex(x => x.value === oldValue);
                                    if(headerIndex !== -1){
                                        headers[headerIndex].disabled = false;
                                    }
                                }

                                columns[i] = col;
                                this.setState({
                                    columns: columns,
                                    headers: headers,
                                }, () => {
                                    let rows = this.changeColumns(col.realField, col.field);
                                    this.setState({
                                        rows: rows,
                                    });
                                });
                            }}
                        />
                    </Box>
                </td>
            })}
        </tr>
    </thead>
  }

  customCell = (row, feild) => {
    row[feild.value + '_validated'] = (row[feild.value] && row[feild.value] !== '') ? false : feild.required;
    let isError = (feild.required) ? row[feild.value + '_validated'] : false;

    return <Box>
        <CustomInput
            className={'no-margin'}
            placeholder={feild.label}
            value={row[feild.value]}
            required={feild.required}
            error={isError}
            onChange={(e) => {
                row[feild.value] = e.target.value;
                row[feild.value + '_validated'] = (e.target.value !== '') ? false : feild.required;
            }}
        />
    </Box>;
  }
  /* END TABLE */


  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone pb={2}>
                <Grid item xs={12}>
                    <CustomLabel
                        className={'no-margin'}
                        label={<Box fontWeight={'bold'}>Match your uploaded sheet headers to our form. Fields marked with <span className={'text-danger font-size-18'}>*</span> is mandatory.</Box>}
                        size={'md'}
                    />
                    <CustomLabel
                        className={'no-margin'}
                        label={'You may perform changes to your customer details in the columns below.'}
                        size={'sm'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.customTable()}
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        Confirm
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;
    
    if (form.checkValidity() === true) {
        if(this.props.onImport){
            this.props.onImport(this.getForm());
        }
    } else {
        this.setState({
            isLoading: false
        }, () => {
            apiUtil.toast('There some are field(s) which are empty. Please fill in these empty fields to continue.', 'check_circle', 'error');
        });
    }
  }
  /* END SUBMIT */


  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default CustomerImportDialog;