import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import wmsApi from "../../api/wmsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';

import { 
  Box,
  Grid,
  Badge,
  IconButton,
} from '@material-ui/core';

import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as ImportIcon } from '../../assets/img/icons/import.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/img/icons/arrow-up.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomSwitch from '../../components/CustomFields/CustomSwitch.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CircleIcon from '../../components/CircleIcon/CircleIcon.jsx';
import StatusItem from '../../components/Status/StatusItem.jsx';
import CustomGuideStepper from '../../components/Stepper/CustomGuideStepper.jsx';

import CustomerPreviewDialog from './Partial/CustomerPreviewDialog.jsx';
import CustomerImportDialog from './Partial/CustomerImportDialog.jsx';

import Tour from 'reactour'


export class Customers extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    this.state = {
      isLoadingStatus: false,
      totalActive: 0,
      totalDisabled: 0,
      totalActiveDisabled: 0,

      isLoading: false,
      grouping: true,
      limit: apiUtil.getDefaultPageSize(),
      data: [],
      total: 0,
      page: 1,

      activeInactiveCustomers: 0,

      openDialog: false,
      openDialogItem: null,

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,

      isTourOpen: false,
      openSkipDialog: false,
    }
  }
  

  componentDidMount() {
    const { isOnBoarding } = this.context;

    this.callInitApis();

    if(isOnBoarding){
      this.setState({
        isTourOpen: true,
      });
    }
  }


  callInitApis = () => {
    this.loadStatusApi();
    this.callReadApi();
  }
  loadStatusApi = () => {
    this.callStatusApi(true, (data) => {
      if(data){
        this.setState({
          totalActive: data.total,
          totalActiveDisabled: (data.total + this.state.totalDisabled),
        });
      }
    });
    this.callStatusApi(false, (data) => {
      if(data){
        this.setState({
          totalDisabled: data.total,
          totalActiveDisabled: (data.total + this.state.totalActive),
        });
      }
    });
  }


  /* API */
  callStatusApi = (is_active = true, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let param = {
        page: 1,
        take: 1,
        is_active: is_active,
      };

      wmsApi.read('customers', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  
  callReadApi = (search = '') => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        page: this.state.page,
        take: this.state.limit,
        is_active: (this.state.activeInactiveCustomers === 0) ? true : false,
        search: search,
      };

      wmsApi.read('customers', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              data: (data.result && data.result.length > 0) ? data.result : [],
              total: data.total,
              isLoading: false,
            });

            this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  callUpdateIsActiveApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.update('customers/' + row.id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        destroy_all: true,
      };

      wmsApi.delete('customers/' + row.id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callImportApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.create('create/customers/multiple', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    const { isOnBoarding } = this.context;

    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb xs={isOnBoarding ? 'auto' : true} />
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {this.setStepper()}
          </Grid>
        </Box>}
        <Box clone pr={1}>
          <Grid item xs={'auto'}>
            <CustomUploadButton 
              color={'primary'}
              accept={'.xls, .xlsx'}
              onChange={(files) => {
                if(files && files.length > 0){
                  this.setState({
                    openImportDialog: true,
                    openImportDialogItem: files[0],
                  });
                }
              }}
            >
              <ImportIcon />
            </CustomUploadButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              className={'new-customer-step'}
              color={'secondary'}
              onClick={() => {
                this.props.history.push('/customers-form');
              }}
            >
              + New Customer
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* STATUS */
  customStatus = () => {
    return <Grid container alignItems={'center'}>
      <Box clone>
        <Grid item xs={'auto'}>
          <CircleIcon />
        </Grid>
      </Box>
      <Box clone pl={3}>
        <Grid item xs={true}>
          <CustomLabel
            className={'no-margin'}
            label={'Customer Overview'}
            weight={'bold'}
            size={'md'}
          />
          <CustomLabel
            className={'no-margin'}
            label={'Based on recent activities'}
            size={'xs'}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={'Total Customers'}
            count={<Box className={'custom-count'}>
              <ArrowUpIcon />
              <span className={'custom-count-pl'}>{this.state.totalActiveDisabled}</span>
            </Box>}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={<Badge 
              color="primary" 
              variant="dot" 
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <span>Active customers</span>
            </Badge>}
            count={this.state.totalActive}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={<Badge 
              color="error" 
              variant="dot" 
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <span>Disable customers</span>
            </Badge>}
            count={this.state.totalDisabled}
          />
        </Grid>
      </Box>
    </Grid>
  }
  /* END STATUS */


  /* TABLE */
  customTable = () => {
    return <Table 
      ref={this.refTable}
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      limit={this.state.limit}
      page={this.state.page}
      total={this.state.total}
      data={this.state.data}
      onPageChange={(page) => {
        this.setState({
          page: page,
        }, () => {
          this.callReadApi(this.refTableSearch.current.state.value);
        });
      }}
      Toolbar={this.customToolbar}
      columns={[
        { 
          title: "Customer Name", 
          field: "company_name",
          render: (row, type) => {
            if(type === 'row'){
              return <Box>
                <CustomButton
                  className={'underline'}
                  color={'primary'}
                  href={'/'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    this.setState({
                      openPreviewDialog: true,
                      openPreviewDialogItem: row.id,
                    });
                  }}
                >
                  {(row && row.company_name) ? row.company_name : ''}
                </CustomButton>
              </Box>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Code", 
          field: "company_code",
        },
        { 
          title: "Primary Address", 
          render: (row) => {
            let address = '';
            
            if(row.customer_addresses && row.customer_addresses.length > 0){
              let mainAddress = row.customer_addresses.filter(x => x.is_main === true);
              address = (mainAddress && mainAddress.length > 0) ? mainAddress[0].address_line1 : row.customer_addresses[0].address_line1;
            }
            
            return address;
          }
        },
        { 
          title: "Phone Number", 
          render: (row) => {
            let contactNo = '';
            
            if(row.customer_addresses && row.customer_addresses.length > 0){
              let mainAddress = row.customer_addresses.filter(x => x.is_main === true);
              contactNo = (mainAddress && mainAddress.length > 0) ? mainAddress[0].contact_no :  row.customer_addresses[0].contact_no;
            }
            
            return contactNo;
          }
        },
        { 
          title: "Status", 
          width: "100px", 
          render: (row) => {
            return <CustomSwitch 
              checked={row.is_active}
              onChange={(isChecked) => {
                row.is_active = isChecked;
                this.callUpdateIsActiveApi(row, () => {
                  this.callReadApi(this.refTableSearch.current.state.value);
                  apiUtil.toast('Successfully Changed', 'check_circle');
                });
              }}
            />;
          }
        },
        { 
          title: "ACTION", 
          width: '200px',
          align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container justify={'center'}>
                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/customers-form',
                          state: {
                            id: row.id
                          }
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Box>
                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        this.setState({
                          openDialog: true,
                          openDialogItem: row,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          }
        },
      ]}
    />
  }

  customToolbar = () => {
    return <Box>
      <Grid container>
        <Box clone order={{ xs: 3, md: 1}}>
          <Grid item xs={12} md={3}>
            <CustomInput
              ref={this.refTableSearch}
              placeholder={'Search Customer'}
              endAdornment={
                <IconButton
                  onClick={(e) => {
                    this.callReadApi(this.refTableSearch.current.state.value);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              }
              onPressEnter={(e) => {
                this.callReadApi(e.target.value);
              }}
            />
          </Grid>
        </Box>
        <Box clone pb={{ xs: 2, md: 0}} order={{ xs: 2, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true}>
            <GroupButton
              color={'secondary'}
              selected={this.state.activeInactiveCustomers}
              buttons={[ "Active Customers", "Disable Customers" ]}
              onClick={(selected, btn) => {
                this.setState({
                  activeInactiveCustomers: selected,
                }, () => {
                  this.callReadApi(this.refTableSearch.current.state.value);
                });
              }}
            />
          </Grid>
        </Box>
        {/* <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
          <Grid item xs={12} md={'auto'}>
              <CustomButton 
                color={'primary'}
                onClick={() => {
                  console.log("Click Export", this.refTable.current);
                }}
              >
                <ExportIcon />
                <Box display={'inline-block'} pl={1}>Export</Box>
              </CustomButton>
          </Grid>
        </Box> */}
      </Grid>
    </Box>
  }
  /* END TABLE */
  

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callDeleteApi(row, () => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          }, () => {
            this.callReadApi(this.refTableSearch.current.state.value);
            apiUtil.toast('Successfully Deleted', 'check_circle');
          });
        });
      }}
    >
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }

  previewDialog = () => {
    return <CustomDialog 
      open={this.state.openPreviewDialog}
      title={'Customer Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
    >
      <CustomerPreviewDialog
        id={this.state.openPreviewDialogItem}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
      />
    </CustomDialog>
  }

  importDialog = () => {
    return <CustomDialog 
      open={this.state.openImportDialog}
      isLoading={this.state.openImportDialogIsLoading}
      title={'Import Customers'}
      maxWidth={'xl'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openImportDialog: false,
          openImportDialogItem: null,
        });
      }}
    >
      <CustomerImportDialog
        file={this.state.openImportDialogItem}
        onClose={() => {
          this.setState({
            openImportDialog: false,
            openImportDialogItem: null,
          });
        }}
        onImport={(form) => {
          this.setState({
            openImportDialogIsLoading: true
          }, () => {
            this.callImportApi(form, (data) => {
              let all_customers = (form && form.length > 0) ? form.length : 0;
              let failed_customers = (data && data.failed_customers && data.failed_customers.length > 0) ? data.failed_customers.length : 0;
              let saved_customers = (data && data.saved_customers && data.saved_customers.length > 0) ? data.saved_customers.length : 0;
              
              if(failed_customers > 0){
                apiUtil.toast(failed_customers + ' of ' + all_customers + ' customers have not successfully imported', 'check_circle', 'error');
              }

              if(saved_customers > 0){
                apiUtil.toast(saved_customers + ' of ' + all_customers + ' customers successfully imported', 'check_circle', 'success');
              }

              this.callReadApi(this.refTableSearch.current.state.value);

              this.setState({
                openImportDialog: false,
                openImportDialogItem: null,
                openImportDialogIsLoading: false,
              });
            });
          });
        }}
      />
    </CustomDialog>
  }
  /* END DIALOG */


  /* STEPPER */
  setStepper = () => {
    return <>
      <CustomGuideStepper
        activeStep={0}
        steps={[
          {
            label: 'Create Customer',
          },
          {
            label: 'Create Vehicle',
          },
          {
            label: 'Create Operator',
          },
          {
            label: 'Create Job',
          },
        ]}
        onClick={(index) => {
          if(index === 0){
            // this.props.history.push('/customers');
          } else if(index === 1){
            this.props.history.push('/resources/vehicle');
          } else if(index === 2){
            this.props.history.push('/resources/transport-operator');
          } else if(index === 3){
            this.props.history.push('/job-list');
          }
        }}
      />
      <Box pb={2} textAlign={'center'}>This is the Customer list page. Click <b className={'txt-second'}>New Customer</b> to begin</Box>
      <Box textAlign={'center'}>
        <CustomButton 
          color={'primary'}
          href={'/'}
          onClick={(e) => {
              e.preventDefault();

              this.setState({
                openSkipDialog: true,
              });
          }}
        >
          Skip All
        </CustomButton>
      </Box>
    </>
  }
  /* END STEPPER */

  /* TOUR */
  setTour = () => {
    return <>
      <Tour
        steps={[
          {
            selector: '.new-customer-step',
            content: <Box>
              <h2>Create Customer</h2>
              <p>Click here to create a new customer</p>
            </Box>,
          },
        ]}
        isOpen={this.state.isTourOpen}
        rounded={10}
        accentColor={'#64CCC9'}
        badgeContent={'#64CCC9'}
        showNumber={false}
        showNavigation={false}
        disableInteraction={false}
        prevButton={<></>}
        nextButton={<></>}
        lastStepNextButton={<></>}
        onRequestClose={() => {
          this.setState({
            isTourOpen: false,
          });
        }} />
    </>
  }
  /* END TOUR */

  /* SKIP DIALOG */
  skipDialog = () => {
    const { accessToken, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = this.context;

    return <CustomDialog 
      open={this.state.openSkipDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        this.setState({
          openSkipDialog: false,
        });
      }}
      onOk={() => {
        apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
          this.setState({
            openSkipDialog: false,
          }, () => {
            setOnBoardingCustomer(null);
            setOnBoardingVehicle(null);
            setOnBoardingDriver(null);
            setOnBoardingJob(null);

            apiUtil.toastOnBoarding('Done', 'check_circle');
            this.props.history.push('/');
          });
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END SKIP DIALOG */


  render() {
    return <Box className="customers-page" >
      <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
      
      <Card>
        {this.customStatus()}
      </Card>

      <Card>
        {this.customTable()}
      </Card>
      
      {this.customDialog()}
      {this.previewDialog()}
      {this.importDialog()}
      {this.setTour()}
      {this.skipDialog()}
    </Box>;
  }
}

