import React, { Component } from 'react';
import uuid from 'react-uuid'
import moment from 'moment'

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from '../../../api/apiUtil.jsx';

import { 
  Box,
  Grid,
  IconButton,
  Hidden,
  Chip,
  Divider,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';

import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CustomFormDialog from '../../../components/Dialog/CustomFormDialog.jsx';
import CustomStepper from '../../../components/Stepper/CustomStepper.jsx';
import CustomCollapse from '../../../components/Collapse/CustomCollapse.jsx';
import {RoleAccessService} from '../../../data/role-access'; 
import StepsForm from './StepsForm.jsx';
import AttemptForm from './AttemptForm.jsx';


export class StepsList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let preview = ((props.preview === true) || (props.preview === false)) ? props.preview : false;
    let items = (props.items && props.items.length > 0) ? props.items : [];
    let grantedAccess = new RoleAccessService();

    this.state = {
      grantedAccess : grantedAccess,
      currentRole: window.localStorage.getItem('current_role') || null,
      openDialogEdit: false,
      itemDialogEdit: null,

      openDialogDelete: false,
      itemDialogDelete: null,

      openDialogAttempt: false,
      itemDialogAttempt: null,

      job_steps: items,
      preview: preview,
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.state.items) {
        let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
        this.setState({
            job_steps: items
        });
    }
  }


  /* FUNCTIONS */
  addNew = (form = null, callback = null) => {
    let job_steps = this.state.job_steps;

    if(form) {
        form['uuid'] = uuid();
        form['remove'] = false;
        job_steps.push(form);
    } else {
        job_steps.push({
            uuid: uuid(),
            job_step_name: '',
            job_step_status_id: '',
            job_step_status: null,
            location: '',
            is_signature_required: true,
            is_scan_required: false,
            job_step_pic: '',
            job_step_pic_contact: '',
            description: '',
            remove: false,
        });
    }
    
    this.setState({
        job_steps: job_steps
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onAddNew){
            this.props.onAddNew(this.state.job_steps);
        }
    });
  }

  edit = (item = null, form = null, callback = null) => {
    let job_steps = this.state.job_steps;

    let editIndex = job_steps.findIndex(e => e.uuid === item.uuid);
    if(editIndex !== -1){
        let oldForm = job_steps[editIndex];

        form['uuid'] = item.uuid;
        form['remove'] = false;
        form['job_step_id'] = item.job_step_id;
        job_steps[editIndex] = form;

        /*if (!this.props.isAddJob) {
            oldForm['remove'] = true;
            job_steps.push(oldForm);
        }*/
    }
    
    this.setState({
        job_steps: job_steps
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onEdit){
            this.props.onEdit(this.state.job_steps);
        }
    });
  }

  remove = (uuid = '', callback = null) => {
    let job_steps = this.state.job_steps;

    let index = job_steps.findIndex(e => e.uuid === uuid);
    if(index !== -1){
        if (this.props.isAddJob) {
            job_steps.splice(index, 1);
        } else {
            job_steps[index]['remove'] = true;
        }
    }

    this.setState({
        job_steps: job_steps
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onRemove){
            this.props.onRemove(this.state.job_steps);
        }
    });
  }

  renderSteps = () => {
    let steps = [];

    if(this.state.job_steps && this.state.job_steps.length > 0 && this.state.job_steps.filter(x => !x.remove).length > 0){
        steps = this.state.job_steps.filter(x => !x.remove).map((item, i) => {
            let index = (i + 1);
            item['order_sequence'] = index;
            return {
                icon: index,
                label: this.setItem(item),
            }
        })
    }

    steps.unshift({
        icon: null,
        label: this.setHeader(),
    });

    if(!this.state.preview) {
        this.props.isAddJob ? steps.push({
            icon: <AddIcon />,
            label: this.setFooter(),
        })
        :
        this.state.grantedAccess.isShouldReadyOnlyAccess(this.state.currentRole, "step_address") ? 
        steps.push({})
        :  steps.push({
            icon: <AddIcon />,
            label: this.setFooter(),
        });
    }
    
    return steps;
  }
  /* END FUNCTIONS */


  /* DIALOG */
  editDialog = () => {
      return <CustomFormDialog 
          open={this.state.openDialogEdit}
          title={((this.state.itemDialogEdit !== null) ? 'Edit Job Step' : 'Add Job Step')}
          maxWidth={'md'}
          onClose={() => {
            this.setState({
                openDialogEdit: false,
                itemDialogEdit: null,
            });
          }}
      >
          <StepsForm
            isAddJob={this.props.isAddJob}
            item={this.state.itemDialogEdit}
            onSave={(isEdit, form) => {
                let item = this.state.itemDialogEdit;

                if(isEdit) {
                    this.edit(item, form, () => {
                        this.setState({
                            openDialogEdit: false,
                            itemDialogEdit: null,
                        });
                    });
                } else {
                    this.addNew(form, () => {
                        this.setState({
                            openDialogEdit: false,
                            itemDialogEdit: null,
                        });
                    });
                }
                
            }}
            onClose={() => {
                this.setState({
                    openDialogEdit: false,
                    itemDialogEdit: null,
                });
            }}
          />
      </CustomFormDialog>
  }

  deleteDialog = () => {
      return <CustomDialog 
          open={this.state.openDialogDelete}
          title={'Delete'}
          onClose={() => {
            this.setState({
                openDialogDelete: false,
                itemDialogDelete: null,
            });
          }}
          onOk={() => {
              let item = this.state.itemDialogDelete;
              this.remove(item.uuid, () => {
                this.setState({
                    openDialogDelete: false,
                    itemDialogDelete: null,
                });
              });
          }}
      >
          <Box>Are you sure you want to delete?</Box>
      </CustomDialog>
  }

  attemptDialog = () => {
    return <CustomFormDialog 
        open={this.state.openDialogAttempt}
        title={'Attempt Details'}
        maxWidth={'md'}
        onClose={() => {
          this.setState({
              openDialogAttempt: false,
              itemDialogAttempt: null,
          });
        }}
    >
        <AttemptForm
          item={this.state.itemDialogAttempt}
          onClose={() => {
              this.setState({
                  openDialogAttempt: false,
                  itemDialogAttempt: null,
              });
          }}
        />
    </CustomFormDialog>
}
  /* END DIALOG */


  setHeader = () => {
    return <Hidden xsDown implementation="css">
        <Box className={'custom-grid-header'}>
            <Grid container alignItems={'center'}>
                <Box clone pl={{ xs: 0, md: 2}}>
                    <Grid item xs={12} md={3}>
                        <CustomLabel
                            label={'Step Name'}
                        />
                    </Grid>
                </Box>
                <Box clone pl={{ xs: 0, md: 2}}>
                    <Grid item xs={12} md={2}>
                        <CustomLabel
                            label={'Status'}
                        />
                    </Grid>
                </Box>
                <Box clone pl={{ xs: 0, md: 2}}>
                    <Grid item xs={12} md={3}>
                        <CustomLabel
                            label={'Address'}
                        />
                    </Grid>
                </Box>
                <Box clone  pl={{ xs: 0, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
                    <Grid item xs={12} md={true}></Grid>
                </Box>
            </Grid>
        </Box>
    </Hidden>
  }

  setFooter = () => {
    return <Box>
        <CustomButton 
            className={'plr-0 pt-3'}
            href={'/'}
            color={'secondary'}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.setState({
                    openDialogEdit: true,
                    itemDialogEdit: null,
                });
            }}
        >
            Add New item
        </CustomButton>
    </Box>
  }

  setStatus = (item) => {
    let status = ''
    if(!this.state.preview) {
        status = (item && item.job_step_status && item.job_step_status.label && item.job_step_status.label !== '') ? item.job_step_status.label : '';
    } else {
        status = (item && item.job_step_status && item.job_step_status.name && item.job_step_status.name !== '') ? item.job_step_status.name : '';
    }

    return (status !== '') && <Box><Chip className={'w-100 status-label ' + apiUtil.statusToClassName(status)} label={status} /></Box>
  }

  setItem = (item) => {
      return <Box>
          <CustomCollapse
            className={'job-steps-row'}
            open={(this.state.preview && item.order_attempts && item.order_attempts.length > 0)}
            item={(obj, isOpen) => {
                return <Box clone p={2}>
                    <Grid container alignItems={'center'}>
                        <Box clone>
                            <Grid item xs={12} md={3}>
                                <CustomLabel
                                    label={item.job_step_name}
                                />
                            </Grid>
                        </Box>
                        <Box clone pl={{ xs: 0, md: 1}}>
                            <Grid item xs={12} md={2}>
                                {this.setStatus(item)}
                            </Grid>
                        </Box>
                        <Box clone pl={{ xs: 0, md: 2}}>
                            <Grid item xs={12} md={3}>
                                <CustomLabel
                                    label={item.location}
                                />
                            </Grid>
                        </Box>
                        <Box clone pl={{ xs: 0, md: 2}} textAlign={'right'}>
                            <Grid item xs={12} md={!this.state.preview ? 2 : true}>
                                <CustomButton 
                                    className={'plr-0'}
                                    href={'/'}
                                    color={'primary'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        obj.toggle();
                                    }}
                                >
                                    {isOpen ? 'Collapse' : 'Expand'}
                                </CustomButton>
                            </Grid>
                        </Box>

                        {!this.state.preview && <Box clone>
                            <Grid item xs={12} md={2}>
                                <Grid container justify={'flex-end'}>
                                    <Box clone>
                                        <Grid item xs={'auto'}>
                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        openDialogEdit: true,
                                                        itemDialogEdit: item,
                                                    });
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={'auto'}>
                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        openDialogDelete: true,
                                                        itemDialogDelete: item,
                                                    });
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>}
                    </Grid>
                </Box>
            }}
            details={<Box clone p={2}>
                <Grid container alignItems={'center'}>
                    <Box clone>
                        <Grid item xs={12} md={5}>
                            <CustomLabel
                                label={'Person in Charge'}
                            />
                            <CustomLabel
                                label={item.job_step_pic}
                            />
                        </Grid>
                    </Box>
                    <Box clone>
                        <Grid item xs={12} md={4}>
                            <CustomLabel
                                label={'Phone Number'}
                            />
                            <CustomLabel
                                label={item.job_step_pic_contact}
                            />
                        </Grid>
                    </Box>
                    <Box clone>
                        <Grid item xs={12} md={3}>
                            <CustomLabel
                                label={'Remarks'}
                            />
                            <CustomLabel
                                label={item.description}
                            />
                        </Grid>
                    </Box>

                    {(this.state.preview && item.order_attempts && item.order_attempts.length > 0) && <Box clone>
                        <Grid item xs={12}>
                            {item.order_attempts.map((attempt, a) => {
                                return <Grid container alignItems={'center'} key={a}>
                                    <Box clone pt={2} pb={2}>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={'auto'}>
                                            {moment(attempt.submitted_time).local().format('MMMM D, YYYY HH:mm A')}
                                        </Grid>
                                    </Box>
                                    <Box clone pl={5}>
                                        <Grid item xs={true}>
                                            <CustomButton 
                                                color={'primary'}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    this.setState({
                                                        openDialogAttempt: true,
                                                        itemDialogAttempt: attempt,
                                                    });
                                                }}
                                            >
                                                Details
                                            </CustomButton>
                                        </Grid>
                                    </Box>
                                </Grid>
                            })}
                        </Grid>
                    </Box>}
                </Grid>
            </Box>}
        />
      </Box>
  }


  render() {
    return <Box pt={!this.state.preview ? 5 : 0} pb={5}>
        <CustomStepper
            color={'secondary'}
            steps={this.renderSteps()}
        />
        {this.attemptDialog()}
        {this.editDialog()}
        {this.deleteDialog()}
    </Box>
  }
}

export default StepsList;