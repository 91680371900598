import React from 'react';

import { 
    Box,
    Tabs,
    Tab,
    Typography
} from '@material-ui/core';


const TabPanel = (props) => {
    const { children, value, index, panelPadding, ...other } = props;
    return (
      <Typography
        className={"custom-panel"}
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={'custom-tabpanel-' + index}
        aria-labelledby={'custom-tab-' + index}
        {...other}
      >
        { 
          (panelPadding && panelPadding !== '')
          ?
          <Box style={{ padding: panelPadding }}>{children}</Box>
          :
          <Box className={'custom-div'}>{children}</Box>
        }
     </Typography>
    );
}


const CustomTabs = (props) => {
  const [selected, setSelected] = React.useState(props.selected ? props.selected : 0);
  const [items, setItems] = React.useState(props.items ? props.items : []);
  const [panelPadding, setPanelPadding] = React.useState(props.panelPadding ? props.panelPadding : null);
  const [minHeight] = React.useState(props.minHeight ? props.minHeight : 'auto');
  const [className] = React.useState(props.className ? props.className : '');


  React.useEffect(() => {
    setItems(props.items);
    setSelected(props.selected ? props.selected : 0);
    setPanelPadding(props.panelPadding ? props.panelPadding : null);
  }, [props.items, props.selected, props.panelPadding]);


  const handleChange = (event, newValue) => {
    setSelected(newValue);

    if(props.onChange){
      props.onChange(newValue);
    }
  };

  const itemList = () => {
    if(items && items.length > 0){
        return items.map((item, index) => {
            return <Tab 
                id={"custom-tab-" + index}
                aria-controls={"custom-tabpanel-" + index}
                key={index} 
                label={item.text} 
                icon={item.icon} 
                disabled={item.disabled} 
                onClick={() => {
                    if(props.onClick){
                        props.onClick(index, item);
                    }
                }}
            />;
        });
    }
  };

  const itemPanel = () => {
    if(items && items.length > 0){
        return items.map((item, index) => {
            return <TabPanel 
              key={index} 
              index={index}
              value={selected} 
              style={{ minHeight: minHeight }}
              panelPadding={panelPadding}
            >
                {item.children}
            </TabPanel>;
        });
    }
  };

  
  return (
    <Box className={"custom-tabs-component " + className}>
        <Tabs
            className="custom-tabs"
            value={selected}
            onChange={handleChange}
            textColor="primary"
            variant="fullWidth"
        >
            {itemList()}
        </Tabs>
        {itemPanel()}
    </Box>
  );
}


export default CustomTabs;