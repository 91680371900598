import React, { useCallback } from 'react';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';

import './assets/scss/main.scss';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthDataProvider from "./components/Auth/AuthDataProvider.jsx";
import RouteLayout from "./layout/Main/RouteLayout.jsx";
import CreatePageLayout from './layout/Main/CreatePageLayout.jsx';
import NotificationList from "./components/Header/NotificationList.jsx";
import PageNotFound from "./views/PageNotFound/PageNotFound.jsx";
import SignIn from "./views/Account/Signin.jsx";
import SignUp from "./views/Account/SignUp.jsx";

import Dashboard from "./views/Dashboard/Dashboard.jsx";

import { 
  JobList,
  JobVendorList,
  JobSchedule,
  JobScheduleList,
  JobsForm, 
  LiveTracking, 
  Vendor, 
  VendorForm,
  Customers, 
  CustomersForm, 
  ReportTransportOperation,
  ReportTransportOperationDetails,
  ReportCustomers,
  ReportCustomersDetails,
  Logistic,
  Vehicle,
  VehicleForm, 
  LogisticForm, 
  Transport,
  Settings,
  SettingProfile,
  TransportForm,
  RouteOptimize,
  Onboarding,
  OnboardingComplete,
  Zones,
} from "./views/index";


var isClicked = false;


function App() {
  
  const beforeinstallpromptHandler = useCallback(
    (deferredPrompt) => {
      if(!isClicked){
        toast(
          <button 
            onClick={() => {
              deferredPrompt.prompt();
    
              deferredPrompt.userChoice
                .then((choiceResult) => {
                  isClicked = true;
                  // if (choiceResult.outcome === 'accepted') {
                  //   console.log('User accepted the A2HS prompt');
                  // } else {
                  //   console.log('User dismissed the A2HS prompt');
                  // }
              });
            }}  
          >Install</button>
        );
      }
    }, []
  );
  window.addEventListener('beforeinstallprompt', beforeinstallpromptHandler);

  
  const getUserConfirmation = (dialogKey, callback) => {
    const dialogTrigger = window[Symbol.for(dialogKey)];
  
    if (dialogTrigger) {
      return dialogTrigger(callback);
    }
  
    callback(true);
  };

  return (
    <AuthDataProvider>
      <div className="App">
        <BrowserRouter basename="/" getUserConfirmation={getUserConfirmation}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>

            <RouteLayout path="/dashboard" component={Dashboard} menu_name={"dashboard"} />
            <RouteLayout path="/settings" component={Settings} layout={CreatePageLayout} menu_name={"settings"} />
            <RouteLayout path="/setting-profile" component={SettingProfile} layout={CreatePageLayout} menu_name={"user_profile"} />
            <RouteLayout path="/live-tracking" component={LiveTracking} menu_name = {"live_tracking"} />
            <RouteLayout path="/notification-list" component={NotificationList} layout={CreatePageLayout} menu_name={"user_profile"} />
            <RouteLayout path="/vendor" component={Vendor} />
            <RouteLayout path="/vendor-form" component={VendorForm} layout={CreatePageLayout} menu_name={"user_profile"} />
            <RouteLayout path="/job-list" component={JobList} menu_name = {"jobs"} />
            <RouteLayout path="/jobs/route-optimize" component={RouteOptimize} layout={CreatePageLayout} menu_name={"route_optimize"} />
            <RouteLayout path="/job-vendor-list" component={JobVendorList} menu_name={"vendor_list"} />
            <RouteLayout path="/job-schedule" component={JobSchedule} menu_name={"job_schedule"} />
            <RouteLayout path="/job-schedule-list" component={JobScheduleList} menu_name={"job_schedule_list"} />
            <RouteLayout path="/jobs-form" component={JobsForm} layout={CreatePageLayout} menu_name={"job_form"} />
            <RouteLayout path="/report-transport-operation" component={ReportTransportOperation} menu_name={"report_transport_operation"} />
            <RouteLayout path="/report-transport-operation-details" component={ReportTransportOperationDetails} layout={CreatePageLayout} menu_name={"report_transport_operation_detail"} />
            <RouteLayout path="/report-customers" component={ReportCustomers} menu_name={"report_customers"} />
            <RouteLayout path="/report-customers-details" component={ReportCustomersDetails} layout={CreatePageLayout} menu_name={"report_customer_detail"}/>
            <RouteLayout path="/customers" component={Customers} menu_name={"customers"}/>
            <RouteLayout path="/customers-form" component={CustomersForm} layout={CreatePageLayout} menu_name={"customer_form"}/>
            <RouteLayout path="/resources/logistic-assistance" component={Logistic} menu_name={"resources_logistic_assistance"}/>
            <RouteLayout path="/resources/logistic-assistance-form" component={LogisticForm} layout={CreatePageLayout} menu_name={"resources_logistic_assistance_form"}/>
            <RouteLayout path="/resources/transport-operator-form" component={TransportForm} layout={CreatePageLayout} menu_name={"transport_operator_form"}/>
            <RouteLayout path="/resources/transport-operator" component={Transport} layout={CreatePageLayout} menu_name={"transport_operator"}/>
            <RouteLayout path="/resources/vehicle" component={Vehicle} menu_name={"resources_vehicle"} />
            <RouteLayout path="/resources/vehicle-form" component={VehicleForm} layout={CreatePageLayout} menu_name={"resources_vehicle_form"}/>
            <RouteLayout path="/zones" component={Zones} menu_name = {"zones"} />              
            <Route path="/onboarding" component={Onboarding} menu_name={"onboarding"}/>
            <Route path="/onboarding-complete" component={OnboardingComplete} menu_name={"onboarding_complete"}/>
            <Route path="/sign-in" component={SignIn} />
            <Route path="/sign-up" component={SignUp} />
            <Route component={PageNotFound} />
          </Switch>
        </BrowserRouter>
        <ToastContainer className="custom-toast" autoClose={false} />
      </div>
    </AuthDataProvider>
  );
}


export default App;
