import React , { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import JobPreviewDialog from '../../views/Jobs/Partial/JobPreviewDialog'
import CustomDialog from '../Dialog/CustomDialog.jsx';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    className="customized-menu-root"
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  
    {...props}
    
  />
 
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
class NotificationDropdown extends Component {


  static contextType = AuthContext;
 
  constructor(props){
    super(props);
    
    this.state = {
      anchorEl: null,
      data: null,
      isLoading: true,
      openDialog: false,
      openDialogItem: null,

      openPreviewDialog: false,
      openPreviewDialogItem: null,
      openPreviewDialogStep: 0,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (event) => {
    event.preventDefault();
    this.callNotificationApi();
    this.setState({
      anchorEl: event.currentTarget
    });
  };


  previewDialog = () => {
    return <CustomDialog 
      open={this.state.openPreviewDialog}
      title={'Job Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
      />
    </CustomDialog>
  }
  /* END DIALOG */

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };
 

  callNotificationApi = (search = '') => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
    
      let param = {
        take: 20,
        page: 1,
      };

      ldsApi.getNotificationHistory('notifications/simple', param, accessToken)
        .then((result) => {
          console.log("result");
          console.log(result);
          console.log("result");
          apiUtil.parseResult(result, (data) => {
   
            let mydata = (data.result && data.result.length > 0) ? data.result : [];
            let filterData = mydata.map(data => {
              let timeDiff =  moment().diff(moment(data.created_at), "hours") - 8;
              timeDiff = timeDiff < 1 ? timeDiff + " minutes ago": timeDiff > 24 ? data.created_at: timeDiff + "hours ago";
              let name = data.admin ? data.admin.first_name : '';
              if(data.current_status.status === "Not Assigned" && data.attempts != null && data.attempts.length > 0) {
                return {id: data.order_id, profile_image_url: data.admin ? data.admin.profile_image_url : null, message: data.order_reference_no + " has been " + data.action + " by " + name, time: timeDiff};
              } else if(data.action === "Created") {
                return {id: data.order_id, profile_image_url: data.admin ? data.admin.profile_image_url  : null, message: data.order_reference_no + " was " + data.action + " by " + name, time: timeDiff};
              } else if(data.action === "Updated") {
                return {id: data.order_id, profile_image_url: data.admin ? data.admin.profile_image_url  : null, message: data.order_reference_no + " is " + data.action + " by " + name, time: timeDiff};
              } else if (data.action === "Deleted Order") {
                return null;
              }else {
                return {id: data.order_id, profile_image_url: data.admin ? data.admin.profile_image_url : null, message: data.order_reference_no + " has been " + data.action + "to " + data.current_status.status + " by " + name, time: timeDiff};
              }     
            }).filter(Boolean);
            console.log(filterData);
            this.setState({
              data: filterData,
              total: data.total,
              isLoading: false,
            });

          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }
  render() {
    const { history } = this.props
  return (
    <div>

      <Badge color="secondary" onClick={this.handleClick} badgeContent={0}>        
      <MailIcon />
      </Badge>
      <div className= "noti-overlay" >
        {this.state.isLoading === false ? (
        <StyledMenu
        id="customized-menu"
        anchorEl={this.state.anchorEl}
        keepMounted
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleClose}
        >
          <div  className="header">
          Notifications
        </div>
          {this.state.data &&
              this.state.data.map((data, index) => (
              
              <StyledMenuItem  className="customized-menu-item" key={index}  onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                this.setState({
                  openPreviewDialog: true,
                  openPreviewDialogItem: data.id,
                });
              }}>
                <img src={data.profile_image_url} alt=""/>
                <div className="details">
                        <span className="description">{data.message}</span>
                        <span className="time">{data.time}</span>
                </div> 
              </StyledMenuItem>
                 
          ))}
      
          <div  className="noti-footer"  onClick={() => {
                  history.push('/notification-list');
                }}>
          {/* <MenuItem  */}
               
            {/* >    */}
                See all notifications
            {/* </MenuItem> */}
          </div>
     </StyledMenu>

        ) : (

      <StyledMenu
        id="customized-menu"
        anchorEl={this.state.anchorEl}
        keepMounted
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleClose}
      >
        <div  className="header">
          Notifications
        </div>
        <StyledMenuItem  className="customized-menu-item" >
            Loading...
        </StyledMenuItem>
      </StyledMenu>
        )
        }
     </div>
     {this.previewDialog()}
    </div>
  );
}
}

export default withRouter(NotificationDropdown);
