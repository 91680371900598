import React from "react";
import { ResponsiveContainer, PieChart, Tooltip, Pie, Cell } from "recharts";

export const TooltipContainerStyles = {
  border: 0,
  borderRadius: "8px",
  fontSize: 14,
  boxShadow: "2px 2px 5px 3px rgba(0,0,0,0.15)"
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, outerRadius, fill, payload, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius ) * cos;
  const sy = cy + (outerRadius ) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (<g>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" fontSize={20} fontWeight={"bold"} >{value}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} fontSize={12} textAnchor={textAnchor} >
        {payload.name}
      </text>
    </g>
  );
};

const JobsPieChart = React.memo(({total, completed}) => {

  const stats = [
    {
      "name": "Others",
      "value": total - completed,
      "color": "#E89CAE"
    },
    {
      "name": "Completed",
      "value": completed,
      "color": "#2EC1AC"
    }
  ];

  return (<ResponsiveContainer width="100%" height={150}>
    <PieChart fontSize={14}>
          <Tooltip
            cursor={false}
            contentStyle={TooltipContainerStyles}
            formatter={(value, name) => [`${value}`, `Jobs - ${name}`]}
          />

          <Pie
            dataKey="value"
            data={stats}
            outerRadius={60}
            innerRadius={35}
            name="value"
            nameKey="name"
            unit=""
            label={renderCustomizedLabel}
          >
            {stats.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
  );
});

export default JobsPieChart;
