import React, { Component } from 'react';
import moment from "moment";

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';

import { 
  Box,
  Grid,
  Hidden,
  Drawer,
  IconButton,
  Divider,
  CircularProgress,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';
import defaultImage from '../../assets/img/icons/live_tracking_user.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomList from '../../components/List/CustomList.jsx';
import CustomListItem from '../../components/List/CustomListItem.jsx';
import CustomItem from '../../components/List/CustomItem.jsx';
import CustomAvatarBadge from '../../components/Avatar/CustomAvatarBadge.jsx';
// import CustomSelectOption from '../../components/CustomFields/CustomSelectOption.jsx';
// import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import CustomLiveTrackingMap from '../../components/GoogleMap/CustomLiveTrackingMap.jsx';

import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import JobPreviewDialog from '../Jobs/Partial/JobPreviewDialog.jsx';
import JobStepsList from './Partial/JobStepsList';

import socketIOClient from "socket.io-client";
import { Observable } from 'rxjs/Observable';


const { 
  REACT_APP_LIVE_TRACKING_SOCKET_URL,
} = process.env;


export class LiveTracking extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    
    this.connection = null;
    this.socket = null;

    this.state = {
      minWidth: 250,

      openMenu: false,

      center: null,
      zoom: 12,
      workersFilter: [],
      workers: [],

      openMenuOnline: false,
      openMenuOffline: false,
      openMenuHistory: false,

      clickedWorkerItem: null,
      clickedHistoeryItem: null,

      historyData: [],
      isLoadingHistory: false,

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      isLoading: false,
      search: '',
      rangeDate: {
        startDate: moment(),
        endDate: moment(),
      },
      selectedStatusId: 0,
      
      orderStatusesIsLoading: false,
      orderStatuses: [],

      contentHeight: 396,
    }
  }


  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    this.socketData();

    this.callStatusApi();
    this.callWorkersReadApi();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);

    if(this.connection)
      this.connection.unsubscribe();
  }


  handleResize = () => {
    if(this.state.clickedHistoeryItem !== null){
      let header = document.body.getElementsByTagName('header')[0];
      let headerPosition = header.getBoundingClientRect();
      let headerHeight = headerPosition.height;
  
      let stickyPageHeader = document.body.getElementsByClassName('custom-sticky-page-header')[0];
      let stickyPageHeaderPosition = stickyPageHeader.getBoundingClientRect();
      let stickyPageHeaderHeight = stickyPageHeaderPosition.height;
  
      let stickyListHeaderHeight = 0;
      try {
        let stickyListHeader = document.body.getElementsByClassName('MuiListSubheader-sticky')[0];
        let stickyListHeaderPosition = stickyListHeader.getBoundingClientRect();
        stickyListHeaderHeight = stickyListHeaderPosition.height;
      } catch(e){}
      
      let histoeryItemHeaderHeight = 0;
      try {
        let histoeryItemHeader = document.body.getElementsByClassName('histoery-head-item')[0];
        let histoeryItemHeaderPosition = histoeryItemHeader.getBoundingClientRect();
        histoeryItemHeaderHeight = histoeryItemHeaderPosition.height;
      } catch(e){}
  
      let contentHeight = headerHeight + stickyPageHeaderHeight + stickyListHeaderHeight + histoeryItemHeaderHeight;
  
      this.setState({
        contentHeight: contentHeight
      });
    }
  }


  /* SOCKET */
  getMessages() { 
    const { user } = this.context;

    let application_company_id = apiUtil.getAppCompanyId(user);
    
    let observable = new Observable(observer => {
      this.socket = socketIOClient(REACT_APP_LIVE_TRACKING_SOCKET_URL);
      
      this.socket.on('connect', () => {
        console.log("SOCKET", 'connect', this.socket.connected);
      });
      
      this.socket.emit("join", "live_location");
      
      this.socket.on(application_company_id.toString(), (data) => {
        observer.next(data);    
      });

      return () => {
        this.socket.disconnect();
      };  
    }) 
    return observable;
  }

  socketData(){
    this.connection = this.getMessages().subscribe(message => {
      console.log("connection message", message);
      
      if((this.state.workers && this.state.workers.length > 0) && message.worker_id && message.latitude && message.longitude){
        let workers = this.state.workers;
        let workerIndex = workers.findIndex(x => x.id === message.worker_id);
        if(workerIndex && workerIndex !== -1){
          workers[workerIndex].latitude = message.latitude;
          workers[workerIndex].longitude = message.longitude;
        }
        
        this.setState({
          workers: workers,
          workersFilter: workers,
        });
      }
    });
  }
  /* END SOCKET */


  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      orderStatusesIsLoading: true,
      orderStatuses: [],
    }, () => {
      ldsApi.read('order_statuses', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let arr = [];
            if(data && data.length > 0){
              arr = data.map((item, i) => {
                let value = item.id;
                let label = item.status;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              arr.unshift({
                value: 0,
                label: 'All',
                item: null
              });

              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              orderStatusesIsLoading: false,
              orderStatuses: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }

  callWorkersReadApi = (callback = null) => {
    const { accessToken } = this.context;

    let param = {
      is_active: true,
      // require_worker_only: true,
      // is_truck_driver: true,
    };

    this.setState({
      isLoading: true,
      clickedWorkerItem: null,
      clickedHistoeryItem: null,
    }, () => {
      ldsApi.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data && data.length > 0) ? data : [];
          
          if(result && result.length > 0){
            result = result.map((item, i) => {
              item.isOpen = false
              return item;
            });

            this.setState({
              isLoading: false,
              workers: result,
              workersFilter: result,
            }, () => {
              if(callback){
                callback(result);
              }
            });
          } else {
            this.setState({
              isLoading: false,
              workers: [],
              workersFilter: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          }
        }, (error, type) => {
          this.setState({
            isLoading: false,
            workers: [],
            workersFilter: [],
          }, () => {
            if(callback){
              callback([]);
            }
          });
        });
      });
    });
  }

  callHistoryApi = (worker_ids = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingHistory: true,
      openMenuHistory: true,
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      
      let param = {
        worker_ids: worker_ids,
        start_date: startDate,
        end_date: endDate,
      };

      if(this.state.selectedStatusId && this.state.selectedStatusId > 0){
        param['order_status'] = this.state.selectedStatusId;
      }

      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              historyData: (data.result && data.result.length > 0) ? data.result : [],
              isLoadingHistory: false,
              openMenuHistory: true,
            }, () => {
              if(callback){
                callback(data.result);
              }
            });
          }, (error, type) => {
            this.setState({
              historyData: [],
              isLoadingHistory: false,
              openMenuHistory: true,
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pt={2}>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <CustomInput
              placeholder={'Search for driver'}
              value={this.state.search}
              endAdornment={
                this.state.search !== ''
                ?
                <IconButton
                  style={{
                    padding: '5px'
                  }}
                  onClick={(e) => {
                    let workersFilter = this.state.workers;

                    this.setState({
                      search: '',
                      zoom: 12,
                      center: null,
                      workersFilter: workersFilter,
                    });
                  }}
                >
                  <Icon component={'i'}>close</Icon>
                </IconButton>
                :
                null
              }
              onChange={(e) => {
                let workersFilter = this.state.workers;
                let center = { lat: 1.352083, lng: 103.819836 };
                let zoom = 12;

                if(e.target.value && e.target.value !== ''){
                  workersFilter = workersFilter.filter(data => {
                    let first_name = (data && data.first_name && data.first_name !== '') ? data.first_name : '';
                    let last_name = (data && data.last_name && data.last_name !== '') ? data.last_name : '';
                    let name = ((first_name !== '') ? first_name + ' ' : '') + ((last_name !== '') ? last_name : '');
        
                    if(e.target.value === null || e.target.value === '') {
                      return data
                    } else if(name.toLowerCase().includes(e.target.value.toLowerCase())) {
                      return data
                    }
                  });

                  if(workersFilter && workersFilter.length > 0){
                    if(workersFilter[0] && workersFilter[0].latitude && workersFilter[0].longitude){
                      center = { lat: workersFilter[0].latitude, lng: workersFilter[0].longitude };
                      zoom = 15;
                    }
                  }
                }

                this.setState({
                  search: e.target.value,
                  workersFilter: workersFilter,
                  zoom: zoom,
                  center: center,
                });
              }}
            />
          </Grid>
        </Box>
        {/* <Box clone pr={1}>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <CustomSelectOption
              className={'head-select'}
              label={' '}
              placeholder={'All'}
              value={this.state.selectedStatusId}
              items={this.state.orderStatuses}
              onChange={(value, item) => {
                this.setState({
                  selectedStatusId: value
                }, () => {
                  this.callWorkersReadApi();
                });
              }}
            />
          </Grid>
        </Box>
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, sm: 0 }}>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <CustomDateRangePicker
              range={this.state.rangeDate}
              onChange={(range) => {
                this.setState({
                  rangeDate: range,
                }, () => {
                  this.callWorkersReadApi();
                });
              }}
            />
          </Grid>
        </Box> */}
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.toggleMenu();
  }
  toggleMenu = () => {
    this.setState({ 
      openMenu: !this.state.openMenu,
      clickedWorkerItem: null,
      clickedHistoeryItem: null,
    });
  }

  setDrawer = () => {
    return <Drawer className={'right-drawer'} anchor={'right'} open={this.state.openMenu} onClose={this.toggleDrawer()}>
      <Box minWidth={this.state.minWidth}>
        {
          (this.state.clickedWorkerItem !== null)
          ?
          this.leftMenuInfo(true)
          :
          this.leftMenu(false)
        }
        {this.setDrawerDetails(true)}
      </Box>
    </Drawer>
  }

  
  /* LEFT MENU */
  leftMenu = (isFromDrawer = false) => {
    return <Box width={(isFromDrawer) ? this.state.minWidth : '100%'}>
      <CustomList
        header={<Box pt={1} pb={1}>
          <CustomLabel
            label={'Transport Drivers'}
            size={'lg'}
          />
        </Box>}
      >

        {
          (!this.state.isLoading)
          ?
            <React.Fragment>
              <CustomListItem
                text={<Box>
                  <Icon component={'i'} style={{ fontSize: '12px', position: 'relative', top: '1px', paddingRight: '20px', color: '#4CE095' }}>lens</Icon>
                  <span>Online ({this.state.workersFilter.filter(x => x.is_online === true).length})</span>
                </Box>}
                open={this.state.openMenuOnline}
                onClick={() => {
                  this.setState({
                    openMenuOnline: !this.state.openMenuOnline,
                    openMenuOffline: false,
                  });
                }}
              >
                {this.state.workersFilter.filter(x => x.is_online === true).map((item, i) => {
                  let name = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');
                  let image = (item.profile_image_url_small && item.profile_image_url_small !== '') ? item.profile_image_url_small : defaultImage;
                  
                  return <CustomListItem
                    key={i}
                    text={name}
                    avatar={{
                      text: name,
                      url: image,
                    }}
                    data={item}
                    onClick={(onClickItem) => {
                      let itemData = (onClickItem && onClickItem.state && onClickItem.state.data) ? onClickItem.state.data : null;
                      if(itemData && itemData.id && itemData.id > 0){
                        
                        if(itemData.latitude && itemData.longitude){
                          this.setState({
                            clickedWorkerItem: item,
                            clickedHistoeryItem: null,
                            center: { lat: itemData.latitude, lng: itemData.longitude },
                            zoom: 18,
                          }, () => {
                            this.callHistoryApi(this.state.clickedWorkerItem.id.toString());
                          });
                        }
                      }
                    }}
                  />
                })}
                
              </CustomListItem>

              <CustomListItem
                text={<Box>
                  <Icon component={'i'} style={{ fontSize: '12px', position: 'relative', top: '1px', paddingRight: '20px', color: '#EC4545' }}>lens</Icon>
                  <span>Offline ({this.state.workersFilter.filter(x => x.is_online === false).length})</span>
                </Box>}
                open={this.state.openMenuOffline}
                onClick={() => {
                  this.setState({
                    openMenuOffline: !this.state.openMenuOffline,
                    openMenuOnline: false,
                  });
                }}
              >
                {this.state.workersFilter.filter(x => x.is_online === false).map((item, i) => {
                  let name = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');
                  let image = (item.profile_image_url_small && item.profile_image_url_small !== '') ? item.profile_image_url_small : defaultImage;
                  
                  return <CustomListItem
                    key={i}
                    text={name}
                    avatar={{
                      text: name,
                      url: image,
                    }}
                    data={item}
                    onClick={(onClickItem) => {
                      let itemData = (onClickItem && onClickItem.state && onClickItem.state.data) ? onClickItem.state.data : null;
                      if(itemData && itemData.id && itemData.id > 0){

                        if(itemData.latitude && itemData.longitude){
                          this.setState({
                            clickedWorkerItem: item,
                            clickedHistoeryItem: null,
                            center: { lat: itemData.latitude, lng: itemData.longitude },
                            zoom: 18,
                          }, () => {
                            this.callHistoryApi(this.state.clickedWorkerItem.id.toString());
                          });
                        }
                      }
                    }}
                  />
                })}
              </CustomListItem>
            </React.Fragment>
          :
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'10vh'}>
              <CircularProgress size={35} />
            </Box>
        }

      </CustomList>
    </Box>
  }
  /* END LEFT MENU */

  
  /* LEFT MENU INFO */
  leftMenuInfo = (isFromDrawer = false) => {
    let item = this.state.clickedWorkerItem;
    let name = (item && (item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');
    let image = (item && item.profile_image_url_small && item.profile_image_url_small !== '') ? item.profile_image_url_small : defaultImage;
    let isOnline = (item && ((item.is_online === false) || (item.is_online === true))) ? item.is_online : false;
    let contactNo = (item && item.contact_no && item.contact_no !== '') ? item.contact_no : '';
    let locationUpdatedAt = (item && item.location_updated_at && item.location_updated_at !== '') ? moment(item.location_updated_at).fromNow(true) : '';

    let style = {
      overflowY: 'auto',
      height: 'calc(100vh - ' + this.state.contentHeight + 'px)',
    };
    if(isFromDrawer){
      style = {
        overflowY: 'auto',
        height: 'auto',
      };
    }

    return <Box width={(isFromDrawer) ? this.state.minWidth : '100%'}>
      <CustomList
        header={<Box pt={1} pb={1}>
          <Grid container>
            <Box clone>
              <Grid item xs={12}>
                <CustomButton
                  className={'no-padding'}
                  color={'secondary'}
                  href={'/'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    this.setState({
                      clickedWorkerItem: null,
                      clickedHistoeryItem: null,
                    })
                  }}
                >
                  <Icon component={'i'} style={{ position: 'relative', top: '1px', left: '-5px' }}>keyboard_arrow_left</Icon>
                  <span>Go Back</span>
                </CustomButton>
              </Grid>
            </Box>
            <Box clone textAlign={'center'}>
              <Grid item xs={12}>
                  <CustomAvatarBadge
                    name={name}
                    src={image}
                    color={(isOnline) ? 'primary' : 'secondary'}
                  />
              </Grid>
            </Box>
            <Box clone textAlign={'center'}>
              <Grid item xs={12}>
                <CustomLabel
                  className={'lh-xs'}
                  label={name}
                  size={'sm'}
                  weight={'bold'}
                />
              </Grid>
            </Box>
            <Box clone textAlign={'center'}>
              <Grid item xs={12}>
                <CustomLabel
                  className={'lh-xs'}
                  label={contactNo}
                  size={'xs'}
                />
              </Grid>
            </Box>
            <Box clone textAlign={'center'}>
              <Grid item xs={12}>
                <CustomLabel
                  className={'lh-xs'}
                  label={<span><b>Last seen</b> {locationUpdatedAt}</span>}
                  size={'xs'}
                />
              </Grid>
            </Box>
          </Grid>
        </Box>}
      >
        {
        (!this.state.isLoadingHistory)
        ?
          <CustomListItem
            className={'histoery-head-item'}
            text={<span>Job history ({this.state.historyData.length})</span>}
            open={this.state.openMenuHistory}
            onClick={() => {
              this.setState({
                openMenuHistory: !this.state.openMenuHistory,
              });
            }}
          >
            <Box
              style={style}
            >
              
              {this.state.historyData.map((item, i) => {
                let statusName = ((item.order_status && item.order_status.status && item.order_status.status !== '') ? item.order_status.status : '');

                return <CustomItem
                  key={i}
                  data={item}
                  active={(this.state.clickedHistoeryItem && this.state.clickedHistoeryItem.id && (this.state.clickedHistoeryItem.id === item.id)) ? true : false}
                  onClick={(onClickItem) => {
                    let itemData = (onClickItem && onClickItem.state && onClickItem.state.data) ? onClickItem.state.data : null;
                    if(itemData && itemData.id && itemData.id > 0){
                      
                      this.setState({
                        clickedHistoeryItem: item,
                      }, () => {
                        this.handleResize();
                      });
                    }
                  }}
                >
                  <Box>
                    <Grid container alignItems={'center'}>
                      <Box clone>
                        <Grid item xs={12}>
                          <CustomLabel
                            className={'lh-sm'}
                            label={item.company_name}
                            size={'sm'}
                          />
                        </Grid>
                      </Box>
                      <Box clone pb={1}>
                        <Grid item xs={12}>
                          <CustomButton
                            className={'underline no-padding'}
                            color={'primary'}
                            href={'/'}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();

                              this.setState({
                                openPreviewDialog: true,
                                openPreviewDialogItem: item.id,
                              });
                            }}
                          >{item.order_number}</CustomButton>
                        </Grid>
                      </Box>
                      <Box clone>
                        <Grid item xs={6}>
                          <CustomLabel
                            className={'lh-xs'}
                            label={<Box>
                              <span className={'font-size-12'}>Status: </span>
                              <span className={'font-size-12 status ' + ((statusName && statusName !== '') ? (statusName.replace(' ', '-').trim().toLowerCase()) : '')}>{statusName}</span>
                            </Box>}
                            size={'xs'}
                          />
                        </Grid>
                      </Box>
                      <Box clone textAlign={'right'}>
                        <Grid item xs={6}>
                          <CustomLabel
                            label={(item.drop_off_date && item.drop_off_date !== '') ? moment(item.drop_off_date).format('DD-MM-YYYY') : ''}
                            size={'xs'}
                          />
                        </Grid>
                      </Box>
                    </Grid>
                    <Divider />
                  </Box>
                </CustomItem>
              })}

            </Box>
          </CustomListItem>
        :
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'35vh'}>
            <CircularProgress size={35} />
          </Box>
        }
      </CustomList>
    </Box>
  }
  /* END LEFT MENU INFO */


  /* LEFT MENU INFO SUMMARY */
  setDrawerDetails = (isFromDrawer = false) => {
    let item = this.state.clickedHistoeryItem;

    let style = {
      position: 'absolute',
      left: '0px',
      width: this.state.minWidth + 150 + 'px',
      height: '100%',
      zIndex: '1',
      backgroundColor: 'white',
      borderLeft: '1px solid #EAEDF2',
      overflow: 'auto',
    };
    
    if(isFromDrawer){
      style = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        width: this.state.minWidth + 50 + 'px',
        height: '100%',
        zIndex: '2',
        backgroundColor: 'white',
        borderRight: '1px solid #EAEDF2',
        overflow: 'auto',
      }
    }

    if(item !== null){
      return <Box style={style} p={2}>
        <Grid container>
          <Box clone>
            <Grid item xs={12}>
              <Grid container>
                <Box clone>
                  <Grid item xs={6}>
                    <CustomLabel
                      label={'Job Summary'}
                      size={'sm'}
                      weight={'bold'}
                    />
                  </Grid>
                </Box>
                <Box clone textAlign={'right'}>
                  <Grid item xs={6}>
                    <CustomButton
                      className={'no-padding'}
                      color={'secondary'}
                      href={'/'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        this.setState({
                          clickedHistoeryItem: null,
                        })
                      }}
                    >
                      <Icon component={'i'} style={{ position: 'relative', top: '0px', left: '-5px', fontSize: '20px' }}>cancel</Icon>
                      <span>Close</span>
                    </CustomButton>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box clone pb={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Box>
          
          <Box clone>
            <Grid item xs={12}>
              <Box pb={1}>
                <CustomLabel
                  className={'lh-xs muted'}
                  label={'Company name'}
                  size={'xs'}
                />
                <CustomLabel
                  className={'lh-sm word-break'}
                  label={item.company_name}
                  size={'sm'}
                  weight={'bold'}
                />
              </Box>
            </Grid>
          </Box>
          <Box clone>
            <Grid item xs={12}>
              <Box pb={1}>
                <CustomLabel
                  className={'lh-xs muted'}
                  label={'Email address'}
                  size={'xs'}
                />
                <CustomLabel
                  className={'lh-sm word-break'}
                  label={item.notification_email}
                  size={'sm'}
                  weight={'bold'}
                />
              </Box>
            </Grid>
          </Box>
          <Box clone>
            <Grid item xs={12}>
              <Box pb={1}>
                <CustomLabel
                  className={'lh-xs muted'}
                  label={'Phone number'}
                  size={'xs'}
                />
                <CustomLabel
                  className={'lh-sm word-break'}
                  label={item.drop_off_contact_no}
                  size={'sm'}
                  weight={'bold'}
                />
              </Box>
            </Grid>
          </Box>
          <Box clone>
            <Grid item xs={12}>
              <Box pb={1}>
                <CustomLabel
                  className={'lh-xs muted'}
                  label={'Address'}
                  size={'xs'}
                />
                <CustomLabel
                  className={'lh-sm word-break'}
                  label={item.drop_off_address}
                  size={'sm'}
                  weight={'bold'}
                />
              </Box>
            </Grid>
          </Box>

          <Box clone pb={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Box>

          <Box clone>
            <Grid item xs={12}>
              <Box pb={1}>
                <CustomLabel
                  className={'lh-sm'}
                  label={'Description'}
                  size={'sm'}
                  weight={'bold'}
                />
                <CustomLabel
                  className={'lh-xs muted word-break'}
                  label={item.drop_off_description}
                  size={'xs'}
                />
              </Box>
            </Grid>
          </Box>

          <Box clone pb={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Box>

          <Box clone>
            <Grid item xs={12}>
              <JobStepsList 
                items={item.job_steps}
              />
            </Grid>
          </Box>
        </Grid>
      </Box>
    } else {
      return null;
    }
  }
  /* END LEFT MENU INFO SUMMARY */


  /* MAP */
  setMap = () => {
    return <Box
      width={'100%'}
      height={'100%'}
    >
      <CustomLiveTrackingMap
        center={this.state.center}
        zoom={this.state.zoom}
        markers={this.state.workersFilter}
        onClick={(item, data, i) => {
          // let markers = this.state.workers;
          // if(markers && markers.length > 0){
          //   markers = markers.map((pin, p) => {
          //     pin.isOpen = (p === i) ? true : false;
          //     return pin;
          //   });
          // }

          this.setState({
            clickedWorkerItem: data,
            clickedHistoeryItem: null,
            // workers: markers,
          }, () => {
            this.callHistoryApi(this.state.clickedWorkerItem.id.toString());
          });
        }}
      />
    </Box>
  }
  /* END MAP */


  /* DIALOG */
  previewDialog = () => {
    return <CustomDialog 
      open={this.state.openPreviewDialog}
      title={'Job Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
      />
    </CustomDialog>
  }
  /* END DIALOG */


  render() {
    return <Box className="live-tracking-page" style={{ height: 'calc(100% - 155px)' }}>
      <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
      
      <Box height={'100%'}>
        <Grid container style={{ height: '100%' }}>
          <Hidden smDown>
            <Box clone style={{ overflow: ((this.state.clickedWorkerItem !== null || this.state.clickedHistoeryItem !== null) ? 'hidden' : 'auto'), height: 'calc(100% - 0px)' }}>
              <Grid item xs={12} md={3} lg={3} xl={2}>
                {
                  (this.state.clickedWorkerItem !== null)
                  ?
                  this.leftMenuInfo(false)
                  :
                  this.leftMenu(false)
                }
              </Grid>
            </Box>
          </Hidden>
          <Box clone position={'relative'}>
            <Grid item xs={12} md={9} lg={9} xl={10}>
              <Hidden smDown>
                {this.setDrawerDetails(false)}
              </Hidden>

              <Hidden mdUp>
                <Box
                  style={{
                    position: 'absolute',
                    right: '10px',
                    zIndex: '1',
                  }}
                >
                  <IconButton onClick={() => {
                    this.toggleMenu();
                  }}>
                      <Icon component={'i'}>menu_open</Icon>
                  </IconButton>
                </Box>
              </Hidden>

              {this.setMap()}
            </Grid>
          </Box>
        </Grid>
      </Box>
      {this.setDrawer()}
      {this.previewDialog()}
    </Box>;
  }
}