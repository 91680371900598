import React, { Component } from 'react';

import { 
    Box,
} from '@material-ui/core';


class CircleIcon extends Component {

    constructor(props){
        super(props);
    
        let className = (props.className) ? props.className : '';

        this.state = {
            className: className,
        }
    }


    componentWillReceiveProps(nextProps) {
		if (nextProps.className !== this.state.className) {
			this.setState({
				className: nextProps.className
			});
		}
	}


    render() {
        return <Box className={'custom-circle-icon-component ' + this.state.className}>
            {
            this.props.children
            ?
            this.props.children
            :
            <i className={'fa fa-users'}></i>
            }
        </Box>
    }
}


export default CircleIcon;