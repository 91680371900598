import React, { Component } from 'react';
 
import { 
    ListItem,
} from '@material-ui/core';


class CustomItem extends Component {
    constructor(props) {
        super(props);
        
        let className = (props.className) ? props.className : '';
        let data = (props.data) ? props.data : null;
        let active = (props.active) ? props.active : null;

        this.state = {
            className: className,
            data: data,
            active: active,
        };
    }
 

    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.state.data) {
            let data = (nextProps.data) ? nextProps.data : null;
            this.setState({
                data: data
            });
        }
        if (nextProps.active !== this.state.active) {
            let active = (nextProps.active) ? nextProps.active : null;
            this.setState({
                active: active
            });
        }
    }

 
    render() {
        return <ListItem
            button
            className={'custom-list-item custom-item ' + ((this.state.className && this.state.className !== '') ? this.state.className : '') + ' ' + ((this.state.active !== null) ? ((typeof this.state.active === "function") ? this.state.active() : (this.state.active ? "active" : "")) : "")}
            onClick={(e) => {
                if(this.props.onClick){
                    this.props.onClick(this);
                }
            }}
        >
            {this.props.children}
        </ListItem>
    }
}

export default CustomItem;