import React, { Component } from 'react';
import moment from 'moment'
import uuid from 'react-uuid'

import PreventTransitionPrompt from '../../layout/Main/PreventTransitionPrompt.jsx';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import wmsApi from "../../api/wmsApi.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import {RoleAccessService} from '../../data/role-access'; 
import { 
  Box,
  Grid,
  Container,
  Divider,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as CalendarIcon } from '../../assets/img/icons/calendar.svg';
import { ReactComponent as TimeIcon } from '../../assets/img/icons/time.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
import CustomAutosuggest from '../../components/CustomFields/CustomAutosuggest.jsx';
import CustomSelectOption from '../../components/CustomFields/CustomSelectOption.jsx';
import CustomTooltip from '../../components/Tooltip/CustomTooltip.jsx';
import CustomFormDialog from '../../components/Dialog/CustomFormDialog.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import CustomGoogleMapSearch from '../../components/GoogleMap/CustomGoogleMapSearch.jsx';
import CustomGuideStepper from '../../components/Stepper/CustomGuideStepper.jsx';

import DepartmentPICList from './Partial/DepartmentPICList.jsx';
import ItemsInJobList from './Partial/ItemsInJobList.jsx';
import StepsList from './Partial/StepsList.jsx';

import CustomerForm from './Partial/CustomerForm.jsx';
import DriverForm from './Partial/DriverForm.jsx';
import LogisticsAssistanceForm from './Partial/LogisticsAssistanceForm.jsx';

import Tour from 'reactour'


export class JobsForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let id = (this.props.location && this.props.location.state && this.props.location.state.id && this.props.location.state.id > 0) ? this.props.location.state.id : 0;

    this.isCustomerRequired = false;
    let grantedAccess = new RoleAccessService();
    this.state = {
      id: id,
      grantedAccess : grantedAccess,
      currentRole: window.localStorage.getItem('current_role') || null,
      isLoading: false,

      drop_off_date: '',
      drop_off_dateValidated: false,
      drop_off_time_planned: '',
      // drop_off_time_plannedValidated: false,
      drop_off_description: '',
      drop_off_descriptionValidated: false,
      drop_off_address: '',
      drop_off_addressValidated: false,
      amount: '',
      // amountValidated: false,
      
      drop_off_name: '',
      drop_off_nameValidated: false,

      drop_off_contact_name: '',
      drop_off_contact_nameValidated: false,

      drop_off_contact_no: '',
      drop_off_contact_noValidated: false,

      notification_email: '',
      notification_emailValidated: false,

      assign_type: true,
      job_type: '',
      picItems: [],
      itemsInJobList: [],
      job_steps: [],

      openDialogCustomer: false,
      itemDialogCustomer: null,
      
      openDialogDriver: false,
      itemDialogDriver: null,

      openDialogLogisticsAssistance: false,
      itemDialogLogisticsAssistance: null,

      customerItems: [],
      customerItemsIsLoading: false,
      customerItem: null,
      customerId: '',
      customerName: '',
      customerNameValidated: false,
      
      vendorItems: [],
      vendorItemsIsLoading: false,
      vendorItem: null,
      vendorId: '',
      vendorName: '',
      vendorNameValidated: false,

      workerItems: [],
      workerItemsIsLoading: false,
      workerItem: null,
      workerId: '',
      workerName: '',

      manPowerItems: [],
      manPowerItemsIsLoading: false,
      manPowerItem: null,
      manPowerId: '',
      manPowerName: '',

      branchItems: [],
      order_branch_id: '',
      
      departmentItems: [],
      departmentItem: null,
      department_id: '',

      customerDetailsIsLoading: true,
      customerDetails: null,

      orderStatusesIsLoading: false,
      orderStatuses: [],
      order_status: null,
      order_status_id: 0,

      jobStepStatusesIsLoading: false,
      jobStepStatuses: [],

      isFormChanged: false,

      openDialog: false,
      openDialogItem: null,

      isTourOpen: false,
      openSkipDialog: false,

      copyData: this.props.location.copyData
    }
  }

  componentDidMount() {
    const { isCustomerRequired, isOnBoarding } = this.context;
    this.isCustomerRequired = isCustomerRequired;
    
    if(isOnBoarding){
      this.setState({
        isTourOpen: true,
      });
    }

    this.callOrderStatusesApi((data) => {
      let orderStatusItem = apiUtil.customFilter(data, 'label', 'not assigned');
      if(orderStatusItem){
        this.setState({
          order_status: orderStatusItem.item,
          order_status_id: orderStatusItem.value,
        });
      }
    });

    this.callJobStepStatusesApi();

    if(this.state.id && this.state.id > 0){
      this.callDetailsApi(this.state.id, (data, itemsInTheJob) => {
        /* VENDOR */
        let vendorItems = [];
        let vendorItem = null;
        if(data && data.vendor){
          vendorItem = {
            value: (data.vendor_id) ? data.vendor_id : '',
            label: (data.vendor.application_company && data.vendor.application_company.company_name && data.vendor.application_company.company_name !== '') ? data.vendor.application_company.company_name : '',
            item: (data.vendor) ? data.vendor : null,
          };
          vendorItems.push(vendorItem);
        }
        this.setState({
          job_type: (data && data.job_type) ? data.job_type : '',
          assign_type: (data && data.assign_type && data.assign_type === 'vendor') ? false : true,
          vendorNameValidated: false,
          vendorName: (vendorItem && vendorItem.label) ? vendorItem.label : '',
          vendorId: (vendorItem && vendorItem.value) ? vendorItem.value : '',
          vendorItem: (vendorItem && vendorItem.item) ? vendorItem.item : null,
          vendorItems: vendorItems,
        });
        /* END VENDOR */


        /* STATUS */
        this.setState({
          order_status: (data && data.order_status) ? data.order_status : null,
          order_status_id: (data && data.order_status_id) ? data.order_status_id : '',
        });
        /* END STATUS */

        
        /* JOB DETAILS SECTION */
        this.setState({
          drop_off_date: (data && data.drop_off_date) ? moment(data.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '',
          drop_off_time_planned: (data && data.drop_off_time_planned) ? moment(data.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '',
          drop_off_description: (data && data.drop_off_description) ? data.drop_off_description : '',
          driver_notes: (data && data.driver_notes) ? data.driver_notes : '',
        });
        /* END JOB DETAILS SECTION */

        if(this.isCustomerRequired){
          /* CUSTOMERS DETAILS SECTION */
          let customerItems = [];
          let customerItem = null;
          if(data && data.customer){
            customerItem = {
              value: (data.customer_id) ? data.customer_id : '',
              label: (data.company_name) ? data.company_name : '',
              item: (data.customer) ? data.customer : null,
            };
            customerItems.push(customerItem);
          }

          this.setState({
            customerNameValidated: false,
            customerName: (customerItem && customerItem.label) ? customerItem.label : '',
            customerId: (customerItem && customerItem.value) ? customerItem.value : '',
            customerItem: (customerItem && customerItem.item) ? customerItem.item : null,
            customerItems: customerItems,
            amount: (data && data.amount) ? data.amount : '',
            item_tracking_number: (data.item_tracking_number) ? data.item_tracking_number : '',
            is_tracking_link_required: (data.is_tracking_link_required) ? data.is_tracking_link_required : false,
            is_notify_customer: (data.is_notify_customer) ? data.is_notify_customer : false,
          }, () => {
            if(data && data.customer_id){
              this.callCustomerDetailsApi(this.state.customerId, (customerData) => {
                let customerAddresses = (customerData && customerData.customer_addresses && customerData.customer_addresses.length > 0) ? customerData.customer_addresses : [];
                let branchItems = customerAddresses.map((item, i) => {
                  return {
                    value: item.id,
                    label: item.name,
                    item: item,
                  }
                });

                let departmentItems = [];
                if(customerData && customerData.departments && customerData.departments.length > 0){
                  departmentItems = customerData.departments.map((item, i) => {
                    return {
                      value: item.id,
                      label: item.name,
                      item: item,
                    }
                  });
                }

                this.setState({
                  branchItems: branchItems,
                  departmentItems: departmentItems,
                }, () => {
                  let drop_off_address = '';
                  let order_branch_id = (data && data.order_branch_id) ? data.order_branch_id : '';
                  let drop_off_addressItem = branchItems.filter(x => x.value === order_branch_id);
                  if(drop_off_addressItem && drop_off_addressItem.length > 0){
                    drop_off_address = drop_off_addressItem[0].item.address_line1;
                  }
                  this.setState({
                    order_branch_id: order_branch_id,
                    drop_off_address: drop_off_address,
                  });
                  
                  let departmentItem = null;
                  let department_id = (data && data.department_id) ? data.department_id : '';
                  let departmentArray = (departmentItems && departmentItems.length > 0) ? departmentItems.filter(x => x.value === department_id) : [];
                  if(departmentArray && departmentArray.length > 0){
                    departmentItem = departmentArray[0].item;
                  }
                  this.setState({
                    department_id: department_id,
                    departmentItem: departmentItem,
                  });
                });
              });
            }
          });


          let picItems = [];
          let drop_off_name = [];
          if(data.drop_off_name && data.drop_off_name !== ''){
            drop_off_name = data.drop_off_name.split(',');
          }

          let drop_off_contact_no = [];
          if(data.drop_off_contact_no && data.drop_off_contact_no !== ''){
            drop_off_contact_no = data.drop_off_contact_no.split(',');
          }

          let notification_email = [];
          if(data.notification_email && data.notification_email !== ''){
            notification_email = data.notification_email.split(',');
          }

          if(drop_off_name && drop_off_name.length > 0){
            for(let i = 0; i < drop_off_name.length; i++){
              let name = '';
              let contact_no = '';
              let email = '';
              
              if(drop_off_name[i] && drop_off_name[i] !== ''){
                name = drop_off_name[i];
              }
              if(drop_off_contact_no[i] && drop_off_contact_no[i] !== ''){
                contact_no = drop_off_contact_no[i];
              }
              if(notification_email[i] && notification_email[i] !== ''){
                email = notification_email[i];
              }
              
              picItems.push({
                uuid: uuid(),
                drop_off_name: name,
                drop_off_contact_no: contact_no,
                notification_email: email,
                remove: false,
              });
            }
          }

          this.setState({
            picItems: picItems,
          });
          /* END CUSTOMERS DETAILS SECTION */
        } else {
          /* END RECIPIENT DETAILS SECTION */
          this.setState({
            item_tracking_number: (data.item_tracking_number) ? data.item_tracking_number : '',
            is_tracking_link_required: (data.is_tracking_link_required) ? data.is_tracking_link_required : false,
            is_notify_customer: (data.is_notify_customer) ? data.is_notify_customer : false,
            drop_off_address: (data.drop_off_address) ? data.drop_off_address : '',
            drop_off_name: (data.drop_off_name) ? data.drop_off_name : '',
            drop_off_contact_name: (data.drop_off_contact_name) ? data.drop_off_contact_name : '',
            drop_off_contact_no: (data.drop_off_contact_no) ? data.drop_off_contact_no : '',
            notification_email: (data.notification_email) ? data.notification_email : '',
          });
          /* END RECIPIENT DETAILS SECTION */
        }
        


        /* ITEMS IN JOB LIST SECTION */
        let itemsInJobList = [];
        if(itemsInTheJob && itemsInTheJob.length > 0){
          itemsInJobList = itemsInTheJob.map((item, i) => {
            return {
              uuid: uuid(),
              id: item.id,
              description: item.description,
              quantity: item.quantity,
              uom: item.uom,
              weight: item.weight,
              remarks: item.remarks,
              remove: false,
            }
          });
        }
        this.setState({
          itemsInJobList: itemsInJobList,
        });
        /* END ITEMS IN JOB LIST SECTION */
        

        /* STEPS SECTION */
        let job_steps = [];
        if(data && data.job_steps && data.job_steps.length > 0){
          job_steps = data.job_steps.map((item, i) => {
            let orderStatusItem = apiUtil.customFilter(this.state.jobStepStatuses, 'value', item.job_step_status_id);
            let job_step_status = (orderStatusItem) ? orderStatusItem : null;
            
            return {
              uuid: uuid(),
              id: item.id,
              job_step_id: item.id,
              job_step_name: item.job_step_name,
              job_step_status_id: item.job_step_status_id,
              job_step_status: job_step_status,
              location: item.location,
              is_signature_required: item.is_signature_required,
              is_scan_required: item.is_scan_required,
              job_step_pic: item.job_step_pic,
              job_step_pic_contact: item.job_step_pic_contact,
              description: item.description,
              order_sequence: (i + 1),
              remove: false,
            }
          });
        }
        this.setState({
          job_steps: job_steps,
        });
        /* END STEPS SECTION */


        /* ASSIGN WORKERS SECTION */
        let manPowerItem = (data && data.extra_workers && data.extra_workers.length > 0) ? data.extra_workers[0] : null;
        this.setState({
          workerItem: data.drop_off_worker,
          workerName: data.drop_off_worker_name,
          workerId: data.drop_off_worker_id,
          manPowerItem: manPowerItem,
          manPowerName: (manPowerItem) ? ((manPowerItem.first_name && manPowerItem.first_name !== '') ? manPowerItem.first_name : '') + ' ' + ((manPowerItem.last_name && manPowerItem.last_name !== '') ? manPowerItem.last_name : '') : '',
          manPowerId: (manPowerItem) ? manPowerItem.id : 0,
        });
        /* END ASSIGN WORKERS SECTION */
      });
    }

    if (this.state.copyData && this.state.copyData.id > 0) {
      this.prepareCopyDataForm(this.state.copyData);
    }
  }

  
  /* GEO FUNCTIONS API */
  callGeoCoderAddressApi = (address = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      geocoderAddressIsLoading: true,
    }, () => {
      let params = {
        address: address,
      };

      ldsApi.create('geo_functions/geocoder_address', params, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              if(callback){
                callback(data.result);
              }
            } else {
              if(callback){
                callback(null);
              }
            }
          }, (error, type) => {
            if(callback){
              callback(null);
            }
          });
      });
    });
  }
  
  /* ORDER STATUSES API */
  callOrderStatusesApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      orderStatusesIsLoading: true,
      orderStatuses: [],
    }, () => {
      ldsApi.read('order_statuses', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let arr = [];
            if(data && data.length > 0){
              arr = data.map((item, i) => {
                let value = item.id;
                let label = item.status;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              orderStatusesIsLoading: false,
              orderStatuses: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }
  
  callJobStepStatusesApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      jobStepStatusesIsLoading: true,
      jobStepStatuses: [],
    }, () => {
      ldsApi.get('api/job_step_statuses', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let arr = [];
            if(data && data.result && data.result.length > 0){
              arr = data.result.map((item, i) => {
                let value = item.id;
                let label = item.name;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                jobStepStatusesIsLoading: false,
                jobStepStatuses: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                jobStepStatusesIsLoading: false,
                jobStepStatuses: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              jobStepStatusesIsLoading: false,
              jobStepStatuses: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }
  /* END ORDER STATUSES API */


  /* WORKER API */
  callWorkerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      workerItemsIsLoading: true,
      workerItems: [],
    }, () => {
      let param = {
        // page: 1,
        // take: apiUtil.getDefaultPageSize(),
        is_active: true,
        search: search,
        is_truck_driver: true,
        require_worker_only: true,
      };

      api.read('workers', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.id;
                let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                workerItemsIsLoading: false,
                workerItems: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                workerItemsIsLoading: false,
                workerItems: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              workerItemsIsLoading: false,
              workerItems: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }

  callWorkerManPowerReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      manPowerItemsIsLoading: true,
      manPowerItems: [],
    }, () => {
      let param = {
        page: 1,
        take: apiUtil.getDefaultPageSize(),
        is_active: true,
        search: search,
        is_man_power: true,
        require_worker_only: true,
      };

      api.read('workers', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.id;
                let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                manPowerItemsIsLoading: false,
                manPowerItems: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                manPowerItemsIsLoading: false,
                manPowerItems: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              manPowerItemsIsLoading: false,
              manPowerItems: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }
  /* END WORKER API */


  /* VENDOR API */
  callVendorReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      vendorItemsIsLoading: true,
      vendorItems: [],
    }, () => {
      let param = {
        page: 1,
        take: apiUtil.getDefaultPageSize(),
        is_active: true,
      };

      api.read('vendors/active_vendor', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.id;
                let label = item.company_name;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                vendorItemsIsLoading: false,
                vendorItems: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                vendorItemsIsLoading: false,
                vendorItems: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              vendorItemsIsLoading: false,
              vendorItems: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }
  /* END VENDOR API */


  /* CUSTOMER API */
  callCustomerReadApi = (search = '', callback = null) => {
    const { accessToken, isOnBoarding, onBoardingCustomer } = this.context;

    this.setState({
      customerItemsIsLoading: true,
      customerItems: [],
    }, () => {
      if(!isOnBoarding){
        let param = {
          page: 1,
          take: apiUtil.getDefaultPageSize(),
          is_active: true,
          search: search,
        };
  
        wmsApi.read('customers', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              let result = (data.result && data.result.length > 0) ? data.result : [];
  
              let arr = [];
              if(result && result.length > 0){
                for(let i = 0; i < result.length; i++){
                  let item = result[i];
                  
                  let value = item.id;
                  let label = item.company_name;
  
                  if(arr.findIndex(x => x.value === value) === -1){
                    arr.push({
                      value: value,
                      label: label,
                      item: item
                    });
                  }
                }
                
                this.setState({
                  customerItemsIsLoading: false,
                  customerItems: arr,
                }, () => {
                  if(callback){
                    callback(arr);
                  }
                });
              } else {
                this.setState({
                  customerItemsIsLoading: false,
                  customerItems: [],
                }, () => {
                  if(callback){
                    callback([]);
                  }
                });
              }
            }, (error, type) => {
              this.setState({
                customerItemsIsLoading: false,
                customerItems: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            });
        });
      } else {
        let jsonCustomer = null;
        try {
            jsonCustomer = JSON.parse(onBoardingCustomer);
        } catch(e){}

        let customer = null;
        if(jsonCustomer){
          customer = {
            value: jsonCustomer.id,
            label: jsonCustomer.company_name,
            item: jsonCustomer
          }
        }

        let arr = [];
        if(customer){
          arr.push(customer);

          this.setState({
            customerItemsIsLoading: false,
            customerItems: arr,
          }, () => {
            if(callback){
              callback(arr);
            }
          });
        } else {
          this.setState({
            customerItemsIsLoading: false,
            customerItems: [],
          }, () => {
            if(callback){
              callback([]);
            }
          });
        }
      }
    });
  }

  callCustomerDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      customerDetailsIsLoading: true,
      customerDetails: null,
    }, () => {
      wmsApi.details('customers', id, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data && data.customer) ? data.customer : null;

            if(result){
              this.setState({
                customerDetailsIsLoading: false,
                customerDetails: result,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                customerDetailsIsLoading: false,
                customerDetails: null,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              customerDetailsIsLoading: false,
              customerDetails: null,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END CUSTOMER */


  /* API */
  callDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.read('orders/' + id, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null, (data && data.sorted_order_details) ? data.sorted_order_details : []);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, []);
              }
            });
          });
      });
    });
  }

  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callUpdateApi = (id, row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('orders/' + id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }
  /* END API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    const { isOnBoarding } = this.context;

    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
                <IconButton 
                    onClick={() => {
                        this.props.history.push('/job-list');
                    }}
                >
                    <CloseIcon />
                </IconButton>
          </Grid>
        </Box>}
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
                <Divider orientation={'vertical'} />
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={isOnBoarding ? 'auto' : true}>
            <CustomLabel
                className={'no-margin'}
                label={(this.state.id > 0) ? 'Edit Job' : 'Add New Job'}
                weight={'bold'}
                size={'md'}
            />
          </Grid>
        </Box>
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {this.setStepper()}
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={'auto'}>
       
            <CustomButton 
              className={'create-job-step'}
              type={'submit'}
              color={'secondary'}
              isLoading={this.state.isLoading}
            ><Box pl={2} pr={2}>Submit job</Box>
            </CustomButton>
          
       
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* FORM */
  jobDetailsForm = () => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    let isOwner = user.owner_info.is_customer_admin !== undefined ? user.owner_info.is_customer_admin : false;

    return <Box className={'job-details-step'} pb={5}>
        <Grid container>
            <Grid item xs={12}>
                <CustomLabel
                    label={'Job Details'}
                    weight={'bold'}
                    size={'md'}
                />
                <Divider light />
            </Grid>
            <Grid item xs={12}>
                <Container maxWidth={'xs'}>
                    <Box clone pt={5}>
                        <Grid container>
                            { 
                              isOwner ? 
                              "" :
                              <>
                                <Box clone>
                                    <Grid item xs={12}>
                                        <CustomLabel
                                            label={'Select Job Nature'}
                                        />
                                    </Grid>
                                </Box>

                                <Box clone pr={{ xs: 0, md: 2 }} pb={2}>
                                    <Grid item xs={12} md={6}>
                                        <CustomButton 
                                            className={'outlined-default-secondary ' + (this.state.assign_type ? 'active' : '')}
                                            variant={'outlined'}
                                            color={'secondary'}
                                            fullWidth={true}
                                            disabled={(this.state.id && this.state.id > 0) ? true : false}
                                            onClick={() => {
                                                this.setState({
                                                    assign_type: true,
                                                    isFormChanged: true,
                                                });
                                            }}
                                        >
                                            Own Jobs
                                        </CustomButton>
                                    </Grid>
                                </Box>
                                
                                <Box clone pb={2}>
                                    <Grid item xs={12} md={6}>
                                        <CustomButton 
                                            className={'outlined-default-secondary ' + (!this.state.assign_type ? 'active' : '')}
                                            variant={'outlined'}
                                            color={'secondary'}
                                            fullWidth={true}
                                            disabled={(this.state.id && this.state.id > 0) ? true : false}
                                            onClick={() => {
                                                this.setState({
                                                    assign_type: false,
                                                    isFormChanged: true,
                                                });
                                            }}
                                        >
                                            Vendor Jobs
                                        </CustomButton>
                                    </Grid>
                                </Box>
                              </>
                            }
                            
                            {!this.state.assign_type && <Box clone>
                                <Grid item xs={12}>
                                    <CustomAutosuggest 
                                      label={'Select Vendor'}
                                      placeholder={'Select Vendor (type something to find a vendor)'}
                                      labelComponent={<CustomTooltip title={'Select from your list of vendors.'} />}
                                      items={this.state.vendorItems}
                                      isLoading={this.state.vendorItemsIsLoading}
                                      value={this.state.vendorName}
                                      error={this.state.vendorNameValidated}
                                      required={true}
                                      onChange={(value) => {
                                        if(value){
                                          this.setState({
                                            vendorNameValidated: false,
                                            vendorName: value.label,
                                            vendorId: value.value,
                                            vendorItem: value.item,
                                            isFormChanged: true,
                                          });
                                        } else {
                                          this.setState({
                                            vendorNameValidated: true,
                                            vendorName: '',
                                            vendorId: '',
                                            vendorItem: null,
                                            isFormChanged: true,
                                          });
                                        }
                                      }}
                                      onSearch={(value, e) => {
                                        this.setState({
                                          vendorName: value,
                                          isFormChanged: true,
                                        }, () => {
                                          this.callVendorReadApi(value, (arr) => {
                                            this.setState({
                                              vendorItems: arr
                                            }, () => {
                                              e.showAllSuggestions();
                                            });
                                          });
                                        });
                                      }}
                                      onClick={(value, e) => {
                                        this.callVendorReadApi(value, (arr) => {
                                          this.setState({
                                            vendorItems: arr
                                          }, () => {
                                            e.showAllSuggestions();
                                          });
                                        });
                                      }}
                                      onInit={() => {
                                        this.callVendorReadApi();
                                      }}
                                    />
                                </Grid>
                            </Box>}

                            <Box clone>
                                <Grid item xs={12}>
                                    <CustomInput
                                        label={'Job Type'}
                                        placeholder={'Enter job type here...'}
                                        value={this.state.job_type}
                                        required={false}
                                        onChange={(e) => {
                                            this.setState({
                                                job_type: e.target.value,
                                                isFormChanged: true,
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>

                            <Box clone>
                                <Grid item xs={12}>
                                    <CustomInput
                                        readOnly={this.state.id > 0 ? this.state.grantedAccess.isShouldReadyOnlyAccess(this.state.currentRole, "job_date"): false}
                                        label={'Select Job Date'}
                                        placeholder={'DD/MM/YYYY'}
                                        color={'secondary'}
                                        type={"date"}
                                        endAdornment={(obj) => {
                                          return <Box
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                              }}
                                          >
                                              <CalendarIcon />
                                          </Box>
                                        }}
                                        value={this.state.drop_off_date}
                                        error={this.state.drop_off_dateValidated}
                                        required={true}
                                        onChange={(e) => {
                                            this.setState({
                                              drop_off_date: e.target.value,
                                              drop_off_dateValidated: (e.target.value !== '') ? false : true,
                                              isFormChanged: true,
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>

                            <Box clone>
                                <Grid item xs={12}>
                                    <CustomInput
                                        readOnly={this.state.id > 0 ? this.state.grantedAccess.isShouldReadyOnlyAccess(this.state.currentRole, "job_time"): false}
                                        label={'Select Job Time'}
                                        placeholder={'-- : --'}
                                        type={"time"}
                                        color={'secondary'}
                                        endAdornment={(obj) => {
                                          return <Box
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                              }}
                                          >
                                              <TimeIcon />
                                          </Box>
                                        }}
                                        labelComponent={<CustomTooltip title={'The scheduled date & time of this job.'} />}
                                        value={this.state.drop_off_time_planned}
                                        // error={this.state.drop_off_time_plannedValidated}
                                        // required={true}
                                        onChange={(e) => {
                                            this.setState({
                                                drop_off_time_planned: e.target.value,
                                                // drop_off_time_plannedValidated: (e.target.value !== '') ? false : true,
                                                isFormChanged: true,
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>
                            
                            <Box clone>
                                <Grid item xs={12}>
                                    <CustomInput
                                        label={'Job Description'}
                                        placeholder={'Enter your description here...'}
                                        value={this.state.drop_off_description}
                                        error={this.state.drop_off_descriptionValidated}
                                        multiline={true}
                                        rows={5}
                                        required={true}
                                        onChange={(e) => {
                                            this.setState({
                                                drop_off_description: e.target.value,
                                                drop_off_descriptionValidated: (e.target.value !== '') ? false : true,
                                                isFormChanged: true,
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>

                            <Box clone>
                                <Grid item xs={12}>
                                    <CustomInput
                                        label={'Driver Notes'}
                                        placeholder={'Enter driver notes here...'}
                                        value={this.state.driver_notes}
                                        multiline={true}
                                        rows={5}
                                        required={false}
                                        onChange={(e) => {
                                            this.setState({
                                                driver_notes: e.target.value,
                                                isFormChanged: true,
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>

                        </Grid>
                    </Box>
                </Container>
            </Grid>
        </Grid>
    </Box>
  }

  customersDetailsForm = () => {
    if(this.isCustomerRequired){
      return <Box pt={5} pb={5}>
          <Grid container>
              <Grid item xs={12}>
                  <CustomLabel
                      label={'Customers Details'}
                      weight={'bold'}
                      size={'md'}
                  />
                  <Divider light />
              </Grid>
              <Grid item xs={12}>
                  <Container maxWidth={'xs'}>
                      <Box clone pt={5}>
                          <Grid container>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomAutosuggest 
                                        label={'Select Customer'}
                                        placeholder={'Enter any keyword to search for customers'}
                                        labelComponent={<CustomTooltip title={'Select from your list of customers. Create one if u dont have them in your list.'} />}
                                        helperText={<Box>
                                          <Grid container alignItems={'center'}>
                                              <Box clone>
                                                  <Grid item xs={'auto'}>Customer not in list?</Grid>
                                              </Box>
                                              <Box clone>
                                                  <Grid item xs={'auto'}>
                                                      <CustomButton 
                                                          className={'underline'}
                                                          href={'/'}
                                                          color={'primary'}
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              e.stopPropagation();

                                                              this.setState({
                                                                openDialogCustomer: true,
                                                                itemDialogCustomer: null,
                                                              });
                                                          }}
                                                      >
                                                          Create customer
                                                      </CustomButton>
                                                  </Grid>
                                              </Box>
                                          </Grid>
                                        </Box>}
                                        items={this.state.customerItems}
                                        isLoading={this.state.customerItemsIsLoading}
                                        value={this.state.customerName}
                                        error={this.state.customerNameValidated}
                                        required={true}
                                        onChange={(value) => {
                                          if(value){
                                            if(this.state.customerId > 0){
                                              this.setState({
                                                customerNameValidated: false,
                                                customerName: value.label,
                                                customerId: value.value,
                                                customerItem: value.item,
                                                isFormChanged: true,
                                              }, () => {
                                                this.setState({
                                                  openDialog: true,
                                                  openDialogItem: value.item,
                                                });
                                              });
                                            } else {
                                              this.setState({
                                                customerNameValidated: false,
                                                customerName: value.label,
                                                customerId: value.value,
                                                customerItem: value.item,
                                                isFormChanged: true,
                                              }, () => {
                                                this.addNewCustomerDetails(value.item);
                                              });
                                            }
                                          } else {
                                            this.setState({
                                              customerNameValidated: true,
                                              customerName: '',
                                              customerId: '',
                                              customerItem: null,
                                              isFormChanged: true,
                                            }, () => {
                                              this.setState({
                                                branchItems: [],
                                                departmentItems: [],
                                              });
                                            });
                                          }
                                        }}
                                        onSearch={(value, e) => {
                                          this.setState({
                                            customerName: value,
                                            isFormChanged: true,
                                          }, () => {
                                            this.callCustomerReadApi(value, (arr) => {
                                              this.setState({
                                                customerItems: arr
                                              }, () => {
                                                e.showAllSuggestions();
                                              });
                                            });
                                          });
                                        }}
                                        onClick={(value, e) => {
                                          this.callCustomerReadApi(value, (arr) => {
                                            this.setState({
                                              customerItems: arr
                                            }, () => {
                                              e.showAllSuggestions();
                                            });
                                          });
                                        }}
                                        onInit={() => {
                                          this.callCustomerReadApi();
                                        }}
                                      />
                                  </Grid>
                              </Box>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomSelectOption
                                          label={'Branch Name'}
                                          placeholder={'Select branch'}
                                          value={this.state.order_branch_id}
                                          items={this.state.branchItems}
                                          onChange={(value, item) => {
                                              this.setState({
                                                order_branch_id: value,
                                                isFormChanged: true,
                                              }, () => {
                                                this.setState({
                                                  drop_off_address: (item && item.item && item.item.address_line1) ? item.item.address_line1 : '',
                                                });
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <span></span>
                                      <CustomInput
                                          label={'Branch Address'}
                                          placeholder={'Search address'}
                                          value={this.state.drop_off_address}
                                          error={this.state.drop_off_addressValidated}
                                          required={true}
                                          onChange={(e) => {
                                              this.setState({
                                                  drop_off_address: e.target.value,
                                                  drop_off_addressValidated: (e.target.value !== '') ? false : true,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomSelectOption
                                          label={'Department'}
                                          placeholder={'Select department'}
                                          value={this.state.department_id}
                                          items={this.state.departmentItems}
                                          onChange={(value, item) => {
                                              this.setState({
                                                department_id: value,
                                                departmentItem: item,
                                                isFormChanged: true,
                                              }, () => {
                                                let picItems = [];
                                                if(item && item.item && item.item.contact_people && item.item.contact_people.length > 0){
                                                  picItems = item.item.contact_people.map((item, i) => {
                                                    return {
                                                      uuid: uuid(),
                                                      id: item.id,
                                                      drop_off_name: item.first_name,
                                                      notification_email: item.email,
                                                      drop_off_contact_no: item.contact_no,
                                                      remove: false,
                                                    }
                                                  });
                                                }
                                                this.setState({
                                                  picItems: picItems,
                                                });
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>
                              
                              <Box clone>
                                  <Grid item xs={12}>
                                      <span></span>
                                      <CustomInput
                                          label={'Price'}
                                          placeholder={'0.00'}
                                          type={'number'}
                                          color={'secondary'}
                                          startAdornment={<p>$</p>}
                                          // endAdornment={<span>USD</span>}
                                          value={this.state.amount}
                                          // error={this.state.amountValidated}
                                          // required={true}
                                          onChange={(e) => {
                                              this.setState({
                                                  amount: e.target.value,
                                                  // amountValidated: (e.target.value !== '') ? false : true,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Tracking Number'}
                                          placeholder={'Tracking Number'}
                                          type={'number'}
                                          value={this.state.item_tracking_number}
                                          onChange={(e) => {
                                              this.setState({
                                                  item_tracking_number: e.target.value,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <Grid container>
                                          <Box clone>
                                              <Grid item xs={'auto'}>
                                                  <CustomCheckbox
                                                      label={'Notify recipient'}
                                                      checked={this.state.is_notify_customer}
                                                      onChange={(checked) => {
                                                          this.setState({
                                                              is_notify_customer: checked,
                                                              isFormChanged: true,
                                                          }, () => {
                                                            if(!checked){
                                                              this.setState({
                                                                is_tracking_link_required: false,
                                                              });
                                                            }
                                                          });
                                                      }}
                                                  />
                                              </Grid>
                                          </Box>
                                          <Box clone>
                                              <Grid item xs={'auto'}>
                                                  <CustomCheckbox
                                                      label={'Send tracking link'}
                                                      checked={this.state.is_tracking_link_required}
                                                      onChange={(checked) => {
                                                          this.setState({
                                                              is_tracking_link_required: checked,
                                                              isFormChanged: true,
                                                          }, () => {
                                                            if(checked){
                                                              this.setState({
                                                                is_notify_customer: true,
                                                              });
                                                            }
                                                          });
                                                      }}
                                                  />
                                              </Grid>
                                          </Box>
                                      </Grid>
                                  </Grid>
                              </Box>

                          </Grid>
                      </Box>
                  </Container>
              </Grid>
          </Grid>
      </Box>
    } else {
      return <Box pt={5} pb={5}>
          <Grid container>
              <Grid item xs={12}>
                  <CustomLabel
                      label={'Recipient Details'}
                      weight={'bold'}
                      size={'md'}
                  />
                  <Divider light />
              </Grid>
              <Grid item xs={12}>
                  <Container maxWidth={'xs'}>
                      <Box clone pt={5}>
                          <Grid container>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Recipient Name'}
                                          placeholder={'Recipient Name'}
                                          value={this.state.drop_off_name}
                                          error={this.state.drop_off_contact_nameValidated}
                                          required={true}
                                          onChange={(e) => {
                                              this.setState({
                                                  drop_off_name: e.target.value,
                                                  drop_off_nameValidated: (e.target.value !== '') ? false : true,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>
                              
                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Recipient Email'}
                                          placeholder={'Recipient Email'}
                                          value={this.state.notification_email}
                                          error={this.state.notification_emailValidated}
                                          required={true}
                                          onChange={(e) => {
                                              this.setState({
                                                  notification_email: e.target.value,
                                                  notification_emailValidated: (e.target.value !== '') ? false : true,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Recipient Phone No.'}
                                          placeholder={'Recipient Phone No.'}
                                          value={this.state.drop_off_contact_no}
                                          error={this.state.drop_off_contact_noValidated}
                                          required={true}
                                          onChange={(e) => {
                                              this.setState({
                                                  drop_off_contact_no: e.target.value,
                                                  drop_off_contact_noValidated: (e.target.value !== '') ? false : true,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomGoogleMapSearch
                                        label={'Full Address'}
                                        placeholder={'Full Address'}
                                        value={this.state.drop_off_address}
                                        error={this.state.drop_off_addressValidated}
                                        required={true}
                                        onChange={(e) => {
                                            this.setState({
                                              drop_off_address: e.target.value,
                                                drop_off_addressValidated: (e.target.value !== '') ? false : true,
                                            });
                                        }}
                                        onPlacesChanged={(place) => {
                                          let address = apiUtil.getGmapAddress(place);
                          
                                            this.setState({
                                              drop_off_address: address,
                                                latitude: place.geometry.location.lat(),
                                                longitude: place.geometry.location.lng(),
                                                drop_off_addressValidated: (address !== '') ? false : true,
                                            });
                                        }}
                                      />
                                  </Grid>
                              </Box>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Tracking Number'}
                                          placeholder={'Tracking Number'}
                                          type={'number'}
                                          value={this.state.item_tracking_number}
                                          onChange={(e) => {
                                              this.setState({
                                                  item_tracking_number: e.target.value,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <Grid container>
                                          <Box clone>
                                              <Grid item xs={'auto'}>
                                                  <CustomCheckbox
                                                      label={'Notify recipient'}
                                                      checked={this.state.is_notify_customer}
                                                      onChange={(checked) => {
                                                          this.setState({
                                                              is_notify_customer: checked,
                                                              isFormChanged: true,
                                                          }, () => {
                                                            if(!checked){
                                                              this.setState({
                                                                is_tracking_link_required: false,
                                                              });
                                                            }
                                                          });
                                                      }}
                                                  />
                                              </Grid>
                                          </Box>
                                          <Box clone>
                                              <Grid item xs={'auto'}>
                                                  <CustomCheckbox
                                                      label={'Send tracking link'}
                                                      checked={this.state.is_tracking_link_required}
                                                      onChange={(checked) => {
                                                          this.setState({
                                                              is_tracking_link_required: checked,
                                                              isFormChanged: true,
                                                          }, () => {
                                                            if(checked){
                                                              this.setState({
                                                                is_notify_customer: true,
                                                              });
                                                            }
                                                          });
                                                      }}
                                                  />
                                              </Grid>
                                          </Box>
                                      </Grid>
                                  </Grid>
                              </Box>

                          </Grid>
                      </Box>
                  </Container>
              </Grid>
          </Grid>
      </Box>
    }
  }

  personInChargeForm = () => {
    if(this.isCustomerRequired){
      return <Box pt={5} pb={5}>
          <DepartmentPICList 
              items={this.state.picItems}
              onAddNew={(data) => {
                  this.setState({
                    picItems: data,
                    isFormChanged: true,
                  });
              }}
              onEdit={(data) => {
                  this.setState({
                    picItems: data,
                    isFormChanged: true,
                  });
              }}
              onRemove={(data) => {
                  this.setState({
                    picItems: data,
                    isFormChanged: true,
                  });
              }}
          />
      </Box>
    }
  }

  itemsInJobForm = () => {
    return <Box pt={5} pb={5}>
        <ItemsInJobList 
            items={this.state.itemsInJobList}
            onAddNew={(data) => {
                this.setState({
                  itemsInJobList: data,
                  isFormChanged: true,
                });
            }}
            onEdit={(data) => {
                this.setState({
                  itemsInJobList: data,
                  isFormChanged: true,
                });
            }}
            onRemove={(data) => {
                this.setState({
                  itemsInJobList: data,
                  isFormChanged: true,
                });
            }}
        />
    </Box>
  }

  jobStepForm = () => {
    return <Box className={'job-steps-step'} pt={5} pb={5}>
        <StepsList 
            items={this.state.job_steps}
            isAddJob={this.state.id === 0}
            onAddNew={(data) => {
                this.setState({
                  job_steps: data,
                  isFormChanged: true,
                });
            }}
            onEdit={(data) => {
                this.setState({
                  job_steps: data,
                  isFormChanged: true,
                });
            }}
            onRemove={(data) => {
                this.setState({
                  job_steps: data,
                  isFormChanged: true,
                });
            }}
        />
    </Box>
  }

  assignWorkersForm = () => {
    return <Box pt={5} pb={5}>
        <Grid container>
            <Grid item xs={12}>
                <CustomLabel
                    label={<React.Fragment>Assign workers <small className={'muted'}>Optional</small></React.Fragment>}
                    labelComponent={<CustomTooltip title={'The job status will be \'Unassigned\' if no drivers are assigned here.'} />}
                    weight={'bold'}
                    size={'md'}
                />
                <Divider light />
            </Grid>
            <Grid item xs={12}>
                <Container maxWidth={'xs'}>
                    <Box clone pt={5}>
                        <Grid container>

                            <Box clone>
                                <Grid item xs={12}>
                                    <CustomAutosuggest 
                                      label={'Transporter Operator'}
                                      placeholder={'Enter any keyword to search for transport operator'}
                                      helperText={<Box>
                                        <Grid container alignItems={'center'}>
                                            <Box clone>
                                                <Grid item xs={'auto'}>Driver not in list?</Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={'auto'}>
                                                    <CustomButton 
                                                        className={'underline'}
                                                        href={'/'}
                                                        color={'primary'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();

                                                            this.setState({
                                                              openDialogDriver: true,
                                                              itemDialogDriver: null,
                                                            });
                                                        }}
                                                    >
                                                        Create new Transport operator
                                                    </CustomButton>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                      </Box>}
                                      items={this.state.workerItems}
                                      isLoading={this.state.workerItemsIsLoading}
                                      value={this.state.workerName}
                                      onChange={(value) => {
                                        if(value){
                                          this.setState({
                                            workerName: value.label,
                                            workerId: value.value,
                                            workerItem: value.item,
                                            isFormChanged: true,
                                          }, () => {
                                            let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'assigned');
                                            if(orderStatusItem){
                                              this.setState({
                                                order_status: orderStatusItem.item,
                                                order_status_id: orderStatusItem.value,
                                              });
                                            }
                                          });
                                        } else {
                                          this.setState({
                                            workerName: '',
                                            workerId: '',
                                            workerItem: null,
                                            isFormChanged: true,
                                          }, () => {
                                            let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'not assigned');
                                            if(orderStatusItem){
                                              this.setState({
                                                order_status: orderStatusItem.item,
                                                order_status_id: orderStatusItem.value,
                                              });
                                            }
                                          });
                                        }
                                      }}
                                      onSearch={(value, e) => {
                                        this.setState({
                                          workerName: value,
                                          isFormChanged: true,
                                        }, () => {
                                          this.callWorkerDriverReadApi(value, (arr) => {
                                            this.setState({
                                              workerItems: arr
                                            }, () => {
                                              e.showAllSuggestions();
                                            });
                                          });
                                        });
                                      }}
                                      onClick={(value, e) => {
                                        this.callWorkerDriverReadApi(value, (arr) => {
                                          this.setState({
                                            workerItems: arr
                                          }, () => {
                                            e.showAllSuggestions();
                                          });
                                        });
                                      }}
                                      onInit={() => {
                                        this.callWorkerDriverReadApi();
                                      }}
                                    />
                                </Grid>
                            </Box>

                            <Box clone>
                                <Grid item xs={12}>
                                    <CustomAutosuggest 
                                      label={'Logistic Assistance'}
                                      placeholder={'Enter any keyword to search for logistic assistance'}
                                      helperText={<Box>
                                          <Grid container alignItems={'center'}>
                                              <Box clone>
                                                  <Grid item xs={'auto'}>Logistic assistance not in list?</Grid>
                                              </Box>
                                              <Box clone>
                                                  <Grid item xs={'auto'}>
                                                      <CustomButton 
                                                          className={'underline'}
                                                          href={'/'}
                                                          color={'primary'}
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              e.stopPropagation();

                                                              this.setState({
                                                                openDialogLogisticsAssistance: true,
                                                                itemDialogLogisticsAssistance: null,
                                                              });
                                                          }}
                                                      >
                                                          Create new Logistics assistance
                                                      </CustomButton>
                                                  </Grid>
                                              </Box>
                                          </Grid>
                                      </Box>}
                                      items={this.state.manPowerItems}
                                      isLoading={this.state.manPowerItemsIsLoading}
                                      value={this.state.manPowerName}
                                      onChange={(value) => {
                                        if(value){
                                          this.setState({
                                            manPowerName: value.label,
                                            manPowerId: value.value,
                                            manPowerItem: value.item,
                                            isFormChanged: true,
                                          });
                                        } else {
                                          this.setState({
                                            manPowerName: '',
                                            manPowerId: '',
                                            manPowerItem: null,
                                          });
                                        }
                                      }}
                                      onSearch={(value, e) => {
                                        this.setState({
                                          manPowerName: value,
                                          isFormChanged: true,
                                        }, () => {
                                          this.callWorkerManPowerReadApi(value, (arr) => {
                                            this.setState({
                                              manPowerItems: arr
                                            }, () => {
                                              e.showAllSuggestions();
                                            });
                                          });
                                        });
                                      }}
                                      onClick={(value, e) => {
                                        this.callWorkerManPowerReadApi(value, (arr) => {
                                          this.setState({
                                            manPowerItems: arr
                                          }, () => {
                                            e.showAllSuggestions();
                                          });
                                        });
                                      }}
                                      onInit={() => {
                                        this.callWorkerManPowerReadApi();
                                      }}
                                    />
                                </Grid>
                            </Box>

                        </Grid>
                    </Box>
                </Container>
            </Grid>
        </Grid>
    </Box>
  }
  /* END FORM */

  addNewCustomerDetails = (data) => {
    const { isOnBoarding, onBoardingCustomer } = this.context;

    if(!isOnBoarding){
      /* ADD NEW CUSTOMER DETAILS */
      if(data && data.id){
        this.callCustomerDetailsApi(this.state.customerId, (customerData) => {
          let customerAddresses = (customerData && customerData.customer_addresses && customerData.customer_addresses.length > 0) ? customerData.customer_addresses : [];
          let branchItems = customerAddresses.map((item, i) => {
            return {
              value: item.id,
              label: item.name,
              item: item,
            }
          });

          let departmentItems = [];
          if(customerData && customerData.departments && customerData.departments.length > 0){
            departmentItems = customerData.departments.map((item, i) => {
              return {
                value: item.id,
                label: item.name,
                item: item,
              }
            });
          }

          this.setState({
            branchItems: branchItems,
            departmentItems: departmentItems,
          }, () => {
            let drop_off_address = '';
            let order_branch_id = (data && data.customer_addresses && data.customer_addresses[0] && data.customer_addresses[0].id) ? data.customer_addresses[0].id : '';
            let drop_off_addressItem = branchItems.filter(x => x.value === order_branch_id);
            if(drop_off_addressItem && drop_off_addressItem.length > 0){
              drop_off_address = drop_off_addressItem[0].item.address_line1;
            }
            this.setState({
              order_branch_id: order_branch_id,
              drop_off_address: drop_off_address,
            });
            
            let departmentItem = null;
            let department_id = (this.state.departmentItems && this.state.departmentItems[0] && this.state.departmentItems[0].value) ? this.state.departmentItems[0].value : '';
            let departmentArray = (this.state.departmentItems && this.state.departmentItems.length > 0) ? this.state.departmentItems.filter(x => x.value === department_id) : [];
            if(departmentArray && departmentArray.length > 0){
              departmentItem = departmentArray[0];
            }
            this.setState({
              department_id: department_id,
              departmentItem: departmentItem,
            });

            let picItems = [];
            if(departmentItem && departmentItem.item && departmentItem.item.contact_people && departmentItem.item.contact_people.length > 0){
              for(let x = 0; x < departmentItem.item.contact_people.length; x++){
                let item = departmentItem.item.contact_people[x];

                picItems.push({
                  uuid: uuid(),
                  id: item.id,
                  drop_off_name: item.first_name,
                  drop_off_contact_no: item.contact_no,
                  notification_email: item.email,
                  remove: false,
                });
              }
            }

            this.setState({
              picItems: picItems,
            });


            /* STEPS SECTION */
            let orderStatusItem = apiUtil.customFilter(this.state.jobStepStatuses, 'label', 'Pending');
            let job_step_status = (orderStatusItem) ? orderStatusItem : null;
            
            let job_step_pic = '';
            let job_step_pic_contact = '';
            let picItem = (picItems && picItems.length > 0) ? picItems[0] : null;
            if(picItem){
              job_step_pic = picItem.drop_off_name;
              job_step_pic_contact = picItem.drop_off_contact_no;
            }

            let job_steps = this.state.job_steps;
            if(job_steps && job_steps.length > 0){
              job_steps = job_steps.map((item, i) => {
                item.remove = true;
                return item;
              });
            }

            let step = {
              uuid: uuid(),
              job_step_name: 'Delivery',
              is_signature_required: true,
              is_scan_required: false,
              description: '',
              order_sequence: 1,
              remove: false,
              job_step_status_id: job_step_status.value,
              job_step_status: job_step_status,
              location: drop_off_address,
              latitude: 0,
              longitude: 0,
              job_step_pic: job_step_pic,
              job_step_pic_contact: job_step_pic_contact,
            };

            this.callGeoCoderAddressApi(step.location, (data) => {
                if(data){
                  step.latitude = data.lat;
                  step.longitude = data.lng;
                  
                }
            });
            
            job_steps.push(step);

            this.setState({
              job_steps: job_steps,
            });
            /* END STEPS SECTION */
          });
        });
      }
      /* END ADD NEW CUSTOMER DETAILS */
    } else {
      let customerData = null;
      try {
        customerData = JSON.parse(onBoardingCustomer);
      } catch(e){}

      if(customerData){
        let customerAddresses = (customerData && customerData.addresses && customerData.addresses.length > 0) ? customerData.addresses : [];
        let branchItems = customerAddresses.map((item, i) => {
          return {
            value: item.uuid,
            label: item.name,
            item: item,
          }
        });

        let departmentItems = [];
        if(customerData && customerData.departments && customerData.departments.length > 0){
          departmentItems = customerData.departments.map((item, i) => {
            return {
              value: item.uuid,
              label: item.department_name,
              item: item,
            }
          });
        }

        this.setState({
          branchItems: branchItems,
          departmentItems: departmentItems,
        }, () => {
          let drop_off_address = '';
          let order_branch_id = (data && data.addresses && data.addresses[0] && data.addresses[0].id) ? data.addresses[0].id : '';
          let drop_off_addressItem = branchItems.filter(x => x.value === order_branch_id);
          if(drop_off_addressItem && drop_off_addressItem.length > 0){
            drop_off_address = drop_off_addressItem[0].item.address_line1;
          }
          this.setState({
            order_branch_id: order_branch_id,
            drop_off_address: drop_off_address,
          });
          
          let departmentItem = null;
          let department_id = (this.state.departmentItems && this.state.departmentItems[0] && this.state.departmentItems[0].uuid) ? this.state.departmentItems[0].uuid : '';
          let departmentArray = (this.state.departmentItems && this.state.departmentItems.length > 0) ? this.state.departmentItems.filter(x => x.uuid === department_id) : [];
          if(departmentArray && departmentArray.length > 0){
            departmentItem = departmentArray[0];
          }
          this.setState({
            department_id: department_id,
            departmentItem: departmentItem,
          });

          let picItems = [];
          if(departmentItem && departmentItem.item && departmentItem.item.contact_people && departmentItem.item.contact_people.length > 0){
            for(let x = 0; x < departmentItem.item.contact_people.length; x++){
              let item = departmentItem.item.contact_people[x];

              picItems.push({
                uuid: uuid(),
                id: item.id,
                drop_off_name: item.first_name,
                drop_off_contact_no: item.contact_no,
                notification_email: item.email,
                remove: false,
              });
            }
          }

          this.setState({
            picItems: picItems,
          });
        });
      }
    }
  }

  prepareCopyDataForm = (copyData) => {
    this.callDetailsApi(copyData.id, (data, itemsInTheJob) => {
      /* VENDOR */
      let vendorItems = [];
      let vendorItem = null;
      if(data && data.vendor){
        vendorItem = {
          value: (data.vendor_id) ? data.vendor_id : '',
          label: (data.vendor.application_company && data.vendor.application_company.company_name && data.vendor.application_company.company_name !== '') ? data.vendor.application_company.company_name : '',
          item: (data.vendor) ? data.vendor : null,
        };
        vendorItems.push(vendorItem);
      }
      this.setState({
        job_type: (data && data.job_type) ? data.job_type : '',
        assign_type: (data && data.assign_type && data.assign_type === 'vendor') ? false : true,
        vendorNameValidated: false,
        vendorName: (vendorItem && vendorItem.label) ? vendorItem.label : '',
        vendorId: (vendorItem && vendorItem.value) ? vendorItem.value : '',
        vendorItem: (vendorItem && vendorItem.item) ? vendorItem.item : null,
        vendorItems: vendorItems,
      });
      /* END VENDOR */


      /* STATUS */
      this.setState({
        order_status: (data && data.order_status) ? data.order_status : null,
        order_status_id: (data && data.order_status_id) ? data.order_status_id : '',
      });
      /* END STATUS */

        
      /* JOB DETAILS SECTION */
      this.setState({
        drop_off_date: (data && data.drop_off_date && copyData.drop_off_date) ? moment(data.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '',
        drop_off_time_planned: (data && data.drop_off_time_planned && copyData.drop_off_time_planned) ? moment(data.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '',
        drop_off_description: (data && data.drop_off_description && copyData.drop_off_description) ? data.drop_off_description : '',
        driver_notes: (data && data.driver_notes) ? data.driver_notes : '',
      });
      /* END JOB DETAILS SECTION */

      if (this.isCustomerRequired) {
        /* CUSTOMERS DETAILS SECTION */
        let customerItems = [];
        let customerItem = null;
        if(data && data.customer){
          customerItem = {
            value: (data.customer_id) ? data.customer_id : '',
            label: (data.company_name) ? data.company_name : '',
            item: (data.customer) ? data.customer : null,
          };
          customerItems.push(customerItem);
        }

        this.setState({
          customerNameValidated: false,
          customerName: (customerItem && customerItem.label) ? customerItem.label : '',
          customerId: (customerItem && customerItem.value) ? customerItem.value : '',
          customerItem: (customerItem && customerItem.item) ? customerItem.item : null,
          customerItems: customerItems,
          amount: (data && data.amount && copyData.amount) ? data.amount : '',
          item_tracking_number: (data.item_tracking_number && copyData.item_tracking_number) ? data.item_tracking_number : '',
          is_tracking_link_required: (data.is_tracking_link_required) ? data.is_tracking_link_required : false,
          is_notify_customer: (data.is_notify_customer) ? data.is_notify_customer : false,
        }, () => {
          if(data && data.customer_id){
            this.callCustomerDetailsApi(this.state.customerId, (customerData) => {
              let customerAddresses = (customerData && customerData.customer_addresses && customerData.customer_addresses.length > 0) ? customerData.customer_addresses : [];
              let branchItems = customerAddresses.map((item, i) => {
                return {
                  value: item.id,
                  label: item.name,
                  item: item,
                }
              });

              let departmentItems = [];
              if(customerData && customerData.departments && customerData.departments.length > 0){
                departmentItems = customerData.departments.map((item, i) => {
                  return {
                    value: item.id,
                    label: item.name,
                    item: item,
                  }
                });
              }

              this.setState({
                branchItems: branchItems,
                departmentItems: departmentItems,
              }, () => {
                let drop_off_address = '';
                let order_branch_id = (data && data.order_branch_id) ? data.order_branch_id : '';
                let drop_off_addressItem = branchItems.filter(x => x.value === order_branch_id);
                if(drop_off_addressItem && drop_off_addressItem.length > 0){
                  drop_off_address = drop_off_addressItem[0].item.address_line1;
                }
                this.setState({
                  order_branch_id: order_branch_id,
                  drop_off_address: drop_off_address,
                });

                let departmentItem = null;
                let department_id = (data && data.department_id) ? data.department_id : '';
                let departmentArray = (departmentItems && departmentItems.length > 0) ? departmentItems.filter(x => x.value === department_id) : [];
                if(departmentArray && departmentArray.length > 0){
                  departmentItem = departmentArray[0].item;
                }
                this.setState({
                  department_id: department_id,
                  departmentItem: departmentItem,
                });
              });
            });
          }
        });

        let picItems = [];
        let drop_off_name = [];
        if(data.drop_off_name && data.drop_off_name !== ''){
          drop_off_name = data.drop_off_name.split(',');
        }

        let drop_off_contact_no = [];
        if(data.drop_off_contact_no && data.drop_off_contact_no !== ''){
          drop_off_contact_no = data.drop_off_contact_no.split(',');
        }

        let notification_email = [];
        if(data.notification_email && data.notification_email !== ''){
          notification_email = data.notification_email.split(',');
        }

        if (drop_off_name && drop_off_name.length > 0) {
          for(let i = 0; i < drop_off_name.length; i++){
            let name = '';
            let contact_no = '';
            let email = '';

            if(drop_off_name[i] && drop_off_name[i] !== '' && copyData.drop_off_name){
              name = drop_off_name[i];
            }
            if(drop_off_contact_no[i] && drop_off_contact_no[i] !== ''){
              contact_no = drop_off_contact_no[i];
            }

            if(notification_email[i] && notification_email[i] !== '' && copyData.drop_off_contact_email){
              email = notification_email[i];
            }

            picItems.push({
              uuid: uuid(),
              drop_off_name: name,
              drop_off_contact_no: contact_no,
              notification_email: email,
              remove: false,
            });
          }
        }

        this.setState({
          picItems: picItems,
        });
        /* END CUSTOMERS DETAILS SECTION */
      } else {
        /* END RECIPIENT DETAILS SECTION */
        this.setState({
          item_tracking_number: (data.item_tracking_number && copyData.item_tracking_number) ? data.item_tracking_number : '',
          is_tracking_link_required: (data.is_tracking_link_required) ? data.is_tracking_link_required : false,
          is_notify_customer: (data.is_notify_customer) ? data.is_notify_customer : false,
          drop_off_address: (data.drop_off_address) ? data.drop_off_address : '',
          drop_off_name: (data.drop_off_contact_name && copyData.drop_off_name) ? data.drop_off_contact_name : '',
          drop_off_contact_name: (data.drop_off_contact_name && copyData.drop_off_name) ? data.drop_off_contact_name : '',
          drop_off_contact_no: (data.drop_off_contact_no && copyData.drop_off_contact_no) ? data.drop_off_contact_no : '',
          notification_email: (data.notification_email && copyData.drop_off_contact_email) ? data.notification_email : '',
        });
        /* END RECIPIENT DETAILS SECTION */
      }
        
      /* ITEMS IN JOB LIST SECTION */
      if (copyData.order_details) {
        let itemsInJobList = [];
        if(itemsInTheJob && itemsInTheJob.length > 0){
          itemsInJobList = itemsInTheJob.map((item, i) => {
            return {
              uuid: uuid(),
              id: item.id,
              description: item.description,
              quantity: item.quantity,
              uom: item.uom,
              weight: item.weight,
              remarks: item.remarks,
              remove: false,
            }
          });
        }
        this.setState({
          itemsInJobList: itemsInJobList,
        });
      }
      /* END ITEMS IN JOB LIST SECTION */
        

      /* STEPS SECTION */
      if (copyData.job_steps) {
        let job_steps = [];
        if(data && data.job_steps && data.job_steps.length > 0){
          job_steps = data.job_steps.map((item, i) => {
            let orderStatusItem = apiUtil.customFilter(this.state.jobStepStatuses, 'value', item.job_step_status_id);
            let job_step_status = (orderStatusItem) ? orderStatusItem : null;

            return {
              uuid: uuid(),
              id: item.id,
              job_step_id: item.id,
              job_step_name: item.job_step_name,
              job_step_status_id: item.job_step_status_id,
              job_step_status: job_step_status,
              location: item.location,
              is_signature_required: item.is_signature_required,
              is_scan_required: item.is_scan_required,
              job_step_pic: item.job_step_pic,
              job_step_pic_contact: item.job_step_pic_contact,
              description: item.description,
              order_sequence: (i + 1),
              remove: false,
            }
          });
        }
        this.setState({
          job_steps: job_steps,
        });
      }
      /* END STEPS SECTION */

      if (copyData.drop_off_worker) {
        let manPowerItem = (data && data.extra_workers && data.extra_workers.length > 0) ? data.extra_workers[0] : null;
        this.setState({
          workerItem: data.drop_off_worker,
          workerName: data.drop_off_worker_name,
          workerId: data.drop_off_worker_id,
          manPowerItem: manPowerItem,
          manPowerName: (manPowerItem) ? ((manPowerItem.first_name && manPowerItem.first_name !== '') ? manPowerItem.first_name : '') + ' ' + ((manPowerItem.last_name && manPowerItem.last_name !== '') ? manPowerItem.last_name : '') : '',
          manPowerId: (manPowerItem) ? manPowerItem.id : 0,
        });
      }
    });
  }

  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const { isOnBoarding, setOnBoardingJob } = this.context;
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;

    this.setState({
      isFormChanged: false,
      drop_off_dateValidated: false,
      // drop_off_time_plannedValidated: false,
      drop_off_descriptionValidated: false,
      drop_off_contact_nameValidated: false,
      drop_off_addressValidated: false,
      // amountValidated: false,
      customerNameValidated: false,
      vendorNameValidated: false,
      drop_off_contact_noValidated: false,
      notification_emailValidated: false,
    });
    
    if(this.state.drop_off_date === ''){
      this.setState({
        drop_off_dateValidated: true
      });
    }
    // if(this.state.drop_off_time_planned === ''){
    //   this.setState({
    //     drop_off_time_plannedValidated: true
    //   });
    // }
    if(this.state.drop_off_description === ''){
      this.setState({
        drop_off_descriptionValidated: true
      });
    }
    if(this.state.drop_off_contact_name === ''){
      this.setState({
        drop_off_contact_nameValidated: true
      });
    }
    // if(this.state.drop_off_address === ''){
      // this.setState({
      //   drop_off_addressValidated: false
      // });
    // }
    // if(this.state.amount === ''){
    //   this.setState({
    //     amountValidated: true
    //   });
    // }
    
    if(this.state.vendorName === ''){
      this.setState({
        vendorNameValidated: true
      });
    }

    
    if(this.isCustomerRequired){
      if(this.state.customerName === ''){
        this.setState({
          customerNameValidated: true
        });
      }
    } else {
      if(this.state.drop_off_name === ''){
        this.setState({
          drop_off_nameValidated: true
        });
      }
      if(this.state.drop_off_contact_name === ''){
        this.setState({
          drop_off_contact_nameValidated: true
        });
      }
      if(this.state.notification_email === ''){
        this.setState({
          notification_emailValidated: true
        });
      }
      if(this.state.drop_off_contact_no === ''){
        this.setState({
          drop_off_contact_noValidated: true
        });
      }
    }



    // drop_off_contact_name notification_email drop_off_contact_no 
    if (form.checkValidity() === true) {
      let drop_off_name = '';
      let drop_off_contact_no = '';
      let notification_email = '';
      
      if(this.isCustomerRequired){
        if(this.state.picItems && this.state.picItems.length > 0){
          drop_off_name = this.state.picItems.filter(x => !x.remove).map((item, i) => {
            return item.drop_off_name;
          }).join(',');

          drop_off_contact_no = this.state.picItems.filter(x => !x.remove).map((item, i) => {
            return item.drop_off_contact_no;
          }).join(',');

          notification_email = this.state.picItems.filter(x => !x.remove).map((item, i) => {
            return item.notification_email;
          }).join(',');
        }
      } else {
        drop_off_name = this.state.drop_off_name;
        drop_off_contact_no = this.state.drop_off_contact_no;
        notification_email = this.state.notification_email;
      }
      

      let order_details = [];
      if(this.state.itemsInJobList && this.state.itemsInJobList.length > 0){
        order_details = this.state.itemsInJobList.filter(x => !x.remove).map((item, i) => {
          delete item.uuid;
          delete item.remove;

          return item;
        });
      }
      

      let job_steps = [];
      if(this.state.job_steps && this.state.job_steps.length > 0){
        job_steps = this.state.job_steps.map((item, i) => {
          delete item.uuid;
          delete item.job_step_status;

          return item;
        });
      }
      
      
      let departmentItem = (this.state.departmentItem && this.state.departmentItem.item) ? this.state.departmentItem.item : null;
      if(departmentItem){
        delete departmentItem['created_at'];
        delete departmentItem['updated_at'];
        delete departmentItem['application_company_id'];

        if(departmentItem.contact_people && departmentItem.contact_people.length > 0){
          departmentItem.contact_people = departmentItem.contact_people.filter(x => !x.remove).map((item, i) => {
            delete item['created_at'];
            delete item['updated_at'];
  
            return item;
          });
        }
      }

      let drop_off_date = moment(this.state.drop_off_date);
      let drop_off_time_planned = "";
      if(this.state.drop_off_time_planned && this.state.drop_off_time_planned !== ''){
        drop_off_time_planned = moment(drop_off_date.format(apiUtil.getDefaultDateFormat()) + ' ' + this.state.drop_off_time_planned).local().format('YYYY-MM-DD HH:mm:ss');
      }

      let data = {
        order_details: order_details,
        job_steps: job_steps,
        driver_notes: this.state.driver_notes,
        job_type: this.state.job_type,
        is_notify_customer: this.state.is_notify_customer,
        drop_off_date: this.state.drop_off_date,
        drop_off_time_planned: drop_off_time_planned,
        drop_off_description: this.state.drop_off_description,
        customer_id: this.state.customerId,
        drop_off_contact_name: (this.isCustomerRequired) ? this.state.customerName : this.state.drop_off_contact_name,
        department_id: this.state.department_id,
        notification_email: notification_email,
        drop_off_contact_no: drop_off_contact_no,
        drop_off_name: drop_off_name,
        order_branch_id: this.state.order_branch_id,
        drop_off_address: this.state.drop_off_address,
        amount: this.state.amount,
        item_tracking_number: this.state.item_tracking_number,
        is_tracking_link_required: this.state.is_tracking_link_required,
        order_status_id: this.state.order_status_id,
        total_package: (this.state.itemsInJobList && this.state.itemsInJobList.length > 0) ? this.state.itemsInJobList.reduce((total, x) => parseFloat(total) + parseFloat(x.quantity), 0) : 0,
        total_kg: (this.state.itemsInJobList && this.state.itemsInJobList.length > 0) ? this.state.itemsInJobList.reduce((total, x) => parseFloat(total) + parseFloat(x.weight), 0) : 0,
      }

      if(this.state.assign_type){
        data['assign_type'] = "own";
        data['drop_off_worker_id'] = this.state.workerId;
        data['driver_name'] = this.state.workerName;
        data['extra_worker_ids'] = this.state.manPowerId.toString();
        data['manpower_name'] = this.state.manPowerName;
      } else {
        data['assign_type'] = "vendor";
        data['vendor_id'] = this.state.vendorId;
        data['assigned_vendor'] = this.state.vendorName;
      }
      
      if(this.state.id && this.state.id > 0){
        this.callUpdateApi(this.state.id, data, () => {
          this.setState({
            isLoading: false
          }, () => {
            this.props.history.push({
              pathname: '/job-list',
              state: {
                isShouldDisableSocketToast: true
              }
            });
            apiUtil.toast('Successfully Updated', 'check_circle');
          });
        });
      } else {
        if(!isOnBoarding){
          this.callCreateApi(data, () => {
            this.setState({
              isLoading: false
            }, () => {
              this.props.history.push({
                pathname: '/job-list',
                state: {
                  isShouldDisableSocketToast: true
                }
              });
              apiUtil.toast('Successfully Created', 'check_circle');
            });
          });
        } else {
          setOnBoardingJob(JSON.stringify(data));
          this.props.history.push('/onboarding-complete');
          apiUtil.toastOnBoarding('Successfully Created', 'check_circle');
        }
      }
    } else {
      this.setState({
        isLoading: false
      });
    }
  }
  /* END SUBMIT */


  /* DIALOG */
  customerDialog = () => {
    return <CustomFormDialog 
        open={this.state.openDialogCustomer}
        title={'Create New Customer'}
        onClose={() => {
          this.setState({
              openDialogCustomer: false,
              itemDialogCustomer: null,
          });
        }}
    >
        <CustomerForm
          item={this.state.itemDialogCustomer}
          onSave={(isEdit, data) => {
              if(!isEdit) {
                /* ADD NEW CUSTOMER DETAILS */
                let customerItems = this.state.customerItems;
                let customerItem = null;
                if(data && data.customer){
                  customerItem = {
                    value: (data.customer.id) ? data.customer.id : '',
                    label: (data.customer.company_name) ? data.customer.company_name : '',
                    item: (data.customer) ? data.customer : null,
                  };
                  customerItems.push(customerItem);
                }

                this.setState({
                  customerNameValidated: false,
                  customerName: (customerItem && customerItem.label) ? customerItem.label : '',
                  customerId: (customerItem && customerItem.value) ? customerItem.value : '',
                  customerItem: (customerItem && customerItem.item) ? customerItem.item : null,
                  customerItems: customerItems,
                }, () => {
                  if(data && data.customer && data.customer.id){
                    this.callCustomerDetailsApi(this.state.customerId, (customerData) => {
                      let customerAddresses = (customerData && customerData.customer_addresses && customerData.customer_addresses.length > 0) ? customerData.customer_addresses : [];
                      let branchItems = customerAddresses.map((item, i) => {
                        return {
                          value: item.id,
                          label: item.name,
                          item: item,
                        }
                      });

                      let departmentItems = [];
                      if(customerData && customerData.departments && customerData.departments.length > 0){
                        departmentItems = customerData.departments.map((item, i) => {
                          return {
                            value: item.id,
                            label: item.name,
                            item: item,
                          }
                        });
                      }

                      this.setState({
                        branchItems: branchItems,
                        departmentItems: departmentItems,
                      }, () => {
                        let drop_off_address = '';
                        let order_branch_id = (data && data.customer && data.customer.customer_addresses && data.customer.customer_addresses[0] && data.customer.customer_addresses[0].id) ? data.customer.customer_addresses[0].id : '';
                        let drop_off_addressItem = branchItems.filter(x => x.value === order_branch_id);
                        if(drop_off_addressItem && drop_off_addressItem.length > 0){
                          drop_off_address = drop_off_addressItem[0].item.address_line1;
                        }
                        this.setState({
                          order_branch_id: order_branch_id,
                          drop_off_address: drop_off_address,
                        });
                        
                        let departmentItem = null;
                        let department_id = (data && data.customer && data.customer.departments && data.customer.departments[0] && data.customer.departments[0].id) ? data.customer.departments[0].id : '';
                        let departmentArray = (departmentItems && departmentItems.length > 0) ? departmentItems.filter(x => x.value === department_id) : [];
                        if(departmentArray && departmentArray.length > 0){
                          departmentItem = departmentArray[0].item;
                        }
                        this.setState({
                          department_id: department_id,
                          departmentItem: departmentItem,
                        });

                        let picItems = [];
                        if(departmentItem && departmentItem.contact_people && departmentItem.contact_people.length > 0){
                          let item = departmentItem.contact_people[0];

                          picItems.push({
                            uuid: uuid(),
                            id: item.id,
                            drop_off_name: item.first_name,
                            drop_off_contact_no: item.contact_no,
                            notification_email: item.email,
                            remove: false,
                          });
                        }

                        this.setState({
                          picItems: picItems,
                        });
                      });
                    });
                  }
                });
                /* END ADD NEW CUSTOMER DETAILS */


                this.setState({
                  openDialogCustomer: false,
                  itemDialogCustomer: null,
                });
              }
              
          }}
          onClose={() => {
              this.setState({
                  openDialogCustomer: false,
                  itemDialogCustomer: null,
              });
          }}
        />
    </CustomFormDialog>
  }

  driverDialog = () => {
    return <CustomFormDialog 
        open={this.state.openDialogDriver}
        title={'Create New Driver'}
        onClose={() => {
          this.setState({
              openDialogDriver: false,
              itemDialogDriver: null,
          });
        }}
    >
        <DriverForm
          item={this.state.itemDialogDriver}
          onSave={(isEdit, data) => {
              if(!isEdit) {
                /* ADD NEW DRIVER */
                let workerItems = this.state.workerItems;
                let workerItem = null;
                if(data && data.result){
                  let value = data.result.id;
                  let label = ((data.result.first_name && data.result.first_name !== '') ? data.result.first_name : '') + ' ' + ((data.result.last_name && data.result.last_name !== '') ? data.result.last_name : '');

                  workerItem = {
                    value: value,
                    label: label,
                    item: data.result,
                  };
                  workerItems.push(workerItem);
                }

                this.setState({
                  workerItems: workerItems,
                  workerName: workerItem.label,
                  workerId: workerItem.value,
                  workerItem: workerItem.item
                }, () => {
                  let notAssignedStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'not assigned');
                  let assignedStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'assigned');

                  if(assignedStatusItem && notAssignedStatusItem){
                    if(this.state.order_status_id === notAssignedStatusItem.value){
                      this.setState({
                        order_status: assignedStatusItem.item,
                        order_status_id: assignedStatusItem.value,
                      });
                    }
                  }
                });
                /* END ADD NEW DRIVER */
                  
                this.setState({
                  openDialogDriver: false,
                  itemDialogDriver: null,
                });
              }
              
          }}
          onClose={() => {
              this.setState({
                  openDialogDriver: false,
                  itemDialogDriver: null,
              });
          }}
        />
    </CustomFormDialog>
  }

  logisticsAssistanceDialog = () => {
    return <CustomFormDialog 
        open={this.state.openDialogLogisticsAssistance}
        title={'Create Logistics Assistance'}
        onClose={() => {
          this.setState({
              openDialogLogisticsAssistance: false,
              itemDialogLogisticsAssistance: null,
          });
        }}
    >
        <LogisticsAssistanceForm
          item={this.state.itemDialogLogisticsAssistance}
          onSave={(isEdit, data) => {
              if(!isEdit) {
                /* ADD NEW LOGISTIC ASSISTANCE */
                let manPowerItems = this.state.manPowerItems;
                let manPowerItem = null;
                if(data && data.result){
                  let value = data.result.id;
                  let label = ((data.result.first_name && data.result.first_name !== '') ? data.result.first_name : '') + ' ' + ((data.result.last_name && data.result.last_name !== '') ? data.result.last_name : '');

                  manPowerItem = {
                    value: value,
                    label: label,
                    item: data.result,
                  };
                  manPowerItems.push(manPowerItem);
                }

                this.setState({
                  manPowerItems: manPowerItems,
                  manPowerName: manPowerItem.label,
                  manPowerId: manPowerItem.value,
                  manPowerItem: manPowerItem.item
                });
                /* END ADD NEW LOGISTIC ASSISTANCE */
                  
                this.setState({
                  openDialogLogisticsAssistance: false,
                  itemDialogLogisticsAssistance: null,
                });
              }
              
          }}
          onClose={() => {
              this.setState({
                  openDialogLogisticsAssistance: false,
                  itemDialogLogisticsAssistance: null,
              });
          }}
        />
    </CustomFormDialog>
  }
  
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Confirm'}
      btnCloseText={'No'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        }, () => {
          this.setState({
            branchItems: [],
            departmentItems: [],
          });
        });
      }}
      onOk={() => {
        let data = this.state.openDialogItem;
        this.setState({
          openDialog: false,
          openDialogItem: null,
        }, () => {
          this.addNewCustomerDetails(data);
        });
      }}
    >
      <Box>Clear existing job information?</Box>
    </CustomDialog>
  }
  /* END DIALOG */


  /* PREVENT TRANSITION PROMPT */
  preventTransitionPrompt = () => {
    const { isOnBoarding } = this.context;

    if(!isOnBoarding){
      return <PreventTransitionPrompt
        when={this.state.isFormChanged}
        title="Any you sure want to leave this page?"
        message={'Any unsaved changes will be lost.'}
        onOk={(location) => {
          this.setState({
            isFormChanged: false
          }, () => {
            this.props.history.push(location.pathname);
          });
        }}
      />
    }
  }
  /* END PREVENT TRANSITION PROMPT */


  /* STEPPER */
  setStepper = () => {
    return <>
      <CustomGuideStepper
        activeStep={3}
        steps={[
          {
            label: 'Create Customer',
          },
          {
            label: 'Create Vehicle',
          },
          {
            label: 'Create Operator',
          },
          {
            label: 'Create Job',
          },
        ]}
        onClick={(index) => {
          if(index === 0){
            this.props.history.push('/customers');
          } else if(index === 1){
            this.props.history.push('/resources/vehicle');
          } else if(index === 2){
            this.props.history.push('/resources/transport-operator');
          } else if(index === 3){
            this.props.history.push('/job-list');
          }
        }}
      />
      <Box pb={2} textAlign={'center'}>Fill in the form and create a Job.</Box>
      <Box textAlign={'center'}>
        <CustomButton 
          color={'primary'}
          href={'/'}
          onClick={(e) => {
              e.preventDefault();

              this.setState({
                openSkipDialog: true,
              });
          }}
        >
          Skip All
        </CustomButton>
      </Box>
    </>
  }
  /* END STEPPER */

  /* TOUR */
  setTour = () => {
    return <>
      <Tour
        steps={[
          {
            selector: '.job-details-step',
            content: <Box>
              <h2>Job Details</h2>
              <p>The fields here are the details required for a job.You can customize these later.</p>
            </Box>,
          },
          {
            selector: '.job-steps-step',
            content: <Box>
              <h2>Job Steps</h2>
              <p>Need some checkpoints for your driver before the end address? You can set it here.The last stop's address is your receipient's address.</p>
            </Box>,
          },
          {
            selector: '.create-job-step',
            stepInteraction: true,
            content: <Box>
              <h2>Create Job</h2>
              <p>Fill in the required data to create a job.</p>
            </Box>,
          },
        ]}
        isOpen={this.state.isTourOpen}
        rounded={10}
        accentColor={'#64CCC9'}
        badgeContent={'#64CCC9'}
        showNumber={false}
        showNavigation={false}
        disableInteraction={true}
        // prevButton={<></>}
        // nextButton={<></>}
        lastStepNextButton={<></>}
        onRequestClose={() => {
          this.setState({
            isTourOpen: false,
          });
        }} />
    </>
  }
  /* END TOUR */

  /* SKIP DIALOG */
  skipDialog = () => {
    const { accessToken, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = this.context;

    return <CustomDialog 
      open={this.state.openSkipDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        this.setState({
          openSkipDialog: false,
        });
      }}
      onOk={() => {
        apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
          this.setState({
            openSkipDialog: false,
          }, () => {
            setOnBoardingCustomer(null);
            setOnBoardingVehicle(null);
            setOnBoardingDriver(null);
            setOnBoardingJob(null);

            apiUtil.toastOnBoarding('Done', 'check_circle');
            this.props.history.push('/');
          });
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END SKIP DIALOG */


  render() {
    return <Box className="job-form-page" >
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <StickyPageHeader isSmall={true}>
                {this.customStickyPageHeader()}
            </StickyPageHeader>
            
            <Container maxWidth="lg">
                {
                  (!this.state.isLoading)
                  ?
                  <Card>
                    {this.jobDetailsForm()}
                    {this.customersDetailsForm()}
                    {this.personInChargeForm()}
                    {this.itemsInJobForm()}
                    {this.jobStepForm()}
                    {this.state.grantedAccess.isShouldShowJobAccess(this.state.currentRole, "job_assign") && this.state.assign_type && this.assignWorkersForm()}
                  </Card>
                  :
                  <Card>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                      <CircularProgress size={35} />
                    </Box>
                  </Card>
                }
            </Container>
            
        </form>
        {this.customerDialog()}
        {this.driverDialog()}
        {this.logisticsAssistanceDialog()}
        {this.customDialog()}
        {this.setTour()}
        {this.skipDialog()}
        {this.preventTransitionPrompt()}
    </Box>
  }
}
