import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import api from "../../../api/api.jsx";

import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';

export class VehicleForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let item = (props.item) ? props.item : null;
    
    let plate_no = (item && item.plate_no) ? item.plate_no : '';
    let manufacturer = (item && item.manufacturer) ? item.manufacturer : '';
    let model = (item && item.model) ? item.model : '';
    

    this.state = {
        isLoading: isLoading,
        item: item,

        plate_no: plate_no,
        plate_noValidated: false,
        manufacturer: manufacturer,
        manufacturerValidated: false,
        model: model,
        modelValidated: false,
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.item !== this.state.item) {
        let item = (nextProps.item) ? nextProps.item : null;
        this.setState({
            item: item
        });
    }
  }


  /* API */
  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
        api.create('vehicles', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            // if(error && error.email && error.email.length > 0){
            //     apiUtil.toast(error.email[0], 'warning', 'error');
            // } else if(error && error !== ''){
            //     apiUtil.toast(error, 'warning', 'error');
            // }
            
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    <CustomLabel
                        label={'Transport Operator Information'}
                        weight={'bold'}
                        size={'md'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Plate Number'}
                        placeholder={'Enter vehicle plate number'}
                        value={this.state.plate_no}
                        error={this.state.plate_noValidated}
                        required={true}
                        autoFocus={true}
                        onChange={(e) => {
                            this.setState({
                                plate_no: e.target.value,
                                plate_noValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Manufacturer'}
                        placeholder={'Enter vehicle manufacturer'}
                        value={this.state.manufacturer}
                        error={this.state.manufacturerValidated}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                manufacturer: e.target.value,
                                manufacturerValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Model'}
                        placeholder={'Enter vehicle Model name'}
                        value={this.state.model}
                        error={this.state.modelValidated}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                model: e.target.value,
                                modelValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        {(this.state.item ? 'Edit Vehicle' : 'Add Vehicle')}
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;

    this.setState({
        plate_noValidated: false,
        manufacturerValidated: false,
        modelValidated: false,
    });
    
    if(this.state.plate_no === ''){
      this.setState({
        plate_noValidated: true
      });
    }
    if(this.state.manufacturer === ''){
      this.setState({
        manufacturerValidated: true
      });
    }
    if(this.state.model === ''){
      this.setState({
        modelValidated: true
      });
    }

    if (form.checkValidity() === true) {
        this.callCreateApi(this.getForm(), (data) => {
            if(data){
                if(this.props.onSave){
                    this.props.onSave((this.state.item ? true : false), data);
                }
            }
        });
        
    } else {
        this.setState({
            isLoading: false
        });
    }
  }

  
  getForm = () => {
    let data = {
        plate_no: this.state.plate_no,
        manufacturer: this.state.manufacturer,
        model: this.state.model,
    }

    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default VehicleForm;