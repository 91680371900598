import React, { Component } from 'react';
import LinesEllipsis from 'react-lines-ellipsis'
 

class CustomReadMore extends Component {
    constructor(props) {
        super(props);
        
        let className = (props.className) ? props.className : '';
        let text = (props.text) ? props.text : '';
        let maxLine = (props.maxLine) ? props.maxLine : 3;

        this.state = {
            className: className,
            text: text,
            maxLine: maxLine,
            maxLineDefault: maxLine,
        };
    }
 
 
    toggle = () => {
        this.setState({
            maxLine: (this.state.maxLine === this.state.maxLineDefault) ? 50 : this.state.maxLineDefault
        });
    }

    toggleLines = (e) => {
        e.preventDefault();
        this.toggle();
    }
    
    render() {
        return (
            <div>
                {
                    (this.state.maxLine === this.state.maxLineDefault)
                    ?
                    <LinesEllipsis
                        className={this.state.className}
                        text={this.state.text}
                        maxLine={this.state.maxLine}
                        ellipsis={<span>... <a href='/' className={'primary-link'} onClick={this.toggleLines}>Read more</a></span>}
                        basedOn='letters'
                    />
                    :
                    <span><span className={this.state.className}>{this.state.text}</span> <a href='/' className={'primary-link'} onClick={this.toggleLines}>Read less</a></span>
                }
            </div>
        );
    }
}

export default CustomReadMore;