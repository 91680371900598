import React, { Component } from 'react';
import uuid from 'react-uuid'

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";

import { 
  Box,
  Grid,
  Divider,
  IconButton,
  Tooltip,
  Hidden,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';

import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';

import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CustomFormDialog from '../../../components/Dialog/CustomFormDialog.jsx';

import DepartmentPICForm from './DepartmentPICForm.jsx';


export class DepartmentPICList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let items = (props.items && props.items.length > 0) ? props.items : [];

    this.state = {
      openDialogEdit: false,
      itemDialogEdit: null,

      openDialogDelete: false,
      itemDialogDelete: null,

      contact_people: items,
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.state.items) {
        let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
        this.setState({
            contact_people: items
        });
    }
  }


  /* FUNCTIONS */
  addNew = (form = null, callback = null) => {
    let contact_people = this.state.contact_people;

    if(form) {
        form['uuid'] = uuid();
        form['remove'] = false;
        contact_people.push(form);
    } else {
        contact_people.push({
            uuid: uuid(),
            drop_off_name: '',
            notification_email: '',
            drop_off_contact_no: '',
            remove: false,
        });
    }
    
    this.setState({
        contact_people: contact_people
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onAddNew){
            this.props.onAddNew(this.state.contact_people);
        }
    });
  }

  edit = (item = null, form = null, callback = null) => {
    let contact_people = this.state.contact_people;

    let editIndex = contact_people.findIndex(e => e.uuid === item.uuid);
    if(editIndex !== -1){
        let oldForm = contact_people[editIndex];

        form['uuid'] = item.uuid;
        form['remove'] = false;
        contact_people[editIndex] = form;

        oldForm['remove'] = true;
        contact_people.push(oldForm);
    }
    
    this.setState({
        contact_people: contact_people
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onEdit){
            this.props.onEdit(this.state.contact_people);
        }
    });
  }

  remove = (uuid = '', callback = null) => {
    let contact_people = this.state.contact_people;

    let index = contact_people.findIndex(e => e.uuid === uuid);
    if(index !== -1){
        contact_people[index]['remove'] = true;
    }
    
    this.setState({
        contact_people: contact_people
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onRemove){
            this.props.onRemove(this.state.contact_people);
        }
    });
  }
  /* END FUNCTIONS */


  /* DIALOG */
  editDialog = () => {
      return <CustomFormDialog 
          open={this.state.openDialogEdit}
          title={((this.state.itemDialogEdit !== null) ? 'Edit PIC' : 'Add PIC')}
          onClose={() => {
            this.setState({
                openDialogEdit: false,
                itemDialogEdit: null,
            });
          }}
      >
          <DepartmentPICForm
            item={this.state.itemDialogEdit}
            onSave={(isEdit, form) => {
                let item = this.state.itemDialogEdit;

                if(isEdit) {
                    this.edit(item, form, () => {
                        this.setState({
                            openDialogEdit: false,
                            itemDialogEdit: null,
                        });
                    });
                } else {
                    this.addNew(form, () => {
                        this.setState({
                            openDialogEdit: false,
                            itemDialogEdit: null,
                        });
                    });
                }
                
            }}
            onClose={() => {
                this.setState({
                    openDialogEdit: false,
                    itemDialogEdit: null,
                });
            }}
          />
      </CustomFormDialog>
  }

  deleteDialog = () => {
      return <CustomDialog 
          open={this.state.openDialogDelete}
          title={'Delete'}
          onClose={() => {
            this.setState({
                openDialogDelete: false,
                itemDialogDelete: null,
            });
          }}
          onOk={() => {
              let item = this.state.itemDialogDelete;
              this.remove(item.uuid, () => {
                this.setState({
                    openDialogDelete: false,
                    itemDialogDelete: null,
                });
              });
          }}
      >
          <Box>Are you sure you want to delete?</Box>
      </CustomDialog>
  }
  /* END DIALOG */


  setHeader = () => {
    return <Box pt={1} pb={1} className={'custom-grid-header'}>
        <Grid container alignItems={'center'}>
            <Box clone pl={{ xs: 0, md: 2}}>
                <Grid item xs={12} md={3}>
                    <CustomLabel
                        label={'Name'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} md={3}>
                    <CustomLabel
                        label={'Email'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} md={3}>
                    <CustomLabel
                        label={'Phone No.'}
                    />
                </Grid>
            </Box>
            <Box clone pr={{ xs: 0, md: 2}} textAlign={'right'}>
                <Grid item xs={12} md={3}>
                    <CustomLabel
                        label={'Actions'}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>
  }


  render() {
    return <Box pt={5} pb={5}>
        <Grid container>
            <Box clone pb={1}>
                <Grid item xs={12}>
                    <CustomLabel
                        label={'Person In Charge'}
                        weight={'bold'}
                        size={'md'}
                    />
                    <Divider light />
                </Grid>
            </Box>
            <Grid item xs={12}>
                <Hidden xsDown implementation="css">
                    {this.setHeader()}
                </Hidden>
                <Box>
                    {
                        (this.state.contact_people && this.state.contact_people.length > 0 && this.state.contact_people.filter(x => !x.remove).length > 0)
                        ?
                        this.state.contact_people.filter(x => !x.remove).map((item, i) => {
                            return <Box key={i} pt={1} pb={1}>
                                <Grid container alignItems={'center'}>
                                    <Box clone pl={{ xs: 0, md: 2}}>
                                        <Grid item xs={12} md={3}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Name'} placement={'top'}>
                                                        <Box>{item.drop_off_name}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={3}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Email'} placement={'top'}>
                                                        <Box>{item.notification_email}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={3}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Phone No.'} placement={'top'}>
                                                        <Box>{item.drop_off_contact_no}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone pr={{ xs: 0, md: 2}}>
                                        <Grid item xs={12} md={3}>
                                            <Grid container justify={'flex-end'}>
                                                <Box clone>
                                                    <Grid item xs={'auto'}>
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    openDialogEdit: true,
                                                                    itemDialogEdit: item,
                                                                });
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Box>
                                                <Box clone>
                                                    <Grid item xs={'auto'}>
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    openDialogDelete: true,
                                                                    itemDialogDelete: item,
                                                                });
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Divider light />
                            </Box>
                        })
                        :
                        <Grid container>
                            <Box clone pt={3} textAlign={'center'}>
                                <Grid item xs={12}>
                                    <CustomLabel
                                        label={'No Results!'}
                                        weight={'bold'}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    }

                    <Grid container>
                        <Box clone>
                            <Grid item xs={12}>
                                <CustomButton 
                                    color={'secondary'}
                                    href={'/'}
                                    onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                            openDialogEdit: true,
                                            itemDialogEdit: null,
                                        });
                                    }}
                                >
                                    <Icon component={'i'}>add</Icon>
                                    <Box display={'inline-block'} pl={1}>Add PIC</Box>
                                </CustomButton>
                            </Grid>
                        </Box>
                    </Grid>

                </Box>
            </Grid>
        </Grid>
        {this.editDialog()}
        {this.deleteDialog()}
    </Box>
  }
}

export default DepartmentPICList;