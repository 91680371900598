import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import moment from 'moment';
import jsPDF from "jspdf";
import QRCode  from "qrcode.react";
import LDSIcon from '../../assets/img/services-icon2.png';
import ReactDOM from 'react-dom';
import Barcode from 'react-barcode';

import { 
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';

import { ReactComponent as JobsIcon } from '../../assets/img/left_menu/jobs.svg';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import { ReactComponent as ImportIcon } from '../../assets/img/icons/import.svg';
import { ReactComponent as CopyIcon } from '../../assets/img/icons/copy.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import { ReactComponent as AutoIcon } from '../../assets/img/icons/flash.svg';
import { ReactComponent as LabelIcon } from '../../assets/img/icons/view_week.svg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';

import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import CustomFormDialog from '../../components/Dialog/CustomFormDialog.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CircleIcon from '../../components/CircleIcon/CircleIcon.jsx';
import StatusList from '../../components/Status/StatusList.jsx';
import StatusItem from '../../components/Status/StatusItem.jsx';
import DropdownMenu from '../../components/Dropdown/DropdownMenu.jsx';
import CustomAutosuggest from '../../components/CustomFields/CustomAutosuggest.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';
import CustomGuideStepper from '../../components/Stepper/CustomGuideStepper.jsx';

import JobPreviewDialog from './Partial/JobPreviewDialog.jsx';
import JobImportDialog from './Partial/JobImportDialog.jsx';
import BatchUpdateForm from './Partial/BatchUpdateForm.jsx';
import AutoAssignForm from './Partial/AutoAssignForm.jsx';

import socketIOClient from "socket.io-client";
import { Observable } from 'rxjs/Observable';
import {RoleAccessService} from '../../data/role-access'; 
import Tour from 'reactour'


const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;

export class JobList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.pageName = 'jobList';

    let initStatusSelected = (this.props.location && this.props.location.state && this.props.location.state.index && this.props.location.state.index > 0) ? this.props.location.state.index : null;
    let initOrderStatus = (this.props.location && this.props.location.state && this.props.location.state.order_status && this.props.location.state.order_status > 0) ? this.props.location.state.order_status : null;
    let isShouldDisableSocketToast = (this.props.location && this.props.location.state && this.props.location.state.isShouldDisableSocketToast && this.props.location.state.isShouldDisableSocketToast === true) ? this.props.location.state.isShouldDisableSocketToast: false;
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let rangeDate = {
      startDate: moment(),
      endDate: moment(),
    };

    let rangeDateItem = localStorage.getItem(this.pageName + '_rangeDate');
    if(rangeDateItem && rangeDateItem !== ''){
      try {
        rangeDate = JSON.parse(rangeDateItem);
      }catch(e){}
    }

    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    // const grantedAccess = new RoleAccessService();
    // const currentRole = window.localStorage.getItem('current_role') || null;
    this.state = {
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      jobTab: 0,
      jobTabTable: 0,
      current_status_name: "",
      total_current_status_job: 0,
      dateRangePickerIsOpen: false,
      isShouldDisableSocketToast: isShouldDisableSocketToast,
      rangeDate: rangeDate,
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,

      isLoadingStatus: false,
      status: [],
      tableStatus: [],
      statusSelected: initStatusSelected,
      order_status: initOrderStatus,

      isLoading: false,
      grouping: true,
      limit: 20,
      data: [],
      total: 0,
      page: 1,
      selectedRow:[],
      openDialogBatchUpdate:false,
      openDialog: false,
      openDialogItem: null,
      openDialogAutoAssign:false,
      invalidAddress:[],

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,

      openSettingsDialog: false,

      defaultGroupOrder: -1,

      isTourOpen: false,
      openSkipDialog: false,

      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,

      copyDialogOpen: false,
      copyData: {
        id: 0,

        drop_off_date: true,
        drop_off_time_planned: true,
        drop_off_description: true,
        
        drop_off_name: true,
        drop_off_contact_email: true,
        drop_off_contact_no: true,
        item_tracking_number: true,

        order_details: true,
        job_steps: true,
        drop_off_worker: true,
        amount: true
      }
    }
  }
  

  componentDidMount() {
    const { isOnBoarding } = this.context;
    
    if(isOnBoarding){
      this.setState({
        isTourOpen: true,
      });
    }

    this.socketData();
    this.loadStatusApi(() => {
      this.callReadApi();
    });
  }


  callInitApis = () => {

    this.loadStatusApi();
    this.callReadApi();
  }

  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = data.map((item, i) => {
          return {
            id: item.status_details.id,
            status: item.status_details.status,
            text: item.status_details.status,
            total: item.total_job,
          };
        });
  
        let tableStatus = status;
        status.unshift({
          id: 0,
          status: 'Total Jobs',
          total: total,
        });
  
        this.setState({
          status: status,
          tableStatus: tableStatus,
        }, () => {
          if(callback) {
            callback();
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }

  /* SOCKET */
  getMessages() { 
    const { user } = this.context;

    let application_company_id = apiUtil.getAppCompanyId(user);

    let observable = new Observable(observer => {
      this.socket = socketIOClient(REACT_APP_JOB_UPDATE_SOCKET_URL);

      this.socket.on('connect', () => {
        console.log("SOCKET", 'connect', this.socket.connected);
      });

      this.socket.emit("join", "job_updated");

      this.socket.on(application_company_id.toString(), (data) => {
        observer.next(data);    
      });

      return () => {
        this.socket.disconnect();
      };  
    }) 
    return observable;
  }
  
  socketData(){
    this.connection = this.getMessages().subscribe(message => {
      console.log("connection message", message);

      this.dataMessage = message;
      this.dataMessage = this.dataMessage.order_history;
      if(this.dataMessage && !this.isArray(this.dataMessage)){
        let data = []; data.push(this.dataMessage);
        this.dataMessage= data;
      }
      if(this.dataMessage.length > 0){
        this.dataMessage.forEach(dataMessage=>{
          let actionText = ''
          let userName = ''
          if(dataMessage.admin_id){
            if(dataMessage.action === 'Created'){
              actionText = ' was created by ';
            }else if(dataMessage.action === 'Deleted Order'){
              actionText = ' was deleted by ';
            }else if(dataMessage.action === 'Updated'){
              if(dataMessage.attributes_updated && dataMessage.attributes_updated.includes('order_status_id') !== -1){
                actionText = ' status has been updated to ';
              }else{
                actionText = ' has been updated  by ';
              }
            }
            userName = dataMessage.admin.first_name

          }else if(dataMessage.worker_id){

            userName = dataMessage.worker.first_name
          }

          const icon =  dataMessage.admin_id ? "account_box" : "local_shipping";

          dataMessage.toShow = <div>
          <i className={'material-icons'}>{icon}</i>
          <b style={{marginRight:'5px'}}>{dataMessage.order_reference_no} </b>
          <span style={{marginRight:'5px'}}>{actionText}</span>
          {dataMessage.attributes_updated && dataMessage.attributes_updated.includes('order_status_id') !== -1 && 
          <b style={{marginRight:'5px'}}>{" " + dataMessage.current_status.status + " by "}</b>}
          <b>{userName}</b>
          </div>;
          if(this.state.isShouldDisableSocketToast === true){
            apiUtil.toast('', icon, '', 5000, dataMessage.toShow);
          }
          dataMessage.read_by_me = this.findRead(dataMessage.history_read_shown_markers);
          dataMessage.show_by_me = false;
          if(this.findNew(dataMessage.history_read_shown_markers)){ 
            dataMessage.show_by_me = true;
          }else{this.hadNew = true; }


          localStorage.setItem("doReload", JSON.stringify(dataMessage));
        });
      }
    });
  }
  

  isArray = (value) => {
    return value && typeof value === 'object' && value.constructor === Array;
  }

  findNew = (arrayList) => {
    let isNewItem = false;
    if(arrayList && arrayList.length > 0){
      arrayList.forEach(objItem=>{
        if(objItem.admin_id === localStorage.getItem("admin") && objItem.is_shown){
          isNewItem = true;
        }
      });
    }
    return isNewItem;
  }

  findRead = (arrayList) => {
    let isReadItem = false;
    if(arrayList && arrayList.length > 0){
      arrayList.forEach(objItem=>{
        if(objItem.admin_id === localStorage.getItem("admin") && objItem.is_read){
          isReadItem = true;
        }
      });
    }


    return isReadItem;
  }
  /* END SOCKET */


  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }
  
  callReadApi = (search = '') => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        page: this.state.page,
        take: this.state.limit,
        search: search,
        start_date: startDate,
        end_date: endDate,
        order_status: this.state.order_status,
      };
      // if(order)
      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              data: (data.result && data.result.length > 0) ? data.result : [],
              total: data.total,
              isLoading: false,
            });

            this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  callUpdateIsActiveApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('orders/' + row.id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        destroy_all: true,
      };

      ldsApi.delete('delete/orders/multiple?id=' + row.id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;

            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* WORKER API */
  callWorkerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      disabled: false,
      // take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search,
      is_truck_driver: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
    
            if(callback){
              callback(arr);
            }
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }

  callManpowerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      // take: apiUtil.getDefaultPageSize(),
      disabled: false,
      is_active: true,
      search: search,
      is_man_power: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        let result = (data.result && data.result.length > 0) ? data.result : [];

        let arr = [];
        if(result && result.length > 0){
          arr = result.map((item, i) => {
            let value = item.id;
            let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

            if(arr.findIndex(x => x.value === value) === -1){
              return {
                value: value,
                label: label,
                item: item
              }
            } else {
              return null;
            }
          });
  
          if(callback){
            callback(arr);
          }
        } else {
          if(callback){
            callback([]);
          }
        }
      }, (error, type) => {
        if(callback){
          callback([]);
        }
      });
    });
  }
  /* END WORKER API */


  /* EXPORT API */
  callExportXLSApi = () => {
    const { accessToken } = this.context;

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.exportXLS('admin/api/orders/delivery/export', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `Jobs ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.xls`);
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    });
  }
  /* END EXPORT API */


  /* IMPORT API */
  callImportApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('create/orders/multiple', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END IMPORT API */
 

  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    const { isOnBoarding } = this.context;

    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb xs={isOnBoarding ? 'auto' : true} />
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {this.setStepper()}
          </Grid>
        </Box>}
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              className={'select-dates-step'}
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Grid>
        </Box>
        <Box clone pr={1}>
          <Grid item xs={'auto'}>
            <CustomUploadButton 
              color={'primary'}
              accept={'.xls, .xlsx'}
              onChange={(files) => {
                if (files && files.length > 0){
                  this.setState({
                    openImportDialog: true,
                    openImportDialogItem: files[0],
                  });
                }
              }}
            >
              <ImportIcon />
            </CustomUploadButton>
          </Grid>
        </Box>
        <Box clone pr={1}>
          <Grid item xs={'auto'}>
            <CustomButton 
              className={'new-job-step'}
              color={'secondary'}
              onClick={() => {
                this.props.history.push('/jobs-form');
              }}
            >
              + New Jobs
            </CustomButton>
          </Grid>
        </Box> 
        { this.state.current_status_name === "Not Assigned" && moment(this.state.rangeDate.startDate).format("YYYY-MM-DD") === moment(this.state.rangeDate.endDate).format("YYYY-MM-DD") ?
          <Box clone>
            <Grid item xs={'auto'}>
              <CustomButton 
                color={'secondary'}
                onClick={() => {
                  this.props.history.push({
                    pathname: '/jobs/route-optimize',
                    state: {
                      total_current_status_job: this.state.total_current_status_job,
                      rangeDate: {
                        startDate: moment(this.state.rangeDate.startDate).format("YYYY-MM-DD"),
                        endDate: moment(this.state.rangeDate.endDate).format("YYYY-MM-DD")
                      }
                    }
                  });
                }}
              >
              Route Optimize
              </CustomButton>
            </Grid>
          </Box>: ""
        }
        
        <Box clone pt={{ xs: 4, md: 0 }}>
          <Grid item xs={12}>
            <GroupButton
              className={'head-tabs'}
              color={'secondary'}
              selected={this.state.jobTab}
              buttons={[ "Job List", "Job Schedule" ]}
              onClick={(selected, btn) => {
                if(selected === 1){
                  this.props.history.push('/job-schedule');
                }
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* STATUS */
  customStatus = () => {
    return <Grid container alignItems={'center'}>
      <Box clone mb={1}>
        <Grid item xs={'auto'}>
          <CircleIcon>
            <JobsIcon />
          </CircleIcon>
        </Grid>
      </Box>
      <Box clone pl={3} pr={3}>
        <Grid item xs={'auto'}>
          <CustomLabel
            className={'no-margin'}
            label={'Jobs List'}
            weight={'bold'}
            size={'md'}
          />
          <CustomLabel
            label={'Based on recent activities'}
            size={'xs'}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} lg={true}>
          <Box className={'custom-status-list-wrapper'}>
            <StatusList
              selected={(this.state.statusSelected > 0) ? this.state.statusSelected : 0}
              items={this.state.status.map((item, i) => {
                return <StatusItem
                  text={<Box>
                    <Icon component={'i'} className={'custom-dot status ' + (item.status.replace(' ', '-').trim().toLowerCase())}>lens</Icon>
                    <span>{item.status}</span>
                  </Box>}
                  count={item.total}
                />
              })}
              onClick={(index) => {
                let defaultGroupOrder = -1;
                if(index > 1){
                  defaultGroupOrder = 0;
                }

                this.setState({
                  statusSelected: index,
                  order_status: (index && index > 0) ? this.state.status[index].id : null,
                  current_status_name: (index && index > 0) ? this.state.status[index].status : null,
                  defaultGroupOrder: defaultGroupOrder,
                  total_current_status_job: (index && index > 0) ? this.state.status[index].total : 0,
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Box>
        </Grid>
      </Box>
    </Grid>
  }
  /* END STATUS */


  /* TABLE */
  customTable = () => {
    const { isCustomerRequired } = this.context;

    return <Table 
      ref={this.refTable}
      className={'job-table-list v-top'}
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      limit={this.state.limit}
      page={this.state.page}
      total={this.state.total}
      data={this.state.data}
      defaultExpanded={true}
      selection={true}
      onPageChange={(page) => {
        this.setState({
          page: page,
        }, () => {
          this.callReadApi(this.refTableSearch.current.state.value);
        });
      }}
      onSelectionChange={(rows)=>{
        this.setState({selectedRow:rows});
      }}
      Toolbar={this.customToolbar}
      // Row={this.customRow}
      columns={[
        { 
          title: "Job Number",
          width: '250px',
          field: "order_number",
          hidden: this.state.hiddenColumns.includes('order_number'),
          render: (row, type) => {
            
            if(type === 'row'){
              return <Box>
                {row.order_sequence !== null ?
                <span className="sequence-circle">{row.order_sequence}</span>: ""}
                <div className={row.order_sequence !== null ? "float-right" : ""}>
                  <Box pb={2}>
                    <CustomButton
                      className={'underline'}
                      color={'primary'}
                      href={'/'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        this.setState({
                          openPreviewDialog: true,
                          openPreviewDialogItem: row.id,
                        });
                      }}
                    >
                      {(row && row.order_number) ? row.order_number : ''}
                    </CustomButton>
                  </Box>
                  <Box>
                    <DropdownMenu 
                      isShouldEnable={this.state.grantedAccess.isShouldShowJobAccess(this.state.currentRole, 'job_status')}
                      className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '')}
                      text={(row && row.order_status && row.order_status.status) ? row.order_status.status : ''}
                      rightIcon={<ArrowDownIcon />}
                      items={this.state.tableStatus}
                      onClick={(item) => {
                        /*if(row.drop_off_worker_id && row.drop_off_worker_id > 0){*/
                          let data = {
                            data: [
                              {
                                id: [ row.id ],
                                job_steps: (row.job_steps && row.job_steps.length > 0) ? row.job_steps.map(step => {
                                  step.job_step_id = step.id;
                                  if (item.text.toString().toLowerCase().trim() === "completed") {
                                    step.job_step_status_id = 3;
                                  } else {
                                    step.job_step_status_id = 1;
                                  }
                                  return step
                                }): row.job_steps,
                                order_status_id: item.id,
                                send_notification_to_customer: true,
                              }
                            ]
                          };

                          if(item.text.toString().toLowerCase().trim() === 'not assigned'){
                            data.data[0]['drop_off_worker_id'] = null;
                            data.data[0]['extra_worker_ids'] = null;
                          }

                          this.callUpdateStatusApi(data, (newRow) => {
                            if(newRow){
                              let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                              this.setState({
                                data: newData,
                              });
                            }
                          });
                        /*} else {
                          apiUtil.toast('You must assign a driver!', 'warning', 'error'); 
                        }*/
                      }}
                    />          
                  </Box>
                </div>
              </Box>;
            } else {
              return row;
            }
          }
        },
        { 
          title: (isCustomerRequired) ? "Company" : 'Recipient', 
          field: (isCustomerRequired) ? "company_name" : 'drop_off_name',
          hidden: (this.state.hiddenColumns.includes('drop_off_contact_name') || this.state.hiddenColumns.includes('drop_off_name')),
          render: (row, type) => {
            let date = (row && row.drop_off_date && row.order_status) ? moment(row.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '';
            let time = (date && date !== '') ? (row && row.drop_off_time_planned && row.drop_off_time_planned) ? moment(row.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '' : '';
            
            let dateTime = '';
            if(date && time){
              dateTime = date + ' - ' + time;
            } else if (date){
              dateTime = date;
            } else if (time){
              dateTime = time;
            }

            if(type === 'row'){
              return <Box>
                <Box pb={2}>
                  {isCustomerRequired ? row.company_name : row.drop_off_name}
                </Box>
                <Box>
                  <Box>
                    {dateTime}
                  </Box>
                  <Box>{row.job_steps && row.job_steps.length > 0 ? row.job_steps[0].location : ''}</Box>
                  {row.amount !== null && <Box>Price: ${Number.parseFloat(row.amount).toLocaleString()}</Box>}
                </Box>
              </Box>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Description", 
          field: "drop_off_description",
          hidden: this.state.hiddenColumns.includes('drop_off_description'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Driver", 
          field: "drop_off_worker_name",
          hidden: this.state.hiddenColumns.includes('driver'),
          defaultGroupOrder: this.state.defaultGroupOrder,
          render: (row, type) => {
            if(type === 'row'){
              if(row.drop_off_worker_id && row.drop_off_worker_id > 0){
                if(!row.drop_off_worker.last_name){
                  row.workerName = row.drop_off_worker.first_name;
                }else{
                  row.workerName = row.drop_off_worker.first_name +" "+ row.drop_off_worker.last_name;
                }
              }

              return <Box>
                <CustomAutosuggest 
                  disabled = {this.state.grantedAccess.isShouldShowJobAccess(this.state.currentRole, 'job_status') == false ? true: false}
                  className={'no-margin'}
                  placeholder={'Assign Driver'}
                  value={row.workerName}
                  onChange={(value) => {
                    let status = '';
  
                    if(value){
                      row.workerName = value.label;
                      row.workerId = value.value;
                      row.workerItem = value.item;
  
                      status = 'assigned';
                    } else {
                      row.workerName = '';
                      row.workerId = null;
                      row.workerItem = null;
  
                      status = 'not assigned';
                    }

                    // let orderStatusItem = apiUtil.customFilter(this.state.status, 'job_assign', status);}
                    let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', status);
                    let data = {
                      data: [
                        {
                          id: [ row.id ],
                          job_steps: row.job_steps ? row.job_steps.map(item=> {
                            item.job_step_id = item.id
                            return item
                          }): row.job_steps,
                          order_status_id: (orderStatusItem) ? orderStatusItem.id : '',
                          send_notification_to_customer: true,
                          drop_off_worker_id: row.workerId,
                        }
                      ]
                    };
                    this.callUpdateStatusApi(data, (newRow) => {
                      if(newRow){
                        let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                        this.setState({
                          data: newData,
                        });
                      }
                    });
                  }}
                  onSearch={(value, e) => {
                    row.workerName = value;
  
                    this.callWorkerDriverReadApi(value, (arr) => {
                      e.showNewSuggestions(arr);
                    });
                  }}
                  onClick={(value, e) => {
                    this.callWorkerDriverReadApi(value, (arr) => {
                      e.showNewSuggestions(arr);
                    });
                  }}
                />
              </Box>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Manpower", 
          field: "extra_workers[0].first_name",
          hidden: this.state.hiddenColumns.includes('manpower'),
          render: (row, type) => {
            if(type === 'row'){
              let label = '';
              let manpowerName = (row.extra_workers && row.extra_workers.length > 0) ? row.extra_workers[0] : null;

              if(manpowerName){
                label = ((manpowerName.first_name && manpowerName.first_name !== '') ? manpowerName.first_name : '') + ' ' + ((manpowerName.last_name && manpowerName.last_name !== '') ? manpowerName.last_name : '');
              }

              row.manpowerName = label;

              return <Box>
                <CustomAutosuggest 
                  disabled = {this.state.grantedAccess.isShouldShowJobAccess(this.state.currentRole, 'job_assign') == false ? true: false}
                  className={'no-margin'}
                  placeholder={'Assign Manpower'}
                  value={row.manpowerName}
                  onChange={(value) => {
                    if(value){
                      row.manpowerName = value.label;
                      row.manpowerId = value.value;
                      row.manpowerItem = value.item;
                    } else {
                      row.manpowerName = '';
                      row.manpowerId = null;
                      row.manpowerItem = null;
                    }

                    if (row.job_steps === undefined) {
                      row.job_steps = [];
                    }

                    let data = {
                      data: [
                        {
                          id: [ row.id ],
                          job_steps: row.job_steps.length > 0 ? row.job_steps.map(item=> {
                            item.job_step_id = item.id
                            return item
                          }): row.job_steps,
                          order_status_id: row.order_status_id,
                          send_notification_to_customer: true,
                          extra_worker_ids: (row.manpowerId) ? row.manpowerId.toString() : null,
                        }
                      ]
                    };
                    this.callUpdateStatusApi(data, (newRow) => {
                      if(newRow){
                        let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                        this.setState({
                          data: newData,
                        });
                      }
                    });
                  }}
                  onSearch={(value, e) => {
                    row.manpowerName = value;

                    this.callManpowerDriverReadApi(value, (arr) => {
                      e.showNewSuggestions(arr);
                    });
                  }}
                  onClick={(value, e) => {
                    this.callManpowerDriverReadApi(value, (arr) => {
                      e.showNewSuggestions(arr);
                    });
                  }}
                />
              </Box>;
            } else {
              return row;
            }
          }
        },
        /*{ 
          title: "Delivery Info", 
          field: "drop_off_time_planned",
          hidden: this.state.hiddenColumns.includes('delivery_info'),
          render: (row, type) => {
            if(type === 'row'){
              let date = (row && row.drop_off_date && row.order_status) ? moment(row.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '';
              let time = (date && date !== '') ? (row && row.drop_off_time_planned && row.drop_off_time_planned) ? moment(row.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '' : '';
              
              let dateTime = '';
              if(date && time){
                dateTime = date + ' - ' + time;
              } else if (date){
                dateTime = date;
              } else if (time){
                dateTime = time;
              }
              
              return <div>
              <Box>
              {dateTime}
              </Box>
              <p>{row.job_steps && row.job_steps.length > 0 ? row.job_steps[0].location : ''}</p>
              {row.amount !== null && <p>Price: ${Number.parseFloat(row.amount).toLocaleString()}</p>}
              </div>;
            } else {
              return row;
            }
          }
        },*/
        { 
          title: "Job Type",
          width: '100px',
          field: "job_type",
          hidden: this.state.hiddenColumns.includes('job_type'),
        },
        { 
          title: "Driver Notes",
          field: "driver_notes",
          hidden: this.state.hiddenColumns.includes('driver_notes'),
        },
        { 
          title: "ACTION", 
          width: '200px',
          align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container justify={'center'}>
                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        this.generatePDF(row);
                      }}
                    >
                      <Icon component={'i'} style={{ fontSize: '20px', color: '#4e4e4e' }}>view_week</Icon>
                    </IconButton>
                  </Grid>
                </Box>

                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        window.open(REACT_APP_LDS_API_URL + '/dynamic_delivery_orders_steps/' + row.id + '.pdf','_blank');
                      }}
                    >
                      <Icon component={'i'} style={{ fontSize: '20px', color: '#4e4e4e' }}>insert_drive_file</Icon>
                    </IconButton>
                  </Grid>
                </Box>

                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        let copyData = this.state.copyData;
                        copyData.id = row.id;
                        this.setState({
                          copyDialogOpen: true,
                          copyDataId: copyData
                        });
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                  </Grid>
                </Box>
                
                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/jobs-form',
                          state: {
                            id: row.id
                          }
                        });
                      }}
                    >
                         <EditIcon />
                   
                    </IconButton>
                  </Grid>
                </Box>
                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        this.setState({
                          openDialog: true,
                          openDialogItem: row,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          }
        },
      ]}
    />
  }

  customToolbar = (e) => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    let isOwner = user.owner_info.is_customer_admin !== undefined ? user.owner_info.is_customer_admin : false;

    return <Box>
      <Grid container>
        <Box clone order={{ xs: 3, md: 1}}>
          <Grid item xs={12} md={3}>
            <CustomInput
              ref={this.refTableSearch}
              placeholder={'Search job'}
              endAdornment={
                <IconButton
                  onClick={(e) => {
                    this.callReadApi(this.refTableSearch.current.state.value);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              }
              onPressEnter={(e) => {
                this.callReadApi(e.target.value);
              }}
            />
          </Grid>
        </Box>
        <Box clone pb={{ xs: 2, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true}>
            <GroupButton
              color={'secondary'}
              selected={this.state.jobTabTable}
              buttons={[ "Own Jobs", "Vendor Jobs", "Table Column" ]}
              onClick={(selected, btn) => {
                if (selected === 2) {
                  this.setState({
                    openSettingsDialog: true
                  });
                } else {
                  this.setState({
                    jobTabTable: selected,
                  }, () => {
                    this.props.history.push('/job-vendor-list');
                  });
                }
              }}
            />
          </Grid>
        </Box>
        <Box clone textAlign={'right'} pl={1} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
          <Grid item xs={12} md={'auto'}>
              <CustomButton 
                color={'secondary'}
                onClick={() => {
                  this.setState({
                    openDialogBatchUpdate: true
                  });
                }}
              >
                <EditIcon />
                <Box display={'inline-block'} pl={1}>Batch Update</Box>
              </CustomButton>
          </Grid>
        </Box>
        <Box clone textAlign={'right'} pl={1} pr={1} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
          <Grid item xs={12} md={'auto'}>
              <CustomButton 
                color={'primary'}
                onClick={() => {
                  //this.autoAssignApi();
                  this.checkLatLong();
                }}
              >
                <AutoIcon />
                <Box display={'inline-block'} pl={1}>Auto Assign</Box>
              </CustomButton>
          </Grid>
        </Box>
		    <Box clone textAlign={'right'} pr={1} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
          <Grid item xs={12} md={'auto'}>
              <CustomButton 
                color={'secondary'}
                onClick={() => {
                  this.generatePDF();
                }}
              >
                <LabelIcon />
                <Box display={'inline-block'} pl={1}>Generate Label</Box>
              </CustomButton>
          </Grid>
        </Box>
        <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
          <Grid item xs={12} md={'auto'}>
              <CustomButton 
                color={'primary'}
                onClick={() => {
                  this.callExportXLSApi();
                }}
              >
                <ExportIcon />
                <Box display={'inline-block'} pl={1}>Export To Excel</Box>
              </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }

  customRow = (e, row) => {
    // let desc = (row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : '';

    let date = (row && row.drop_off_date && row.order_status) ? moment(row.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '';
    let time = (date && date !== '') ? (row && row.drop_off_time_planned && row.drop_off_time_planned) ? moment(row.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '' : '';
    
    let dateTime = '';
    if(date && time){
      dateTime = date + ' - ' + time;
    } else if (date){
      dateTime = date;
    } else if (time){
      dateTime = time;
    }

    return <tr>
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        <Box pb={1}>
          <DropdownMenu
            className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '')}
            text={(row && row.order_status && row.order_status.status) ? row.order_status.status : ''}
            rightIcon={<ArrowDownIcon />}
            items={this.state.tableStatus}
            onClick={(item) => {
              /*if(row.drop_off_worker_id && row.drop_off_worker_id > 0){*/
                let data = {
                  data: [
                    {
                      id: [ row.id ],
                      job_steps: (row.job_steps && row.job_steps.length > 0) ? row.job_steps.map(step => {
                        step.job_step_id = step.id;
                        if (item.text.toString().toLowerCase().trim() === "completed") {
                          step.job_step_status_id = 3;
                        } else {
                          step.job_step_status_id = 1;
                        }
                        return step
                      }): row.job_steps,
                      order_status_id: item.id,
                      send_notification_to_customer: true,
                    }
                  ]
                };

                if(item.text.toString().toLowerCase().trim() === 'not assigned'){
                  data.data[0]['drop_off_worker_id'] = null;
                  data.data[0]['extra_worker_ids'] = null;
                }

                this.callUpdateStatusApi(data, (newRow) => {
                  if(newRow){
                    let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                    this.setState({
                      data: newData,
                    });
                  }
                });
              /*} else {
                apiUtil.toast('You must assign a driver!', 'warning', 'error');
              }*/
            }}
          />
        </Box>
      </td>
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        <Box pl={2}>
          <Box>
            {dateTime}
          </Box>
          <Box>{row.job_steps && row.job_steps.length > 0 ? row.job_steps[0].location : ''}</Box>
          {row.amount !== null && <Box>Price: ${Number.parseFloat(row.amount).toLocaleString()}</Box>}
        </Box>
      </td>
      <td 
        colSpan={6}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >&nbsp;</td>
      {/* <td 
        colSpan={5}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        {(desc !== '') && <Box>
          <Box pl={2} pb={2}>
            <CustomReadMore className={'lightGray'} maxLine={2} text={(row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : ''} />
          </Box>  
        </Box>}
      </td> */}
      {/* <td 
        colSpan={4}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        {(desc !== '') && <Box>
          <Box pl={2} pb={2}>
            <CustomReadMore className={'lightGray'} maxLine={2} text={(row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : ''} />
          </Box>  
        </Box>}
      </td> */}
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >&nbsp;</td>
    </tr>
  }
  /* END TABLE */
  

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callDeleteApi(row, () => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          }, () => {
            this.callReadApi(this.refTableSearch.current.state.value);
            apiUtil.toast('Successfully Deleted', 'check_circle');
          });
        });
      }}
    >
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }

  customDialogBatchUpdate = () => {
    return <CustomFormDialog 
        open={this.state.openDialogBatchUpdate}
        title={'Batch Update'}
        onClose={() => {
          this.setState({
              openDialogBatchUpdate: false,
          });
        }}
    >

    <BatchUpdateForm
      onSave={(data) => {
          this.batchUpdateApi(data);
      }}
      onClose={() => {
          this.setState({
              openDialogBatchUpdate: false
          });
      }}
    />


    </CustomFormDialog>
  }

  customDialogAutoAssign = () => {
    return <CustomFormDialog 
        maxWidth={'md'}
        open={this.state.openDialogAutoAssign}
        title={'Auto Assign - Invalid Address'}
        onClose={() => {
          this.setState({
              openDialogAutoAssign: false,
          });
        }}
    >

    <AutoAssignForm
      data={this.state.invalidAddress}
      onSave={(data) => {
          this.checkLatLong(data);
          this.setState({
              openDialogAutoAssign: false
          });
      }}
      onClose={() => {
          this.setState({
              openDialogAutoAssign: false
          });
      }}
    />

    </CustomFormDialog>
  }

  toggleColumn = (checked, columns) => {
    let hiddenColumns = this.state.hiddenColumns;

    if (columns.length > 0) {
      if (checked === true) { // hide column
        hiddenColumns = hiddenColumns.concat(columns);
      } else { // show column
        columns.forEach(column => {
          let index = hiddenColumns.indexOf(column);
          if (index !== -1) {
            hiddenColumns.splice(index, 1);
          }
        });
      }
    }

    this.setState({
      hiddenColumns: hiddenColumns
    });
  }

  columnSettingsDialog = () => {
    const { accessToken } = this.context;
    let hiddenColumns = this.state.hiddenColumns;

    return <CustomDialog
      title={'Column Settings'}
      btnOkText={'Save Settings'}
      open={this.state.openSettingsDialog}
      onOk={() => {
        this.setState({
          isLoading: true,
          hiddenColumns: hiddenColumns
        });

        if (this.state.hiddenColumnSettingId > 0) {
          api.update('application_company_settings/' + this.state.hiddenColumnSettingId, {
            json_data: JSON.stringify(hiddenColumns)
          }, accessToken)
            .then(result => {
              let hiddenColumnSettingId = 0;
              if (result.data.data.result.id) {
                hiddenColumnSettingId = result.data.data.result.id;
                localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
              }

              this.setState({
                openSettingsDialog: false,
                hiddenColumnSettingId: hiddenColumnSettingId,
                isLoading: false
              })
          });
        } else {
          api.create('application_company_settings', {
            enabled: true,
            rule: "job_column_settings",
            json_data: JSON.stringify(hiddenColumns)
          }, accessToken)
            .then(result => {
              let hiddenColumnSettingId = 0;
              if (result.data.data.result.id) {
                hiddenColumnSettingId = result.data.data.result.id;
                localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
              }

              this.setState({
                openSettingsDialog: false,
                hiddenColumnSettingId: hiddenColumnSettingId,
                isLoading: false
              })
          });
        }
      }}
    >
     <CustomCheckbox checked={this.state.hiddenColumns.includes('order_number')} label={'Hide Job Number'} onChange={(checked) => this.toggleColumn(checked, ['order_number'])} />

     <CustomCheckbox checked={(this.state.hiddenColumns.includes('drop_off_contact_name') || this.state.hiddenColumns.includes('drop_off_name'))} label={'Hide Company'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_contact_name', 'drop_off_name'])} />

     <CustomCheckbox checked={this.state.hiddenColumns.includes('driver')} label={'Hide Driver'} onChange={(checked) => this.toggleColumn(checked, ['driver'])} />

     <CustomCheckbox checked={this.state.hiddenColumns.includes('manpower')} label={'Hide Manpower'} onChange={(checked) => this.toggleColumn(checked, ['manpower'])} />

     <CustomCheckbox checked={this.state.hiddenColumns.includes('job_type')} label={'Hide Job Type'} onChange={(checked) => this.toggleColumn(checked, ['job_type'])} />

     <CustomCheckbox checked={this.state.hiddenColumns.includes('driver_notes')} label={'Hide Driver Notes'} onChange={(checked) => this.toggleColumn(checked, ['driver_notes'])} />
    </CustomDialog>
  }

  previewDialog = () => {
    return <CustomDialog 
      open={this.state.openPreviewDialog}
      title={'Job Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
      />
    </CustomDialog>
  }

  importDialog = () => {
    return <CustomDialog 
      open={this.state.openImportDialog}
      title={'Import Jobs'}
      maxWidth={'xl'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openImportDialog: false,
          openImportDialogItem: null,
        });
      }}
    >
      <JobImportDialog
        file={this.state.openImportDialogItem}
        isLoading={this.state.openImportDialogIsLoading}
        onClose={() => {
          this.setState({
            openImportDialog: false,
            openImportDialogItem: null,
          });
        }}
        onImport={(form) => {
          this.setState({
            openImportDialogIsLoading: true
          }, () => {
            this.callImportApi(form, (data) => {
              let all_jobs = (form && form.length > 0) ? form.length : 0;
              let failed_orders = (data && data.failed_orders && data.failed_orders.length > 0) ? data.failed_orders.length : 0;
              let saved_orders = (data && data.saved_orders && data.saved_orders.length > 0) ? data.saved_orders.length : 0;
              
              if(failed_orders > 0){
                apiUtil.toast(failed_orders + ' of ' + all_jobs + ' jobs have not successfully imported', 'check_circle', 'error');
              }
  
              if(saved_orders > 0){
                apiUtil.toast(saved_orders + ' of ' + all_jobs + ' jobs successfully imported', 'check_circle', 'success');
              }
  
              this.callReadApi(this.refTableSearch.current.state.value);
  
              this.setState({
                openImportDialog: false,
                openImportDialogItem: null,
                openImportDialogIsLoading: false,
              });
            });
          });
        }}
      />
    </CustomDialog>
  }

  toggleCopyData = (checked, column) => {
    let copyData = this.state.copyData;
    copyData[column] = checked;
    this.setState({
      copyData: copyData
    });
  }

  copyDialog = () => {
    return <CustomDialog
      title={'Duplicate Job'}
      btnOkText={'Duplicate Job'}
      open={this.state.copyDialogOpen}
      onOk={() => {
        this.props.history.push({
          pathname: "/jobs-form",
          copyData: this.state.copyData
        });
      }}
    >
      <p>Which items you want to duplicate as a new job?</p>
      <p className="modal-subtitle">Job Details</p>
      <CustomCheckbox label={'Job Date'} checked={this.state.copyData.drop_off_date} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_date');
      })} />
      <CustomCheckbox label={'Job Description'} checked={this.state.copyData.drop_off_description} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_description');
      })} />
      <CustomCheckbox label={'Job Time'} checked={this.state.copyData.drop_off_time_planned} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_time_planned');
      })} />

      <p className="modal-subtitle">Recipient Details</p>
      <CustomCheckbox label={'Name'} checked={this.state.copyData.drop_off_name} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_name');
      })} />
      <CustomCheckbox label={'Email'} checked={this.state.copyData.drop_off_contact_email} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_contact_email');
      })} />
      <CustomCheckbox label={'Phone No'} checked={this.state.copyData.drop_off_contact_no} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_contact_no');
      })} />
      <CustomCheckbox label={'Tracking Number'} checked={this.state.copyData.item_tracking_number} onChange={((checked) => {
        this.toggleCopyData(checked, 'item_tracking_number');
      })} />

      <p className="modal-subtitle">Additional Details</p>
      <CustomCheckbox label={'Items in this job'} checked={this.state.copyData.order_details} onChange={((checked) => {
        this.toggleCopyData(checked, 'order_details');
      })} />
      <CustomCheckbox label={'Assign Workers'} checked={this.state.copyData.drop_off_worker} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_worker');
      })} />
      <CustomCheckbox label={'Job Steps'} checked={this.state.copyData.job_steps} onChange={((checked) => {
        this.toggleCopyData(checked, 'job_steps');
      })} />
      <CustomCheckbox label={'Advanced Options'} checked={this.state.copyData.amount} onChange={((checked) => {
        this.toggleCopyData(checked, 'amount');
      })} />
    </CustomDialog>
  }
  /* END DIALOG */


  /* STEPPER */
  setStepper = () => {
    return <>
      <CustomGuideStepper
        activeStep={3}
        steps={[
          {
            label: 'Create Customer',
          },
          {
            label: 'Create Vehicle',
          },
          {
            label: 'Create Operator',
          },
          {
            label: 'Create Job',
          },
        ]}
        onClick={(index) => {
          if(index === 0){
            this.props.history.push('/customers');
          } else if(index === 1){
            this.props.history.push('/resources/vehicle');
          } else if(index === 2){
            this.props.history.push('/resources/transport-operator');
          } else if(index === 3){
            // this.props.history.push('/job-list');
          }
        }}
      />
      <Box pb={2} textAlign={'center'}>This is the Job list page.Let's learn some basics about this page.</Box>
      <Box textAlign={'center'}>
        <CustomButton 
          color={'primary'}
          href={'/'}
          onClick={(e) => {
              e.preventDefault();

              this.setState({
                openSkipDialog: true,
              });
          }}
        >
          Skip All
        </CustomButton>
      </Box>
    </>
  }
  /* END STEPPER */

  /* TOUR */
  setTour = () => {
    return <>
      <Tour
        steps={[
          {
            selector: '.select-dates-step',
            content: <Box>
              <h2>Select dates</h2>
              <p>Here is where you choose a range of dates to display in the job list</p>
            </Box>,
          },
          {
            selector: '.job-statuses-step',
            content: <Box>
              <h2>Job Statuses</h2>
              <p>This area is where you monitor all of your job progress in general. You can filter all job statuses by clicking on any of the statuses here.</p>
            </Box>,
          },
          {
            selector: '.job-listing-step',
            content: <Box>
              <h2>Job Listing</h2>
              <p>Like vehicles and drivers, here is where all your available jobs are are populated.You can edit the displayed columns and sort by a column.</p>
            </Box>,
          },
          {
            selector: '.new-job-step',
            stepInteraction: true,
            content: <Box>
              <h2>Create job</h2>
              <p>Now let's create a new job.</p>
            </Box>,
          },
        ]}
        isOpen={this.state.isTourOpen}
        rounded={10}
        accentColor={'#64CCC9'}
        showNumber={false}
        showNavigation={false}
        disableInteraction={true}
        // prevButton={<></>}
        // nextButton={<></>}
        lastStepNextButton={<></>}
        onRequestClose={() => {
          this.setState({
            isTourOpen: false,
          });
        }} />
    </>
  }
  /* END TOUR */

  /* SKIP DIALOG */
  skipDialog = () => {
    const { accessToken, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = this.context;

    return <CustomDialog 
      open={this.state.openSkipDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        this.setState({
          openSkipDialog: false,
        });
      }}
      onOk={() => {
        apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
          this.setState({
            openSkipDialog: false,
          }, () => {
            setOnBoardingCustomer(null);
            setOnBoardingVehicle(null);
            setOnBoardingDriver(null);
            setOnBoardingJob(null);

            apiUtil.toastOnBoarding('Done', 'check_circle');
            this.props.history.push('/');
          });
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END SKIP DIALOG */

  /*START AUTO ASSIGN*/
  autoAssignApi = (callback = null) =>{
    const { accessToken } = this.context;

    let row = {};
    row.start_date = moment(this.state.startDate).format("YYYY-MM-DD");
    row.end_date = moment(this.state.endDate).format("YYYY-MM-DD");

    this.setState({
      isLoading: true
    }, () => {

      ldsApi.create('auto_assign/jobs', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.job_assigned && data.job_assigned.length > 0) ? data.job_assigned : null;

            if(result){
              let currentData = this.state.data;
              currentData.map((item, i)=>{
                 result.map((res, ii)=>{
                    if(item.id == res.id){
                      item.order_status_id = res.order_status_id;
                      item.order_status = res.order_status;

                      item.drop_off_worker_id = res.drop_off_worker_id;
                      item.drop_off_worker = res.drop_off_worker;
                      item.drop_off_worker_name = res.drop_off_worker_name;
                    }
                 }); 
              });

              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });    
  }

  checkLatLong = (currentData=null, callback = null)=>{
    if(!currentData){ currentData = this.state.data; };

    let invalidLatLong = [];
    let finishCallback = [];
  
    this.setState({
      isLoading: true
    }, () => {

        currentData.filter(x => x.order_status_id == 679).map((v,k)=>{
             
            let row = {};
            row.address = v.drop_off_address; 
            this.generateLatLong(row, (data) => {
                if(data.success){
                  let isDiffLatLong = false;
                  if(v.drop_off_latitude != data.lat && v.drop_off_longitude != data.lng){
                    v.drop_off_latitude = data.lat.toString();
                    v.drop_off_longitude = data.lng.toString();
                    isDiffLatLong = true;
                  }
                  
                  let format = {};
                  format.data = [];

                  let template = {};
                  template.id = [v.id];
                  template.drop_off_latitude = v.drop_off_latitude;
                  template.drop_off_longitude = v.drop_off_longitude;
                  template.drop_off_address = v.drop_off_address;
                  format.data.push(template);

                  if(isDiffLatLong){
                    this.updateLatLong(format, (result) => {
                      if(!result){ invalidLatLong.push(v); }
                      finishCallback.push("true");

                      if(finishCallback.length == currentData.filter(x => x.order_status_id == 679).length){
                        this.showInvalidAddress(invalidLatLong);
                      }
                    });
                  }else{
                    finishCallback.push("true");

                    if(finishCallback.length == currentData.filter(x => x.order_status_id == 679).length){
                      this.showInvalidAddress(invalidLatLong);
                    }
                  }
                  
                }else{
                  invalidLatLong.push(v);
                  finishCallback.push("true");

                  if(finishCallback.length == currentData.filter(x => x.order_status_id == 679).length){
                    this.showInvalidAddress(invalidLatLong);
                  }
                }
            });
          
        });
    });  
  }

  showInvalidAddress = (invalidLatLong, callback = null)=>{
    if(invalidLatLong.length == 0){ 
      this.autoAssignApi();
    }else{
      this.setState({
                      isLoading: false, 
                      openDialogAutoAssign:true,
                      invalidAddress: invalidLatLong
                  });
    }
  }

  generateLatLong = (row, callback = null)=>{
    const { accessToken } = this.context;
    ldsApi.create('geo_functions/geocoder_address', row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result) ? data.result : null;
          if(result){            
            callback(result);
          }else{ 
            callback(null);   
          }
        }, (error, type) => {
          callback(null)
        });
    });
  }

  updateLatLong = (row, callback = null)=>{
    const { accessToken } = this.context;
    ldsApi.create('orders/assign/order', row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result) ? data.result : null;
          if(result){            
            callback(result);
          }else{ 
            callback(null);   
          }
        }, (error, type) => {
          callback(null)
        });
    });
  }

  /*END AUTO ASSIGN*/

  /* PDF GENERATE */ 

  generatePDF = (row) => {
    const qr = function(){return (<QRCode value={'hello world'}/>)}

    let pdf = new jsPDF("l", "mm", [62, 100]);

    let index = 0;
    let data = row?[row]:this.state.data;  
    
    data.map(function(v,k){
      if(index!= 0 ){  pdf.addPage(); }
        
        pdf.setFont("verdana","normal","400");
        pdf.setFontSize(10);
        pdf.text(10,7, v.order_number);  
        
        ReactDOM.render(<Barcode value={v.order_number} displayValue={false} renderer={"img"}/>, document.getElementById('barcode'));
        ReactDOM.render(<QRCode value={v.order_number} renderAs={'canvas'}/>, document.getElementById('qr'));
        let barData = document.getElementById('barcode').children[0].src;  
        let qrData = document.getElementById('qr').children[0]; 
        pdf.addImage(barData, 'PNG',10,8,50,14);
        pdf.addImage(qrData.toDataURL('image/png'), 'PNG',80,10,12,12);

        pdf.setFontSize(8);
        pdf.text(10,25, "SHIP TO :");
        let total_kg = v.total_kg?v.total_kg:"0.0";
        pdf.text(60,29, "Weight(Kg) : "+total_kg);

        pdf.setFont("verdana","bold","700");
        let drop_off_attention = v.drop_off_attention?v.drop_off_attention:"";
        let drop_off_contact_no = v.drop_off_contact_no?v.drop_off_contact_no:"";
        let drop_off_address = v.drop_off_address?v.drop_off_address:"";
        let drop_off_postal_code = v.drop_off_postal_code?v.drop_off_postal_code:"";

        pdf.text(10,29, ""+drop_off_attention);        
        pdf.text(10,33, ""+drop_off_contact_no);        
        pdf.text(10,37, ""+drop_off_address);
        pdf.text(10,41, ""+drop_off_postal_code);
        
        pdf.addImage(LDSIcon,'PNG', 10, 50, 5, 5);
        index++;
      });
      pdf.save("pdf"); 
  }
  
    /*END PDF GENERATE */
  
  /*START BATCH UPDATE*/
  batchUpdateApi = (data, callback = null) =>{
    const { accessToken } = this.context;

    let row = {};
    row.data = [];
    let template = {};
    let selectedRow = this.state.selectedRow;
    let ids = [];

    selectedRow.map((item, i) => { ids.push(item.id); });
    if(!data.order_status_id && !data.drop_off_worker_id && !data.pickup_worker_id){
      this.setState({
        openDialogBatchUpdate:false
      });
      return;
    }

    template.id = ids;
    if(data.order_status_id){ template.order_status_id = data.order_status_id; }
    if(data.drop_off_worker_id){ template.drop_off_worker_id = data.drop_off_worker_id; }
    if(data.extra_worker_ids){ template.extra_worker_ids = data.extra_worker_ids.toString(); }

    row.data.push(template);

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;

            if(result){
              let currentData = this.state.data;
              currentData.map((item, i)=>{
                 data.result.map((res, ii)=>{
                    if(item.id == res.id){
                      item.order_status_id = res.order_status_id;
                      item.order_status = res.order_status;

                      item.drop_off_worker_id = res.drop_off_worker_id;
                      item.drop_off_worker = res.drop_off_worker;
                      item.drop_off_worker_name = res.drop_off_worker_name;

                      item.extra_workers_ids = res.extra_workers_ids;
                      item.extra_workers = res.extra_workers;
                    }
                 }); 
              });

              this.setState({
                isLoading: false,
                openDialogBatchUpdate:false,
                data:currentData
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
                openDialogBatchUpdate:false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
              openDialogBatchUpdate:false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
    
  }
  /*END Batch Update*/

  render() {
    return <Box className="job-list-page" >
      <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
      
      <Card className={'pb-0'}>
        {this.customStatus()}
      </Card>

      <Card className={'job-listing-step'}>
        {this.customTable()}
      </Card>
      
      {this.customDialog()}
      {this.customDialogBatchUpdate()}
      {this.customDialogAutoAssign()}
      {this.previewDialog()}
      {this.importDialog()}
      {this.setTour()}
      {this.skipDialog()}
      {this.columnSettingsDialog()}
      {this.copyDialog()}
	  <div id="barcode" style={{display:'none'}}></div>
      <div id="qr" style={{display:'none'}}></div>
    </Box>;
  }
}