import React, {useState, useCallback, useEffect, useContext} from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import Api from "../../api/api.jsx";
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomCropper from '../../components/Cropper/CustomCropper.jsx';

import { 
    Box,
    Grid,
    Container,
    Divider,
    IconButton,
    CircularProgress
  } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            {children}
          </Box>
        )}
      </div>
    );
}

export const SettingProfile = () => {
    const history = useHistory();
    const location = useLocation();
    const { accessToken, user, setUser } = useContext(AuthContext)
    const pathName = (location && location.state && location.state.pathname && location.state.pathname.length > 0) ? location.state.pathname : "/";
    const [value, setValue] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingRead, setIsLoadingRead ] = useState(false);

    // general section
    const [personalName, setPersonalName] = useState("")
    const [personalEmail, setPersonalEmail] = useState("")
    const [personalPassword, setPersonalPassword] = useState("")
    const [personalProfileImageURL, setPersonalProfileImageURL] = useState("")
    const [personalProfileOldData, setPersonalProfileOldData] = useState("")
    
    const callReadSettingsApi = useCallback(() => {
        setIsLoadingRead(true);

        Api.read('profile/admins', {}, accessToken)
        .then(result => {
            // console.log(result)
            apiUtil.parseResult(result, data => {
                // const res = (data.result && Object.keys(data.result).length > 0 ) ? data.result : {}
                // setCurrentMeasurementData(res);
                if(data.result && Object.keys(data.result).length > 0) {
                    setPersonalName(data.result.first_name);
                    setPersonalEmail(data.result.email);
                    setPersonalProfileImageURL(data.result.profile_image_url);
                    setPersonalProfileOldData(data.result);
                    let user = JSON.parse(window.localStorage.getItem("user"));
                    user.owner_info = data.result;
                    window.localStorage.setItem('user', JSON.stringify(user));
                }

                setIsLoadingRead(false);
            }, (error, type) => {
                setIsLoadingRead(false);
            })
        })

    },[accessToken])
    
    // const callUploadBase64 = ( row, callback = null) => {
    //     setIsLoading(true);
    //     Api.uploadImage('upload_base64', row, accessToken)
    //     .then((result) => {
    //         apiUtil.parseResult(result, (data) => {
    //             setIsLoading(false);
    //             // console.log("submit", data)
    //             setPersonalProfileImageURL(data.result[0].image_url);
    //             if(callback) {
    //                 callback();
    //             }
    //         }, (error, type) => {
    //             console.log("error", error)
    //             setIsLoading(false);
    //             if(callback) {
    //                 callback();
    //             }
    //         });
    //     }).catch(
    //         error => console.log(error)
    //     )
    // }
    const callUpdateProfile = ( row, callback = null) => {
        setIsLoading(true);
        if(Object.keys(row).length > 0) {
            Object.assign(row, {appName:"AUTH"}); 
        }
        Api.update('profile/admins', row, accessToken)
        .then((result) => {
            apiUtil.parseResult(result, (data) => {
                setIsLoading(false);
                // console.log("submit", data)
                // setPersonalProfileImageURL(data.result[0].image_url);
                callReadSettingsApi();
                setTimeout(function(){ window.location.reload(); },1000);
                if(callback) {
                    callback(data.result);
                }
            }, (error, type) => {
                console.log("error", error)
                setIsLoading(false);
                if(callback) {
                    callback(null);
                }
            });
        }).catch(
            error => console.log(error)
        )
    }



    useEffect(() => {
        callReadSettingsApi();
        return () => {
            
        }
    }, [callReadSettingsApi])


    const CustomStickyPageHeader = () => {
     return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <Box clone>
          <Grid item xs={'auto'}>
                <IconButton 
                    onClick={() => {
                        history.push(pathName);
                    }}
                >
                    <CloseIcon />
                </IconButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
                <Divider orientation={'vertical'} />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={true}>
            <CustomLabel
                className={'no-margin'}
                label={'User Settings'}
                weight={'bold'}
                size={'md'}
            />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              type={'submit'}
              color={'secondary'}
              isLoading={isLoading}
            >
              <Box pl={2} pr={2}>Save Changes</Box>
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
    }
    const settingsAccountProfile = () => {
        return <Box><Grid container p={1} >
                <CustomLabel
                    label={'Personal Information'}
                    weight={'bold'}
                    size={'md'}
                />
                <Box clone>
                    <Grid item xs={12}>
                        <div style={{
                                position: 'relative',
                                display: 'flex',
                                height: '260px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                justifyItems: 'center',
                        }}>
                            <CustomCropper
                                className={'no-shadow'}
                                path={'ProfilePicture'}
                                initialImg={personalProfileImageURL}
                                onLoad={() => {
                                    setIsLoading(true);
                                }}
                                onUpload={(url) => {
                                    if(url && url.result && url.result.image_medium_url && url.result.image_medium_url && url.result.image_medium_url !== ''){
                                        setPersonalProfileImageURL(url.result.image_medium_url);
                                    }
                                }}
                                onEnd={() => {
                                    setIsLoading(false);
                                }}
                            />
                        </div>
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'Name'}
                            // placeholder={'Enter Country'}
                            className="input-label--horizontal"
                            value={personalName}
                            // error={firstNameValidated}
                            // required={true}
                            // autoFocus={true}
                            onChange={e => {
                                setPersonalName(e.target.value)
                            }}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'Email'}
                            // placeholder={'Enter State'}
                            className="input-label--horizontal"
                            value={personalEmail}
                            // error={firstNameValidated}
                            // required={true}
                            // autoFocus={true}
                            onChange={e => {
                                setPersonalEmail(e.target.value)
                                // setFirstNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'Password'}
                            // placeholder={'City'}
                            className="input-label--horizontal"
                            value={personalPassword}
                            // error={lastNameValidated}
                            // required={true}
                            // autoFocus={true}
                            onChange={e => {
                                setPersonalPassword(e.target.value)
                                // setLastNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
            </Grid>
        </Box>;
    }  
    

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        

        if(personalProfileImageURL !== personalProfileOldData.profile_image_url) {
            // let uploadImageFormatData = {
            //     "appName": "AUTH",
            //     "base64_images": [personalProfileImageURL],
            //     "exact": true,
            //     "folder_name": "ProfilePicture/645",
            //     "height": 200,
            //     "ignore_ratio": true,
            //     "width": 200
            // }
            // callUploadBase64( uploadImageFormatData, () => {
                // history.push('/resources/transport-operator');
                // apiUtil.toast('Successfully Updated', 'check_circle');
                let isChangePersonalName = personalName !== personalProfileOldData.first_name? personalName: undefined;
                let isChangePersonalEmail = personalEmail !== personalProfileOldData.email? personalEmail: undefined;
                let isChangePersonalPassword = personalPassword != "" ? personalPassword: undefined
                let updateProfileFormatData = {"first_name": isChangePersonalName,"email": isChangePersonalEmail, "password": isChangePersonalPassword, profile_image_url: personalProfileImageURL}
            
                callUpdateProfile( updateProfileFormatData, (data) => {
                    let userInfo = apiUtil.getObject(user);
                    userInfo.owner_info = data;
                    setUser(JSON.stringify(userInfo));
                    // history.push('/resources/transport-operator');
                    apiUtil.toast('Successfully Updated Profile', 'check_circle');
                })
            // })
           

            
        } else {
            let isChangePersonalName = personalName !== personalProfileOldData.first_name? personalName: undefined;
            let isChangePersonalEmail = personalEmail !== personalProfileOldData.email? personalEmail: undefined;
            let isChangePersonalPassword = personalPassword ? personalPassword: undefined
            let updateProfileFormatData = {"first_name": isChangePersonalName,"email": isChangePersonalEmail, "password": isChangePersonalPassword}
        
            callUpdateProfile( updateProfileFormatData, (data) => {
                let userInfo = apiUtil.getObject(user);
                userInfo.owner_info = data;
                setUser(JSON.stringify(userInfo));
                // history.push('/resources/transport-operator');
                apiUtil.toast('Successfully Updated Profile', 'check_circle');
            })
        }
      
    }
    return (
    <Box className="settings--form" >
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <StickyPageHeader isSmall={true}>
                <CustomStickyPageHeader />
            </StickyPageHeader>
            <Container maxWidth="lg">
                <Grid container>

                    {/* <Grid item xs={3}>
                        <Box clone marginTop={3}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Settinsg Menus"
                        >
                            <Tab label="Account Profile" {...a11yProps(0)} />
                        </Tabs>
                        </Box>
                    </Grid> */}

                    {/* <Grid item xs={9}> */}
                    <Grid item xs={12}>
                        <Card>
                        <TabPanel value={value} index={0}>
                            {
                                isLoadingRead
                                ?
                                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'55vh'}>
                                    <CircularProgress size={35} />
                                </Box>
                                :
                                settingsAccountProfile()
                            }
                        </TabPanel>
                        </Card>
                    </Grid>

                </Grid>

            </Container>
        </form>
    </Box>
    )
}

