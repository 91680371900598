import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import moment from 'moment';

import { 
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';

import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import { ReactComponent as PreviousIcon } from '../../assets/img/icons/previous.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomSelectOption from '../../components/CustomFields/CustomSelectOption.jsx';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import CustomReadMore from '../../components/Truncate/CustomReadMore.jsx';
import DropdownMenu from '../../components/Dropdown/DropdownMenu.jsx';

import JobPreviewDialog from '../Jobs/Partial/JobPreviewDialog.jsx';


export class ReportTransportOperationDetails extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let id = (this.props.location && this.props.location.state && this.props.location.state.id && this.props.location.state.id > 0) ? this.props.location.state.id : '';
    let name = (this.props.location && this.props.location.state && this.props.location.state.name && this.props.location.state.name !== '') ? this.props.location.state.name : '';
    let startDate = (this.props.location && this.props.location.state && this.props.location.state.startDate && this.props.location.state.startDate !== '') ? moment(this.props.location.state.startDate) : moment().startOf('month');
    let endDate = (this.props.location && this.props.location.state && this.props.location.state.endDate && this.props.location.state.endDate !== '') ? moment(this.props.location.state.endDate) : moment().endOf('month');
    let selectedStatusId = (this.props.location && this.props.location.state && this.props.location.state.selectedStatusId && this.props.location.state.selectedStatusId > 0) ? this.props.location.state.selectedStatusId : 0;
    let page = (this.props.location && this.props.location.state && this.props.location.state.page && this.props.location.state.page > 0) ? this.props.location.state.page : 1;
    
    this.state = {
        id: id, 
        name: name, 
        startDate: startDate,
        endDate: endDate,
        rangeDate: {
            startDate: startDate,
            endDate: endDate,
        },
        selectedStatusId: selectedStatusId,
        page: page,

        orderStatusesIsLoading: false,
        orderStatuses: [],

        isLoading: false,
        grouping: false,
        paging: false,
        selection: true,
        data: [],

        openPreviewDialog: false,
        openPreviewDialogItem: null,
        openPreviewDialogStep: 0,
    }
  }
  

  componentDidMount() {
    this.callReadApi();
    this.callStatusApi();
  }


  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      orderStatusesIsLoading: true,
      orderStatuses: [],
    }, () => {
      ldsApi.read('order_statuses', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let arr = [];
            if(data && data.length > 0){
              arr = data.map((item, i) => {
                let value = item.id;
                let label = item.status;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              arr.unshift({
                value: 0,
                label: 'All',
                item: null
              });

              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              orderStatusesIsLoading: false,
              orderStatuses: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }

  callReadApi = (search = '') => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
        order_status_ids: (this.state.selectedStatusId && this.state.selectedStatusId > 0) ? this.state.selectedStatusId : '',
        drop_off_worker_id: this.state.id.toString(),
      };

      ldsApi.create('job/summary', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              data: (data.result && data.result.length > 0) ? (data.result[0] && data.result[0].content && data.result[0].content.length > 0) ? data.result[0].content : [] : [],
              name: (data.result && data.result.length > 0) ? (data.result[0] && data.result[0].title && data.result[0].title !== '') ? data.result[0].title : this.state.name : this.state.name,
              isLoading: false,
            });
          }, (error, type) => {
            this.setState({
              data: [],
              isLoading: false,
            });
          });
      });
    });
  }

  
  callExportXLSApi = (order_status_ids = '', ids = '', isCustomer = false) => {
    const { accessToken } = this.context;

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
        order_status_ids: order_status_ids,
      };

      if(isCustomer){
        param['customer_id'] = ids;
      } else {
        param['drop_off_worker_id'] = ids;
      }

      ldsApi.exportXLS('job/summary/xls', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `Report ${moment(param.startDate).format('YYYY-MM-DD')} - ${moment(param.endDate).format('YYYY-MM-DD')}.xls`);
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    });
  }

  callExportPDFApi = (order_status_ids = '', ids = '', isCustomer = false) => {
    const { accessToken } = this.context;

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
        order_status_ids: order_status_ids,
      };

      if(isCustomer){
        param['customer_id'] = ids;
      } else {
        param['drop_off_worker_id'] = ids;
      }

      ldsApi.exportPDF('job/summary/pdf', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            if(response && response.pdf_url && response.pdf_url !== ''){
              const link = document.createElement('a');
              link.href = response.pdf_url;
              link.setAttribute('download', `Report ${moment(param.startDate).format('YYYY-MM-DD')} - ${moment(param.endDate).format('YYYY-MM-DD')}.pdf`);
              link.setAttribute('target', '_blank');
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
            
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    });
  }
  /* END API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={{ xs: 0, lg: 1 }}>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <CustomSelectOption
              className={'head-select'}
              label={' '}
              placeholder={'All'}
              value={this.state.selectedStatusId}
              items={this.state.orderStatuses}
              onChange={(value, item) => {
                this.setState({
                  selectedStatusId: value
                }, () => {
                  this.callReadApi();
                });
              }}
            />
          </Grid>
        </Box>
        <Box clone pr={{ xs: 0, lg: 1 }} pb={{ xs: 2, sm: 0 }}>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <CustomDateRangePicker
              range={this.state.rangeDate}
              onChange={(range) => {
                this.setState({
                  rangeDate: range,
                }, () => {
                  this.callReadApi();
                });
              }}
            />
          </Grid>
        </Box>
        <Box clone pb={2} pr={{ xs: 0, lg: 2 }} pt={2}>
          <Grid item xs={12} md={2}>
              <DropdownMenu
                text={'Export report'}
                color={'secondary'}
                leftIcon={<ExportIcon />}
                items={[
                  {
                    id: 1,
                    text: 'Export to Excel',
                  },
                  {
                    id: 2,
                    text: 'Export to Pdf',
                  },
                ]}
                onClick={(item) => {
                  let selectedStatusId =  (this.state.selectedStatusId && this.state.selectedStatusId > 0) ? this.state.selectedStatusId : '';
                  
                  if(item.id === 1){
                    this.callExportXLSApi(selectedStatusId, this.state.id.toString(), false);
                  } else {
                    this.callExportPDFApi(selectedStatusId, this.state.id.toString(), false);
                  }
                }}
              />
          </Grid>
        </Box>
        <Box clone pb={2}>
          <Grid item xs={12}>
              <Grid container alignItems={'center'}>
                  <Box clone>
                      <Grid item xs={'auto'}>
                        <IconButton
                            onClick={() => {
                                this.props.history.push({
                                  pathname: '/report-transport-operation',
                                  state: {
                                    isBack: 'yes',
                                    startDate: moment(this.state.startDate).format(),
                                    endDate: moment(this.state.endDate).format(),
                                    selectedStatusId: this.state.selectedStatusId,
                                    page: this.state.page,
                                  }
                                });
                            }}
                        >
                            <PreviousIcon />
                        </IconButton>
                      </Grid>
                  </Box>
                  <Box clone>
                      <Grid item xs={'auto'}>
                        <CustomLabel
                            className={'no-margin'}
                            label={this.state.name}
                            size={'xl'}
                        />
                      </Grid>
                  </Box>
              </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* TABLE */
  customTable = () => {
    return <Table 
      ref={this.refTable}
      className={'job-table'}
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      paging={this.state.paging}
      selection={this.state.selection}
      data={this.state.data}
      onSelectionChange={(selectedRows) => {
        console.log("selectedRows", selectedRows);
      }}
      // Row={this.customRow}
      columns={[
        { 
            title: "No", 
            field: "no",
            width: '40px',
            maxWidth: '40px',
            render: (row, type) => {
              if(type === 'row'){
                  return <Box>
                      
                          { row.no + 1 }
                     
                  </Box>;
              } else {
                  return row;
              }
          }
        },
        { 
            title: "Reference No", 
            field: "reference_no",
            render: (row, type) => {
                if(type === 'row'){
                    return <Box>
                        <CustomButton
                            className={'underline'}
                            color={'primary'}
                            href={'/'}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                this.setState({
                                  openPreviewDialog: true,
                                  openPreviewDialogItem: row.order_id,
                                  openPreviewDialogStep: 0,
                                });
                            }}
                        >
                            {(row && row.reference_no) ? row.reference_no : ''}
                        </CustomButton>
                    </Box>;
                } else {
                    return row;
                }
            }
        },
        { 
            title: "Job Date", 
            field: "drop_off_date",
        },
        { 
            title: "Description", 
            field: "descriptions",
            render: (row, type) => {
              if(type === 'row'){
                  return <Box>
                  <Box pl={3} pb={2}>
                    <CustomReadMore maxLine={2} text={(row && row.descriptions && row.descriptions !== '') ? row.descriptions : ''} />
                  </Box>  
                </Box>;
              } else {
                  return row;
              }
          }
        },
        { 
            title: "Start Time", 
            field: "drop_off_time",
        },
        { 
            title: "Completion Time", 
            field: "drop_off_time_end",
        },
        { 
            title: "Address", 
            field: "address",
        },
        { 
            title: "Steps", 
            field: "steps",
            width: '200px',
            align: 'center',
            render: (row, type) => {
                if(type === 'row'){
                    return <Box>
                        <CustomButton
                            className={'underline'}
                            color={'primary'}
                            href={'/'}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                this.setState({
                                  openPreviewDialog: true,
                                  openPreviewDialogItem: row.order_id,
                                  openPreviewDialogStep: 1,
                                });
                            }}
                        >
                            {(row && row.steps && row.steps.length > 0) ? row.steps.length + ' step' : '0 step'}
                        </CustomButton>
                    </Box>;
                } else {
                    return row;
                }
            }
        },
      ]}
    />
  }

  customRow = (e, row) => {
    let desc = (row && row.descriptions && row.descriptions !== '') ? row.descriptions : '';

    return <tr>
      <td 
        colSpan={7}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        {(desc !== '') && <Box>
          <Box pl={3} pb={2}>
            <b>Description</b>
            <CustomReadMore maxLine={2} text={(row && row.descriptions && row.descriptions !== '') ? row.descriptions : ''} />
          </Box>  
        </Box>}
      </td>
    </tr>
  }
  /* END TABLE */


  /* DIALOG */
  previewDialog = () => {
    return <CustomDialog 
      open={this.state.openPreviewDialog}
      title={'Job Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
          openPreviewDialogStep: 0,
        });
      }}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        tab={this.state.openPreviewDialogStep}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
            openPreviewDialogStep: 0,
          });
        }}
      />
    </CustomDialog>
  }
  /* END DIALOG */


  render() {
    return <Box className="report-page report-transport-operation-details-page" >
      <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
      
      <Card
        className={'no-padding no-margin no-radius'}
      >
        {this.customTable()}
        {this.previewDialog()}
      </Card>
    </Box>;
  }
}