import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles((theme) => ({
  badge: {
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '0px solid currentColor',
      content: '""',
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));


const CustomAvatarBadge = props => {
    const classes = useStyles();

    const [name] = React.useState(props.name ? props.name : '');
    const [src] = React.useState(props.src ? props.src : '');
    const [color, setColor] = React.useState(props.color ? props.color : 'secondary');
    

    React.useEffect(() => {
        setColor(props.color ? props.color : 'secondary');
    }, [props.color]);


    return (
        <span className={"custom-avatar-badge-component " + classes.root}>
            <StyledBadge
                overlap="circle"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                variant="dot"
                color={color}
            >
                <Avatar alt={name} src={src} />
            </StyledBadge>
        </span>
    );
  }

  export default CustomAvatarBadge;