import React, { Component } from 'react';

import { 
    Box,
    Button,
    CircularProgress
} from '@material-ui/core';


class CustomUploadButton extends Component {
  
	constructor(props){
		super(props);

        let accept = props.accept ? props.accept : "*/*";
        let variant = props.variant ? props.variant : "contained";
        let color = props.color ? props.color : "default";
        let size = props.size ? props.size : "medium";
        let className = props.className ? props.className : "";
        let multiple = ((props.multiple === true || props.multiple === false) ? props.multiple : false);
        let fullWidth = ((props.fullWidth === true || props.fullWidth === false) ? props.fullWidth : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        
		this.state = {
            accept: accept,
            variant: variant,
            color: color,
            fullWidth: fullWidth,
            multiple: multiple,
            size: size,
            disabled: disabled,
            isLoading: isLoading,
            className: className
		}
	}
    

    componentWillReceiveProps(nextProps) {
        if (nextProps.className !== this.state.className) {
			this.setState({
				className: nextProps.className
			});
        }
        if (nextProps.variant !== this.state.variant) {
			this.setState({
				variant: nextProps.variant
			});
        }
        if (nextProps.color !== this.state.color) {
			this.setState({
				color: nextProps.color
			});
		}
        if (nextProps.fullWidth !== this.state.fullWidth) {
			this.setState({
				fullWidth: nextProps.fullWidth
			});
		}
        if (nextProps.size !== this.state.size) {
			this.setState({
				size: nextProps.size
			});
		}
        if (nextProps.disabled !== this.state.disabled) {
			this.setState({
				disabled: nextProps.disabled
			});
		}
        if (nextProps.isLoading !== this.state.isLoading) {
			this.setState({
				isLoading: nextProps.isLoading
			});
		}
    }
    

    onChange = (e) => {
        var files = e.target.files;
        
        if(this.props.onChange){
            this.props.onChange((files && files.length > 0) ? files : []);
        }
    }

    
	render() {
        return <Box 
            className={"custom-button-component upload-button " + this.state.className}
        >
            <input 
                id="upload-button-file"
                type={'file'}
                accept={this.state.accept}
                multiple={this.state.multiple}
                onChange={this.onChange}
                onClick={(e) => {
                    e.currentTarget.value = null
                }}
            />
            <label 
                className={'upload-label'}
                htmlFor="upload-button-file"
            >
                <Button 
                    type={'button'}
                    component={'span'}
                    variant={this.state.variant}
                    color={this.state.color}
                    fullWidth={this.state.fullWidth}
                    size={this.state.size}
                    disabled={(this.state.isLoading) ? this.state.isLoading : this.state.disabled}
                    onClick={(e) => {
                        if(this.props.onClick){
                            this.props.onClick(e);
                        }
                    }}
                >
                    {
                        (!this.state.isLoading)
                        ?
                        this.props.children
                        :
                        <Box pt={1}>
                            <CircularProgress size={20} />
                        </Box>
                    }
                </Button>
            </label>
		</Box>;
	}
}

export default CustomUploadButton;
