import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer
} from 'recharts';


const DeliveriesBarChart = React.memo(({data}) => {

    return (<ResponsiveContainer width="100%" height={550}>
      <BarChart 
        fontSize={14}
        data={data}
        maxBarSize={30}
        
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        
        <XAxis dataKey="date" tickLine={false}  angle={-90} height={100} textAnchor="end"/>
        <YAxis width={35}  tickLine={false} />
        <Tooltip cursor={false} />
        {/* <Legend /> */}
        <Bar dataKey="total_jobs" fill="#6EE0DD" name="Total Jobs"/>
      </BarChart>
      </ResponsiveContainer>
    );
})

export default DeliveriesBarChart