import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";

import { 
  Box,
  List,
  ListItem,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';

// import Icon from '@material-ui/core/Icon';
import logoTextWhite from '../../assets/img/logo_text_white.png';

import { ReactComponent as ArrowRightIcon } from '../../assets/img/left_menu/arrow-right.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import { ReactComponent as DashboardIcon } from '../../assets/img/left_menu/dashboard.svg';
import { ReactComponent as JobsIcon } from '../../assets/img/left_menu/jobs.svg';
import { ReactComponent as LiveTrackingIcon } from '../../assets/img/left_menu/live-tracking.svg';
import { ReactComponent as CustomersIcon } from '../../assets/img/left_menu/customers.svg';
import { ReactComponent as ReportIcon } from '../../assets/img/left_menu/report.svg';
import { ReactComponent as VendorIcon } from '../../assets/img/left_menu/vendor.svg';
import { ReactComponent as ResourcesIcon } from '../../assets/img/left_menu/resources.svg';

import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import WMSIcon from '../../assets/img/services-icon1.png';
import LDSIcon from '../../assets/img/services-icon2.png';
import {RoleAccessService} from '../../data/role-access'; 
const CustomMenuItem = (props) => {
  let {text, icon, url, active, items, onDrawerToggle} = props;
  let [open, setOpen] = React.useState(active);

  return <React.Fragment>
    <ListItem
      // onClick = {() => onDrawerToggle? onDrawerToggle(): ""}
      className={'custom-item ' + ((typeof active === "function") ? active() : (active ? "active" : ""))}
    >
      <Link to={(items && items.length > 0) ? "" : url}
        onClick={(e) => {
          // e.stopPropagation();

          if(items && items.length > 0){
            e.preventDefault();
            setOpen(!open);
            
            if(onDrawerToggle){
              onDrawerToggle()
            }
          }
        }}
      >
        {icon ? icon : <span className={'no-icon'}></span>}
        <span>{text}</span>
        {(items && items.length > 0) && (open ? <ArrowDownIcon className={'custom-expand-down'} /> : <ArrowRightIcon className={'custom-expand-right'} />)}
      </Link>
    </ListItem>
    {(items && items.length > 0) && <Collapse in={open} timeout="auto" unmountOnExit>
      <List className={'sub-nav'} component="div" disablePadding>
        {items.map((item, i) => {
          return <React.Fragment key={i}>
            {item}
          </React.Fragment>;
        })}
      </List>
    </Collapse>}
  </React.Fragment>
}


class LeftMenu extends Component {
  static contextType = AuthContext;
  
  validURL = (str) => {
    let pattern = new RegExp('^(https?:\\/\\/)?'+
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
      '((\\d{1,3}\\.){3}\\d{1,3}))'+
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
      '(\\?[;&a-z\\d%_.~+=-]*)?'+
      '(\\#[-a-z\\d_]*)?$','i');
    return !!pattern.test(str);
  }

  handleServiceChange = (e) => {
    let url = e.target.value;
    if (!this.validURL(url)) return false;
    window.location.replace(url);
  } 

  render() {
    const { isCustomerRequired, currentRole, appPermission } = this.context;
    let grantedMenu = new RoleAccessService();
    return <Box>
      <List component="nav">
        <div className={this.props.className + ' custom-logo'}>
          <Link to={'/'}>
            <img src={logoTextWhite} alt={'WorkNode'} width={'100%'} />
          </Link>
        </div>

        <div id="servicesMenuSelector">
          <label>Services</label>
          <Select
            style={{width: "100%"}}
            value='#'
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            onChange={(e) => this.handleServiceChange(e)}
          >
            {appPermission.includes('wls') ? <MenuItem style={{width: 210}} className="dark-select" value={'#'}><img src={LDSIcon} className="service-icon" alt="LDS Icon" />Workflow Logistic System</MenuItem> : ""}
            {appPermission.includes('wms') ? <MenuItem style={{width: 210}} className="dark-select" value={'https://wms.worknode.ai/'}><img src={WMSIcon} className="service-icon" alt="WMS Icon" />Warehouse Management System</MenuItem>: ""}
          </Select>
        </div>

        <hr />

        {(grantedMenu.isShouldShowMenu(currentRole, "dashboards")) && <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Dashboard'}
          icon={<DashboardIcon />}
          url={'/dashboard'}
          active={(this.props.location.pathname === "/dashboard" ? true : false)}
        />
        }

        {(grantedMenu.isShouldShowMenu(currentRole, "jobs")) && <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Jobs'}
          icon={<JobsIcon />}
          url={'/job-list'}
          active={((this.props.location.pathname === "/job-list" || this.props.location.pathname === "/job-schedule" || this.props.location.pathname === "/job-schedule-list") ? true : false)}
        />
        }

        {(grantedMenu.isShouldShowMenu(currentRole, "live_tracking")) &&<CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Live Tracking'}
          icon={<LiveTrackingIcon />}
          url={'/live-tracking'}
          active={(this.props.location.pathname === "/live-tracking" ? true : false)}
        />
        }
        {(grantedMenu.isShouldShowMenu(currentRole, "customer")) && isCustomerRequired && <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Customers'}
          icon={<CustomersIcon />}
          url={'/customers'}
          active={(this.props.location.pathname === "/customers" ? true : false)}
        />}
   
        {(grantedMenu.isShouldShowMenu(currentRole, "report")) && <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Report'}
          icon={<ReportIcon />}
          url={'/report-transport-operation'}
          active={(this.props.location.pathname === "/report-transport-operation" ? true : false)}
        />
        }

        {(grantedMenu.isShouldShowMenu(currentRole, "vendor")) && <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Vendor'}
          icon={<VendorIcon />}
          url={'/vendor'}
          active={(this.props.location.pathname === "/vendor" ? true : false)}
        />
        }
        {(grantedMenu.isShouldShowMenu(currentRole, "dashboard")) &&
        <CustomMenuItem
          // onDrawerToggle={this.props.onDrawerToggle} 
          text={'Resources'}
          icon={<ResourcesIcon />}
          active={() => {
            if(this.props.location.pathname === "/resources/transport-operator") {
              return true
            } else if(this.props.location.pathname === "/resources/logistic-assistance") {
              return true
            } else if(this.props.location.pathname === "/resources/vehicle") {
              return true
            } else {
              return false;
            }
          }}
          items={[
            <CustomMenuItem
              onDrawerToggle={this.props.onDrawerToggle} 
              text={'Transport operator'}
              url={'/resources/transport-operator'}
              active={(this.props.location.pathname === "/resources/transport-operator" ? true : false)}
            />,
            <CustomMenuItem
              onDrawerToggle={this.props.onDrawerToggle} 
              text={'Logistic assistance'}
              url={'/resources/logistic-assistance'}
              active={(this.props.location.pathname === "/resources/logistic-assistance" ? true : false)}
            />,
            <CustomMenuItem
              onDrawerToggle={this.props.onDrawerToggle}   
              text={'Vehicle'}
              url={'/resources/vehicle'}
              active={(this.props.location.pathname === "/resources/vehicle" ? true : false)}
            />,
          ]}
        />
        }

      </List>
    </Box>
  }
}

export default withRouter(LeftMenu);
