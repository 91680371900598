import React, { Component } from 'react';

import moment from 'moment'

import { 
  Box,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
} from '@material-ui/core';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import CustomButton from '../../../components/CustomFields/CustomButton.jsx';


export class AttemptForm extends Component {
  
  constructor(props){
    super(props);

    let item = (props.item) ? props.item : null;
    
    let order_attempts_images = (item && item.order_attempts_images && item.order_attempts_images.length > 0) ? item.order_attempts_images : [];
    let received_by = (item && item.received_by) ? item.received_by : '';
    let submitted_time = (item && item.submitted_time) ? item.submitted_time : '';
    let submitted_location = (item && item.submitted_location) ? item.submitted_location : '';
    let reason = (item && item.reason) ? item.reason : '';
    let note = (item && item.note) ? item.note : '';

    this.state = {
        item: item,

        order_attempts_images: order_attempts_images,
        received_by: received_by,
        submitted_time: submitted_time,
        submitted_location: submitted_location,
        reason: reason,
        note: note,
    }
  }
  

  setImages = () => {
    if(this.state.order_attempts_images && this.state.order_attempts_images.length > 0){
        return <GridList>
            {this.state.order_attempts_images.map((item, i) => {
                return <GridListTile key={i}>
                    <img src={item.image_url} alt={item.description} />
                    <GridListTileBar
                        title={<Box style={{ color: 'white' }}>{item.description}</Box>}
                        subtitle={<Box style={{ color: 'white' }}>{moment(item.updated_at).local().format('MMMM D, YYYY HH:mm A')}</Box>}
                        actionIcon={
                            <IconButton
                                className={'icon-white'}
                                onClick={() => {
                                    var win = window.open(item.image_url, '_blank');
                                    win.focus();
                                }}
                            >
                                <OpenInNewIcon />
                            </IconButton>
                        }
                    />
                </GridListTile>
            })}
        </GridList>
    }
  }


  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone pb={2}>
                <Grid item xs={12}>
                    {this.setImages()}
                </Grid>
            </Box>

            <Box clone pb={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Box clone>
                            <Grid item xs={12} md={4}>
                                Received By:
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={12} md={4}>
                                {this.state.received_by}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box clone pb={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Box clone>
                            <Grid item xs={12} md={4}>
                                Submitted At:
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={12} md={4}>
                                {moment(this.state.submitted_time).local().format('MMMM D, YYYY HH:mm A')}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box clone pb={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Box clone>
                            <Grid item xs={12} md={4}>
                                Location:
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={12} md={4}>
                                {this.state.submitted_location}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box clone pb={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Box clone>
                            <Grid item xs={12} md={4}>
                                Reason:
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={12} md={4}>
                                {this.state.reason}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box clone pb={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Box clone>
                            <Grid item xs={12} md={4}>
                                Note:
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={12} md={4}>
                                {this.state.note}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  render() {
    return <Box>
        <Grid container>
            <Box clone pt={2} pb={2}>
                <Grid item xs={12}>
                    {this.setForm()}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.setButtons()}
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
}

export default AttemptForm;