import React from 'react';

const DeferredRenderer = ({ children, idleTimeout }) => {
    const [render, setRender] = React.useState(false);

    React.useEffect(() => {
        if (render) setRender(false);
        const id = requestIdleCallback(() => setRender(true), { timeout: idleTimeout });

        return () => cancelIdleCallback(id);
    }, [idleTimeout]);

    if (!render) return <p>Generating Report...</p>;
    return children;
}

export default DeferredRenderer;
